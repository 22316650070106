export class BtnHide {
    constructor($btn) {
        this.$btn = $btn;
    }

    init() {
        this.$btn.off('click.btnHide').on('click.btnHide', function () {
            $(this).addClass('hide');
        });
    }
}

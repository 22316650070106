export default class CheckboxData {
    static init(wraps) {
        wraps.each((_, wrap) => {
            const checkboxesWrap = $(wrap).find('[data-checkbox-from]');
            const input = $(wrap).find('[data-checkbox-to]');

            CheckboxData._handleData(checkboxesWrap, input);
        });
    }

    static _handleData(wrap, input) {
        let index;
        let dataArr = [];

        if (input.val()) {
            const checkboxes = wrap.find('input[type="checkbox"]');
            const fixValue = input.val().trim().replace(/^;/, '');
            const addLine = fixValue.replace(/;/g, ';_');
            const removeLastLine = addLine.lastIndexOf('_');
            const splitVal = addLine.substring(0, removeLastLine).split('_');
            dataArr = [...splitVal];

            checkboxes.each((_, checkbox) => {
                if (dataArr.includes($(checkbox).val())) {
                    $(checkbox).prop('checked', true);
                }
            });
        }

        wrap.off('change').on('change', 'input[type="checkbox"]', function () {
            const $this = $(this);

            if ($this.prop('checked')) {
                dataArr.push($this.val());
            } else {
                index = dataArr.indexOf($this.val());
                dataArr.splice(index, 1);
            }

            input.val(dataArr.join(''));
        });
    }
}

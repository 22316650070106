import { rAF, delay, isHoverableDevice } from '@auxiliary/customMethods';

export class Cursor {
    constructor($field, $cursor, $image, options = {}) {
        this.$field = $field;
        this.$cursor = $cursor;
        this.$image = $image;
        this.emitter = options.emitter;
        this.boundResizeHandler = null;
        this.pageWidth = $(window).width();
    }

    init() {
        if (!isHoverableDevice.matches) {
            this.$cursor.addClass('touch-device');
            return;
        }

        this.$image && this.onLoadImage();
        this.onResize();
        this.onMouseLeave();
        this.onMouseEnter();
        this.onMove();
        this.emitter.once('destroy:cursor', () => {
            this.destroy();
        });
    }

    onLoadImage() {
        this.$image.on('load', () => {
            this.setToCenterCursor();
        });
        this.$image.on('error', () => {
            this.destroy();
            this.$cursor.remove();
        });
    }

    onMouseLeave() {
        this.$field.on('mouseenter', () => {
            this.$cursor.addClass('active');
        });
    }

    onMouseEnter() {
        this.$field.on('mouseleave', async () => {
            this.$cursor.removeClass('active');
            await delay(50);
            this.setToCenterCursor();
        });
    }

    onMove() {
        this.$field.on('mousemove', rAF(this._moveHandler.bind(this)));
    }

    onResize() {
        this.boundResizeHandler = this._resizeHandler.bind(this);
        this.emitter.on('page:resized', this.boundResizeHandler);
    }

    setToCenterCursor() {
        const fieldCenterW = this.$field.width() / 2;
        const fieldCenterH = this.$field.height() / 2;
        const cursorCenterW = this.$cursor.width() / 2;
        const cursorCenterH = this.$cursor.height() / 2;
        const fieldX = fieldCenterW - cursorCenterW;
        const fieldY = fieldCenterH - cursorCenterH;

        this.$cursor.css('transform', `translate3d(${fieldX}px, ${fieldY}px, 0px)`);
    }

    _moveHandler(e) {
        const $field = this.$field[0];
        const $cursor = this.$cursor[0];
        const fieldRect = $field.getBoundingClientRect();
        const fieldPos = {
            left: fieldRect.left + $field.clientLeft,
            top: fieldRect.top + $field.clientTop,
        };
        const mousePos = {
            y: e.clientY,
            x: e.clientX,
        };
        const cursorSize = {
            w: $cursor.offsetWidth / 2,
            h: $cursor.offsetHeight / 2,
        };
        let fieldX = mousePos.x - fieldPos.left - cursorSize.w;
        let fieldY = mousePos.y - fieldPos.top - cursorSize.h;

        if (fieldY < 0) {
            fieldY = 0;
        }

        if (fieldX < 0) {
            fieldX = 0;
        }

        if (fieldX + $cursor.offsetWidth > $field.clientWidth) {
            fieldX = $field.clientWidth - $cursor.offsetWidth;
        }

        if (fieldY + $cursor.offsetHeight > $field.clientHeight) {
            fieldY = $field.clientHeight - $cursor.offsetHeight;
        }

        this.$cursor.css('transform', `translate3d(${fieldX}px, ${fieldY}px, 0px)`);
    }

    _resizeHandler([width]) {
        if (this.pageWidth === width) {
            return;
        }

        this.pageWidth = width;
        this.setToCenterCursor();
    }

    destroy() {
        this.$field.off('mousemove');
        this.$field.off('mouseleave');
        this.$field.off('mouseenter');

        if (this.boundResizeHandler) {
            this.emitter.off('page:resized', this.boundResizeHandler);
        }
    }
}

export const renderFileList = (name, path, index) => {
    const attr = index > 0 ? 'class="mt-15"' : '';

    return `
        <li ${attr}>
            <a
                href="${window.location.origin}/storage/review/${path}"
                class="link-text text flex-aic"
                target="_blank"
            >
                <svg class="flex-s0">
                    <use xlink:href="#icon-document"></use>
                </svg>
                <span class="ml-12">${name}</span>
            </a>
        </li>
    `;
};

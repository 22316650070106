import { PACKAGE, SET, SQUARE_METRE } from '@auxiliary/constants';
import { removeExtraSpaces } from '@auxiliary/customMethods';
import {
    DELIVERY,
    ICON_NAME_DELIVERY,
    SHIPMENT,
    ICON_NAME_SHIPMENT,
    PACKAGING,
    ICON_NAME_PACKAGING,
    INSTALLATION,
    ICON_NAME_INSTALLATION,
    COMPANY_GUARANTEE,
    SHOP_GUARANTEE,
    COLOR_INFO,
    COLOR_SUCCESS,
    COLOR_SECONDARY,
    AVAILABLE_ON_ORDER,
    AVAILABLE_DO_NOT_HAVE,
    AVAILABLE_HAVE,
    AVAILABLE_EXPECTED,
    SELLER_TYPE_1,
    SELLER_TYPE_2,
    SELLER_TYPE_3,
    PRODUCTION_PERIOD,
    CURRENCY,
    CURRENCY_EN,
    FROM,
    FROM_EN,
    TO,
    TO_EN,
    WHOLESALE_PRICES,
    WHOLESALE_PRICES_EN,
    DETAILS,
    ORDER,
    ORDER_EN,
    DETAILS_EN,
    ABOUT_COMPANY,
    ABOUT_COMPANY_EN,
    PRODUCTION_PERIOD_EN,
    DELIVERY_EN,
    ICON_NAME_DELIVERY_EN,
    SHIPMENT_EN,
    ICON_NAME_SHIPMENT_EN,
    PACKAGING_EN,
    ICON_NAME_PACKAGING_EN,
    INSTALLATION_EN,
    ICON_NAME_INSTALLATION_EN,
    COMPANY_GUARANTEE_EN,
    SHOP_GUARANTEE_EN,
    COLOR_INFO_EN,
    COLOR_SUCCESS_EN,
    COLOR_SECONDARY_EN
} from './productOffers.variables';
import { isDiscountDate } from './productOffers.functions';

const isRU = window.locale == 'ru';
const returnOwnerProductClass = ownerProduct => (ownerProduct ? ' product-list__item--own' : '');

const returnOneOfTooltip = (prop, template) => {
    return prop === '1' ? template : '';
};

const returnTemplate = (prop, template) => {
    return prop ? template : '';
};

const templateGuarantee = (guarantee, time, owner) => {
    return `
        <div
            class="product-list__item-info-item product-list__item-guarantee flex-aic mb-10"
        >
            <svg class="mr-5">
                <use xlink:href="#icon-guarantee"></use>
            </svg>
            <div
                class="text text--fz-14"
            >${isRU ? COMPANY_GUARANTEE : COMPANY_GUARANTEE_EN} ${owner}: ${guarantee} ${time}</div>
        </div>
    `;
};

const templateTooltip = (text, iconName) => {
    return `
        <div class="tooltip product-list__item-tooltip company-extras__item" data-tooltip>
            <svg class="tooltip__title">
                <use xlink:href="#icon-${iconName}"></use>
            </svg>
            <div class="tooltip__content tooltip__content--pr" data-tooltip-content>
                <span class="text text--upper text--fz-10 text--color-secondary">${text}</span>
            </div>
        </div>
    `;
};

const returnTemplateAvailable = data => {
    const helperClass =
        data.available === AVAILABLE_ON_ORDER || data.available === AVAILABLE_EXPECTED
            ? (isRU ? COLOR_INFO : COLOR_INFO_EN)
            : data.available === AVAILABLE_DO_NOT_HAVE
                ? (isRU ? COLOR_SECONDARY : COLOR_SECONDARY_EN)
                : data.available === AVAILABLE_HAVE
                    ? (isRU ? COLOR_SUCCESS : COLOR_SUCCESS_EN)
                    : '';
    return `
        <div class="text text--fz-13 text--lh-1_5x ${helperClass} product-list__item-has mr-10">
            <p>${isRU ? data.available : data.available_en}</p>
            ${data.available_value_days ? `<p>${data.available_value} ${data.available_value_days}</p>` : ''}
        </div>
    `;
};

const returnLocation = data => {
    if (!data.cities) {
        return '';
    }

    return `
        <div class="product-list__item-info-item product-list__item-location flex-aic mb-10">
            <svg class="mr-5">
                <use xlink:href="#icon-location-active"></use>
            </svg>
            <div class="text text--fz-14">${data.cities}</div>
        </div>
    `;
};

// NOTE: Remove conditional for render approved product
const wasApproved = () => {
    // eslint-disable-next-line no-constant-condition
    if (true) {
        return '';
    }

    return `
        <svg class="product-list__item-is-approved">
            <use xlink:href="#icon-approved"></use>
        </svg>
    `;
};

// eslint-disable-next-line no-unused-vars
const returnTermProduction = () => {
    return `
        <div
            class="product-list__item-info-item
                product-list__item-time
                flex-aic
                mb-10">
            <svg class="mr-5">
                <use xlink:href="#icon-time"></use>
            </svg>
            <div class="text text--fz-14">${isRU ? PRODUCTION_PERIOD : PRODUCTION_PERIOD_EN}</div>
        </div>
    `;
};

const renderUnit = (type, count, wholesale) => {
    switch (type) {
        case PACKAGE:
            return `<span>За ${!wholesale ? 'упаковку' : `${wholesale.count} упаков.`} (${count} шт.)</span>`;
        case SET:
            return `<span>За ${!wholesale ? 'комплект' : `${wholesale.count} комп.`}</span>`;
        case SQUARE_METRE:
            return `<span>За ${!wholesale ? '' : wholesale.count} м<sup>2</sup></span>`;
        default:
            return `<span>За ${!wholesale ? '1' : wholesale.count} шт.</span>`;
    }
};

const discountBlock = data => {
    const minPrice = data.is_min_price ? `<span class="text text--fz-13">${isRU ? FROM : FROM_EN}</span>` : '';
    const isDiscount = isDiscountDate(data.discount_date);
    const currency = isRU ? CURRENCY : CURRENCY_EN;
    const to = isRU ? TO : TO_EN;

    return data.discount_price && isDiscount
        ? `
            <div class="flex-aic mt-5">
                <span class="text text--fz-19 text--medium text--spc-_3 text--color-info nowrap">
                    ${minPrice}
                    <span>${data.discount_price.integer}.</span><span
                    class="
                        text
                        text--fz-15
                    ">${data.discount_price.penny}</span>
                </span>
                <span class="text text--fz-13 text--spc-_3 text--color-info">&nbsp;${currency}</span>
            </div>
            <div class="text text--fz-13 text--spc-_3 text--color-info">${to} ${data.discount_date}</div>
        `
        : '';
};

const returnTemplateDropdown = (data, unitMeasure, isType2 = true) => {
    if (isType2 && data.length <= 1) {
        return '';
    }
    const prices = data
        .map(item => {
            return `
            <li class="rest-prices__item">
                <div class="flex-aic c-blue">
                    <span class="text text--fz-14 nowrap">
                        <span>${item.format_price.integer}.</span><span
                            class="
                                text
                                text--fz-11
                            "
                        >${item.format_price.penny}</span>
                    </span>
                    <span class="text text--fz-13">&nbsp;руб</span>
                </div>
                <div class="text text--fz-10 text--color-secondary text--upper mt-5">
                    ${renderUnit(unitMeasure.type, unitMeasure.count, item)}
                </div>
            </li>
        `;
        })
        .join('');
    return `
        <div data-dropdown class="mt-10 rest-prices">
            <div class="rest-prices__title" data-dropdown-opener>
                <svg>
                    <use xlink:href="#icon-close"></use>
                </svg>
                <span>${isRU ? WHOLESALE_PRICES : WHOLESALE_PRICES_EN}</span>
            </div>
            <div class="rest-prices__list-wrap" data-dropdown-content>
                <ul class="rest-prices__list custom-scrollbar">
                    ${prices}
                </ul>
            </div>
        </div>
    `;
};

const returnTemplateSellerType = data => {
    const unitMeasure = { type: data.type, count: data.count };
    const isDiscount = isDiscountDate(data.discount_date);
    const renderDiscountClasses = (classWithDiscount, classWithoutDiscount) => {
        return data.discount_price && isDiscount ? classWithDiscount : classWithoutDiscount;
    };
    const minPrice =
        (data.is_min_price && !data.discount_price) || (data.is_min_price && data.discount_price && !isDiscount)
            ? `<span class="text text--fz-13">${isRU ? FROM : FROM_EN}&nbsp;</span>`
            : '';
    const renderMainPrices = _data => `
        <div
            class="
                d-inline-flex
                flex-item-center
                mr-10
                ${renderDiscountClasses('line-through', '')}
            "
        >
            ${minPrice}
            <span
                class="text
                    text--medium
                    text--spc-_3
                    ${renderDiscountClasses('text--fz-15', 'text--fz-19')}
                ">${_data.format_price.integer}.<span
                    class="
                        text
                        ${renderDiscountClasses('text--fz-12', 'text--fz-15')}
                    "
                >${_data.format_price.penny}</span>
            </span>
            <span class="text text--fz-13 text--spc-_3">&nbsp;${isRU ? CURRENCY : CURRENCY_EN}</span>
        </div>
        ${discountBlock(_data)}
    `;

    if (data.seller_type === SELLER_TYPE_1) {
        return `
            <div class="product-list__price">
                ${renderMainPrices(data)}
                <div class="text text--fz-10 text--color-secondary text--upper mt-10 text--spc-_3">
                    ${renderUnit(unitMeasure.type, unitMeasure.count)}
                </div>
            </div>
        `;
    } else if (data.seller_type === SELLER_TYPE_2) {
        return `
            <div class="product-list__price">
                <div class="d-inline-flex flex-item-center mr-10">
                    <span class="text text--fz-19 text--medium text--spc-_3">
                        <span>${data.wholesales[0].format_price.integer}.</span><span
                            class="
                                text
                                text--fz-15
                            "
                        >${data.wholesales[0].format_price.penny}</span>
                    </span>
                    <span class="text text--fz-13 text--spc-_3">&nbsp;${isRU ? CURRENCY : CURRENCY_EN}</span>
                </div>
                <div
                    class="text text--fz-10 text--color-secondary text--upper mt-10"
                >
                    ${renderUnit(unitMeasure.type, unitMeasure.count, data.wholesales[0])}
                </div>
            </div>
            ${returnTemplateDropdown(data.wholesales.slice(1), unitMeasure)}
        `;
    } else if (data.seller_type === SELLER_TYPE_3) {
        return `
            <div class="product-list__price">
                ${renderMainPrices(data)}
                <div class="text text--fz-10 text--color-secondary text--upper mt-10 text--spc-_3">
                    ${renderUnit(unitMeasure.type, unitMeasure.count)}
                </div>
            </div>
            ${returnTemplateDropdown(data.wholesales, unitMeasure, false)}
        `;
    }
};

// const toCompany = (slug, id) => `<a href="/${slug}/b${id}" target="_blank" class="btn btn--secondary">Подробнее</a>`;
const order = isRU ? ORDER : ORDER_EN;
const orderFromCompany = (id, price, slug) => `<a href="javascript:void(0);" id="${id}" onclick="document.getElementById('order-price').innerText = ${price}; document.getElementById('company-slug-test').value = '${slug}'; document.getElementById('order-counter').value = 1" class="order-btn btn btn--secondary">${order}</a>`;
const detail = isRU ? DETAILS : DETAILS_EN;
const toCompany = (slug, id) => `<a href="/companies/${slug}" target="_blank" class="btn btn--secondary">${detail}</a>`;

const toShop = url =>
    url
        ? `<a
                href="${url}"
                target="_blank"
                rel="noopener noreferrer nofollow noindex"
                data-g-event="from_product_offer_to_shop"
                class="btn btn--effect01"
            >
                ${isRU ? ABOUT_COMPANY : ABOUT_COMPANY_EN}
            </a>`
        : '';

const hasBadge = isTop =>
    isTop
        ? `<div class="good-badges good-badges--static flex-aic mr-10">
        <div class="good-badge good-badge--sm good-badge--top text text--upper
        text--bold text--fz-10 text--spc-1_5">top</div>
    </div>`
        : '';

export const preloader = '<li class="lds-dual-ring"><div class="lds-dual-ring__inner"></div></li>';

export const buttonTemplate = (text, iconName) => {
    return `
        <svg>
            <use xlink:href="#icon-${iconName}"></use>
        </svg>
        <span>${text}</span>
    `;
};

export const itemTemplate = data => {
    return removeExtraSpaces(`
        <li
            class="product-list__item${returnOwnerProductClass(Number(data.is_main_variation))}"
            data-offer-id="${data.id}"
        >
            <div class="container container--product product-list__item-inner">
                <div class="product-list__item-img">
                    <img
                        src="/storage/Company/${data.image}"
                        alt="${data.company_name}"
                        title="${data.company_name}"
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div class="product-list__item-main-info flex-s2">
                    <div class="product-list__item-important-info">
                        <div class="product-list__item-main-info-top flex-aic">
                            <div class="flex-aic mr-15 mb-10">
                                ${hasBadge(data.is_top)}
                                <span class="text text--fz-17 mr-5 text-hidden maxw-270 text--spc-_3"
                                    title="${data.company_name}"
                                >${isRU ? data.company_name : data.company_name_en}</span>
                                ${wasApproved()}
                            </div>
                            <div class="flex-aic mb-10">
                                <div class="full-rating-view mr-15">
                                    <span>${data.company_grade}</span>
                                    <ul class="full-rating-view__stars" data-rating="${data.company_grade}">
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                ${toShop(data.product_link)}
                            </div>
                        </div>
                        <div class="product-list__item-main-info-about">
                            ${returnLocation(data)}
                            ${returnTemplate(
        data.company_guarantee,
        templateGuarantee(
            data.company_guarantee,
            data.company_guarantee_month,
            isRU ? COMPANY_GUARANTEE : COMPANY_GUARANTEE_EN
        )
    )}
                            ${returnTemplate(
        data.shop_guarantee,
        templateGuarantee(data.shop_guarantee, data.shop_guarantee_month, isRU ? SHOP_GUARANTEE : SHOP_GUARANTEE_EN)
    )}
                        </div>
                    </div>
                    <div class="product-list__item-extras company-extras flex-ais flex-s0">
                        ${returnOneOfTooltip(data.delivery, templateTooltip(isRU ? DELIVERY : DELIVERY_EN, isRU ? ICON_NAME_DELIVERY : ICON_NAME_DELIVERY_EN))}
                        ${returnTemplate(data.packaging, templateTooltip(isRU ? PACKAGING : PACKAGING_EN, isRU ? ICON_NAME_PACKAGING : ICON_NAME_PACKAGING_EN))}
                        ${returnOneOfTooltip(data.installation, templateTooltip(isRU ? INSTALLATION : INSTALLATION_EN, isRU ? ICON_NAME_INSTALLATION : ICON_NAME_INSTALLATION_EN))}
                        ${returnTemplate(data.shipment, templateTooltip(isRU ? SHIPMENT : SHIPMENT_EN, isRU ? ICON_NAME_SHIPMENT : ICON_NAME_SHIPMENT_EN))}
                    </div>
                </div>
                <div class="product-list__item-additional-info flex-ais">
                    <div class="product-list__item-prices mr-15">
                        ${returnTemplateAvailable(data)}
                        <div class="mb-5">
                            ${returnTemplateSellerType(data)}
                        </div>
                    </div>
                    ${orderFromCompany(data.id, data.price, data.company_slug)}
<!--                    временно убрал-->
<!--toCompany(data.company_slug, data.company_id)                    -->
                </div >
            </div >
        </li >
    `);
};

export const itemActiveTemplate = data => {
    return removeExtraSpaces(`
    < li class="product-list__item" data - item - id="${data.id}" >
            <div class="container container--product product-list__back-to-offers-wrap">
                <a href="#" id="all-offers" class="product-list__back-to-offers">
                    <span class="text text--upper text--fz-11 text--medium">Закрыть</span>
                    <svg>
                        <use xlink:href="#icon-arrow-long"></use>
                    </svg>
                </a>
            </div>
            <div class="container container--product product-list__item-inner">
                <div class="product-list__item-img">
                    <img
                        src="/storage/Company/${data.image}"
                        alt="${data.company_name}"
                        title="${data.company_name}"
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div class="product-list__item-main-info flex-s2">
                    <div class="product-list__item-important-info">
                        <div class="product-list__item-main-info-top flex-aic">
                            <div class="flex-aic mr-15 mb-10">
                                <span
                                    class="text text--fz-17 mr-5 text-hidden maxw-270"
                                    title="${data.company_name}"
                                >${isRU ? data.company_name : data.company_name_en}</span>
                                ${wasApproved()}
                            </div>
                            <div class="flex-aic mb-10">
                                <div class="full-rating-view mr-15">
                                    <span>${data.company_grade}</span>
                                    <ul class="full-rating-view__stars" data-rating="${data.company_grade}">
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg>
                                                <use xlink:href="#icon-outline-star"></use>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                ${toShop(data.product_link)}
                            </div>
                        </div>
                        <div class="product-list__item-main-info-about">
                            ${returnLocation(data)}
                            ${returnTemplate(
        data.company_guarantee,
        templateGuarantee(
            data.company_guarantee,
            data.company_guarantee_month,
            isRU ? COMPANY_GUARANTEE : COMPANY_GUARANTEE_EN
        )
    )}
                            ${returnTemplate(
        data.shop_guarantee,
        templateGuarantee(data.shop_guarantee, data.shop_guarantee_month, isRU ? SHOP_GUARANTEE : SHOP_GUARANTEE_EN)
    )}
                        </div>
                    </div>
                    <div class="product-list__item-extras flex-ais flex-s0">
                        ${returnOneOfTooltip(data.delivery, templateTooltip(isRU ? DELIVERY : DELIVERY_EN, isRU ? ICON_NAME_DELIVERY : ICON_NAME_DELIVERY_EN))}
                        ${returnTemplate(data.packaging, templateTooltip(isRU ? PACKAGING : PACKAGING_EN, isRU ? ICON_NAME_PACKAGING : ICON_NAME_PACKAGING_EN))}
                        ${returnOneOfTooltip(data.installation, templateTooltip(isRU ? INSTALLATION : INSTALLATION_EN, isRU ? ICON_NAME_INSTALLATION : ICON_NAME_INSTALLATION_EN))}
                        ${returnTemplate(data.shipment, templateTooltip(isRU ? SHIPMENT : SHIPMENT_EN, isRU ? ICON_NAME_SHIPMENT : ICON_NAME_SHIPMENT_EN))}
                    </div>
                </div>
                <div class="product-list__item-additional-info flex-ais">
                    <div class="product-list__item-prices mr-15 text--spc-_3">
                        ${returnTemplateAvailable(data)}
                        <div class="mb-5">
                            ${returnTemplateSellerType(data)}
                        </div>
                    </div>
                    ${toCompany(data.company_slug, data.company_id)}
                </div>
            </div>
            <div></div>
        </li >
    `);
};

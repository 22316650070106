export const createHTML = ($wrap, url, nameOfImage) => {
    return `
        <div class="file__img">
            <img src="${url}" alt="uploaded image" title="uploaded image"/>
        </div>
        <label class="file__edit edit">
            <input type="file" hidden/>
            <svg class="edit__icon">
                <use xlink:href="#icon-edit"></use>
            </svg>
            <span class="edit__text">
                Изменить ${$wrap.hasClass('file--logo') ? 'лого' : 'фото'}
            </span>
        </label>
        <input
            type="text"
            name="image"
            class="input-hidden"
            value="${nameOfImage ? nameOfImage : url}" ${$wrap.hasClass('file--logo') ? 'data-logo-input' : ''}
            required
        />
    `;
};

import { Fields } from '../create/fields/Fields';

export default class CompanyContacts extends Fields {
    static addField(e, HTML, selector = null) {
        super.addField(e, HTML, selector);
    }

    static removeField(e, selector = null) {
        super.removeField(e, selector);
    }

    static findMaxNumber($fields) {
        return super.findMaxNumber($fields) + 1;
    }

    static createHTML(number) {
        super.createHTML(number);
    }
}

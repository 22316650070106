import { warn } from '@auxiliary/customMethods';
import { MAP_ICON } from '@auxiliary/constants';
import { checkNewMapFields, concatAddress } from './googleAutocomplete.functions';
import {
    defaultGeolocation,
    defaultSettings,
    COUNTRY,
    ADMINISTRATIVE_AREA_LEVEL_1,
    LONG_NAME,
    ADMINISTRATIVE_AREA_LEVEL_2,
    ADMINISTRATIVE_AREA_LEVEL_3,
    SUBLOCALITY_LEVEL_1,
    SUBLOCALITY_LEVEL_2,
    SUBLOCALITY_LEVEL_3,
    LOCALITY,
    ROUTE,
    STREET_NUMBER,
    PREMISE,
    ESTABLISHMENT,
    LNG,
    LTD,
    UNNAMED_ROAD,
} from './map.variables';
import { flat } from '@auxiliary/customMethods';

export default class GoogleAutocomplete {
    constructor(input, options) {
        this.input = input;
        this.autocomplete = null;
        this.inputCountry = document.getElementById(COUNTRY);
        this.inputArea = document.getElementById(ADMINISTRATIVE_AREA_LEVEL_1);
        this.inputCity = document.getElementById(LOCALITY);
        this.inputRoute = document.getElementById(ROUTE);
        this.inputHouse = document.getElementById(STREET_NUMBER);
        this.data = {
            [COUNTRY]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_1]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_2]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_3]: LONG_NAME,
            [SUBLOCALITY_LEVEL_1]: LONG_NAME,
            [SUBLOCALITY_LEVEL_2]: LONG_NAME,
            [SUBLOCALITY_LEVEL_3]: LONG_NAME,
            [LOCALITY]: LONG_NAME,
            [ROUTE]: LONG_NAME,
            [STREET_NUMBER]: LONG_NAME,
            [PREMISE]: LONG_NAME,
            [ESTABLISHMENT]: LONG_NAME,
            [LNG]: LNG,
            [LTD]: LTD,
        };
        this.dataKeys = Object.keys(this.data);
    }

    init() {
        if (this.input.value) {
            this._setAutocomplete(this.input, {
                lat: document.getElementById(this.data.ltd).value,
                lng: document.getElementById(this.data.lng).value,
            });
            return;
        }

        this._checkUserLocation().then(userGeolocation => {
            this._setAutocomplete(this.input, userGeolocation);
        });
    }

    _setAutocomplete(input, userGeolocation) {
        const latlng = new google.maps.LatLng(userGeolocation.lat, userGeolocation.lng);
        const map = new google.maps.Map(document.getElementById('map'), {
            ...defaultSettings,
            center: latlng,
            zoom: 17,
        });
        const marker = new google.maps.Marker({
            map,
            position: latlng,
            draggable: true,
            icon: {
                url: MAP_ICON,
                scaledSize: new google.maps.Size(25, 30),
            },
        });
        const geocoder = new google.maps.Geocoder();

        if (!input.value) {
            marker.setVisible(false);
        }

        this.autocomplete = new google.maps.places.Autocomplete(input, { types: ['address'] });
        this.autocomplete.setFields(['address_component', 'geometry']);
        this.autocomplete.bindTo('bounds', map);

        this.autocomplete.addListener('place_changed', this._fillInAddress.bind(this, map, marker, input));

        google.maps.event.addListener(map, 'click', e => {
            marker.setPosition(e.latLng);
            marker.setVisible(true);

            this._getCoordsForMarker(map, geocoder, marker, input);
        });

        google.maps.event.addListener(
            marker,
            'dragend',
            this._getCoordsForMarker.bind(this, map, geocoder, marker, input)
        );
    }

    _fillInAddress(map, marker, input) {
        const place = this.autocomplete.getPlace();

        if (!place.geometry) {
            window.alert('Данное место не содержит геометрии!');
            return;
        }

        map.panTo(place.geometry.location);
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        map.setZoom(17);
        this._bindDataToForm(place, input);
    }

    _getCoordsForMarker(map, geocoder, marker, input) {
        geocoder.geocode({ latLng: marker.getPosition() }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    this._bindDataToForm(results[0], input);
                    map.panTo(results[0].geometry.location);
                }
            }
        });
    }

    _bindDataToForm(results, input) {
        const arrTypes = flat(
            results.address_components.map(address => address.types.filter(type => this.dataKeys.includes(type)))
        );

        for (const key in this.data) {
            if (checkNewMapFields(key)) continue;

            document.getElementById(key).value = '';
        }

        document.getElementById(this.data.ltd).value = results.geometry.location.lat();
        document.getElementById(this.data.lng).value = results.geometry.location.lng();

        results.address_components.forEach(item => {
            const addressTypes = item.types;
            let addressType;

            for (let i = 0; i < addressTypes.length; i++) {
                if (this.dataKeys.includes(addressTypes[i])) {
                    addressType = addressTypes[i];
                    break;
                }
            }

            if (this.data[addressType]) {
                const val = item[this.data[addressType]];

                if (
                    (addressType === ADMINISTRATIVE_AREA_LEVEL_2 ||
                        addressType === ADMINISTRATIVE_AREA_LEVEL_3 ||
                        addressType === SUBLOCALITY_LEVEL_1 ||
                        addressType === SUBLOCALITY_LEVEL_2 ||
                        addressType === SUBLOCALITY_LEVEL_3) &&
                    !arrTypes.includes(ADMINISTRATIVE_AREA_LEVEL_1)
                ) {
                    this.inputArea.value = val;
                    return;
                } else if (val === UNNAMED_ROAD) {
                    return;
                } else if (addressType === ESTABLISHMENT && !arrTypes.includes(ROUTE)) {
                    this.inputRoute.value = val;
                    return;
                } else if (addressType === PREMISE && !arrTypes.includes(STREET_NUMBER)) {
                    this.inputHouse.value = val;
                    return;
                }

                if (checkNewMapFields(addressType)) return;

                document.getElementById(addressType).value = val;
            }

            input.focus();
            input.blur();
        });

        input.value = concatAddress([
            this.inputCountry.value,
            this.inputArea.value,
            this.inputCity.value,
            this.inputRoute.value,
            this.inputHouse.value,
        ]);
    }

    _checkUserLocation() {
        if (navigator.geolocation) {
            return new Promise(resolve => {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const userGeolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        resolve(userGeolocation);
                    },
                    error => {
                        warn(error.message);
                        resolve(defaultGeolocation);
                    }
                );
            });
        } else {
            return new Promise(resolve => {
                resolve(defaultGeolocation);
            });
        }
    }
}

import CompanyContacts from './CompanyContacts';
import { removeExtraSpaces, decrementCount } from '@auxiliary/customMethods';
import { IntlPhone, IntlPhones } from '@components/intlPhone/IntlPhone';

const zIndex = decrementCount(999);
let phones = [];

export class PhonesCreate extends CompanyContacts {
    static init($wrap, { emitter }) {
        const $createdFields = $wrap.find('[data-created-fields]');
        const HTML = PhonesCreate.createHTML($createdFields.length ? super.findMaxNumber($createdFields) : 0);
        const intlPhones = new IntlPhones(document.querySelectorAll('input[type="tel"][data-intl-phone]'), {
            zIndex,
        });

        phones.push(...intlPhones.init());
        PhonesCreate._updatedPhones(emitter);

        $wrap.on('click.add', '[data-add-fields]', function (e) {
            PhonesCreate.addContact.call(this, e, HTML, 'input[name*="[phone]"]', { emitter });
        });
        $wrap.on('click.remove', '[data-remove-fields]', function (e) {
            PhonesCreate.removeContact.call(this, e, 'input[name*="[phone]"]', { emitter });
        });
    }

    static addContact(e, HTML, selector, { emitter }) {
        e.preventDefault();

        const $this = $(this);

        $this.before(HTML());

        const createdInput = $this.prev('[data-created-fields]').find(selector);
        const intlPhone = new IntlPhone(createdInput[0], {
            zIndex: zIndex(),
        });

        intlPhone.init();
        phones.push(intlPhone);
        PhonesCreate._updatedPhones(emitter);
    }

    static removeContact(e, selector, { emitter }) {
        e.preventDefault();

        const $this = $(this);
        const parent = $this.closest('[data-created-fields]');
        const parentId = parent.data('count');

        if ($this.is('[data-phone]')) {
            //NOTE: For edit company
            PhonesCreate.deleteDataFromDataBase({
                url: '/cabinet/user/company/phone/delete',
                originData: {
                    company_id: $this.data('company-id'),
                    phone: $this.data('phone'),
                    phone_information: $this.data('phone-information'),
                    key: $this.data('phone-key'),
                },
            });
        }

        const phone = phones.find(item => parentId === item.id);

        if (phone) {
            phone.destroy();
            phones = phones.filter(item => parentId !== item.id);
            PhonesCreate._updatedPhones(emitter);
        }

        parent.remove();
    }

    static _updatedPhones(emitter) {
        emitter.emit('updated:phones', phones);
    }

    static createHTML(number) {
        let count = number;

        return () => {
            const editCount = ++count - 1;

            return removeExtraSpaces(`
                <div
                    class="
                        contacts-fields__inner-box
                        contacts-fields__inner-box--created
                    "
                    data-created-fields
                    data-count="${editCount}"
                >
                    <div
                        class="
                            contacts-fields__main-field
                            form-group
                            form-group--input-mt
                            form-group--input
                            watch-input
                            form-group-intl-phone
                        "
                        data-intl-phone-wrap
                    >
                        <span
                            class="
                                form-group__tip
                                form-group__tip--required
                            "
                        >
                            Номер телефона
                        </span>
                        <div
                            class="
                                form-group__inner
                                form-group-intl-phone__inner
                            "
                            data-intl-phone-inner
                        >
                            <input
                                type="tel"
                                id="phones.${editCount}.phone"
                                name="phones[${editCount}][phone]"
                                data-intl-phone="${editCount}"
                                required
                            >
                            <div class="form-group-intl-phone__dropdown" data-intl-phone-dropdown></div>
                        </div>
                        <span class="form-group__tip-error"></span>
                    </div>
                    <div
                        class="
                            contacts-fields__descr-field
                            form-group form-group--input
                            watch-input
                        "
                    >
                        <span class="form-group__tip">Описание</span>
                        <div class="form-group__inner">
                            <input
                                type="text"
                                id="phones.${editCount}.phone_information"
                                name="phones[${editCount}][phone_information]"
                            >
                        </div>
                        <span class="form-group__tip-error"></span>
                    </div>
                    <a
                        href="#"
                        class="contacts-fields__remove btn-remove-fields"
                        data-remove-fields
                    >
                        <svg>
                            <use xlink:href="#icon-close"></use>
                        </svg>
                        <span>Удалить</span>
                    </a>
                </div>
            `);
        };
    }
}

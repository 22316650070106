import { warn } from '@auxiliary/customMethods';
import { Rating } from './../rating/Rating';
import { ModalSliderImage } from '@components/UI/modalSlider/ModalSliderImage';

const preloader = '<li class="lds-dual-ring"><div class="lds-dual-ring__inner"></div></li>';

export class UploadItems {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.ratingLength = this.$wrap.find('[data-rating]').length;
        this.blockModalLength = this.$wrap.find('[data-block-in-modal]').length;
    }

    init() {
        const self = this;

        this.$wrap.on('click', '[data-upload-items]', function (e) {
            e.preventDefault();
            const $this = $(this);
            $this.addClass('not-allowed');
            const itemId = $this.data('item-id');
            const nextPage = $this.data('next-page');
            $($this).hide();
            $(self.$wrap).append(preloader);

            const pathName = window.location.pathname;
            const path = /\/$/.test(pathName) ? pathName : `${pathName}/`;

            self._getItems(`${path}pagination?id=${itemId}&page=${nextPage}`);
        });
    }

    _getItems(url) {
        (async () => {
            try {
                const response = await this.xhr(url);
                this.$wrap.find('.lds-dual-ring__inner').remove();
                this.$wrap.find('[data-upload-items]').parent().remove();
                this.$wrap.append(response);
                this.reInitRating();

                this.reInitModalSlider();
            } catch (error) {
                warn(`Error: ${error}`);
            }
        })();
    }

    xhr(url) {
        return $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'GET',
            contentType: false,
            processData: false,
        });
    }

    reInitRating() {
        if (!this.ratingLength) {
            return;
        }
        const $wrapsRating = this.$wrap.find('[data-rating]');
        const $newWrapsRating = $wrapsRating.slice(this.ratingLength);

        this.ratingLength = $wrapsRating.length;
        $newWrapsRating.each((_, newWrapRating) => {
            const rating = new Rating($(newWrapRating));
            rating.init();
        });
    }

    reInitModalSlider() {
        if (!this.blockModalLength) {
            return;
        }

        const $blocksModal = this.$wrap.find('[data-block-in-modal]');
        const $newBlocksModal = $blocksModal.slice(this.blockModalLength);

        this.blockModalLength = $blocksModal.length;

        const modalSlider = new ModalSliderImage($newBlocksModal);
        modalSlider.init();
    }
}

import { warn } from '@auxiliary/customMethods';
import { setNotCheckedChildren, openerEl, fadeToggleFeatures, popupShow } from './production.function';

export class ProductionEdit {
    static init($prodParentEdit) {
        $($prodParentEdit)
            .off('change.editProd')
            .on('change.editProd', '[data-checked]', function (e) {
                const categoryId = $prodParentEdit.find('input[name="category"]').val();
                ProductionEdit._listenerEdit(e, $(this), categoryId);
            });
    }

    static _listenerEdit(e, self, categoryId) {
        const $checkboxSibling = self.closest('[data-checkboxes]').find('[data-listen-input][data-checked]').not(self),
            $dropdown = self.closest('[data-production-parent]').find('[data-production-children]:first');

        switch (self.data('prod-edit')) {
            case 'category_manufacture_id':
                if (self.prop('checked') === false) {
                    self.prop('checked', true);
                    ProductionEdit._checkEdit(self.data('category-id'), false, 'check-product-exist-category', self);
                }

                break;
            case 'category_sale_id':
                if (self.prop('checked') === false) {
                    self.prop('checked', true);
                    ProductionEdit._checkEdit(self.data('category-id'), false, 'check-offer-exist-category', self);
                }
                break;

            case 'feature_manufacture_id':
                if (self.prop('checked') === false) {
                    self.prop('checked', true);

                    ProductionEdit._checkEdit(self.data('id'), categoryId, 'check-feature-exist-product', self);
                }
                break;

            case 'sale_manufacture_id':
                if (self.prop('checked') === false) {
                    self.prop('checked', true);
                    ProductionEdit._checkEdit(
                        self.data('id'),
                        categoryId,
                        'check-feature-exist-offer',
                        self,
                        $checkboxSibling,
                        $dropdown
                    );
                }
                break;
        }
    }

    static _checkEdit(id, categoryId, host, self, sibling = null, dropdown = null) {
        const formData = new FormData();
        formData.append('id', id);
        if (categoryId) {
            formData.append('category_id', categoryId);
        }
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url: `/cabinet/user/company/production/${host}`,
            type: 'POST',
            dataType: 'JSON',
            data: formData,
            contentType: false,
            processData: false,
            success(data) {
                if (data.status === true) {
                    popupShow('not-edit-prod');
                }
                if (data.status === false) {
                    const $children = self
                        .closest('[data-production-parent]')
                        .find('[data-production-children]')
                        .find('[data-checkboxes]')
                        .find('[data-listen-input="checkbox"]:checked');
                    self.prop('checked', false);
                    self.attr('data-checked', '');

                    if (sibling !== null) {
                        sibling.prop('checked', false);
                        sibling.attr('data-checked', '');
                    }
                    if ($children.length) {
                        setNotCheckedChildren($children, self);
                    }
                    if (dropdown !== null) {
                        const $checkboxSibling = self
                            .closest('[data-checkboxes]')
                            .find('[data-listen-input]')
                            .not(self);
                        if (self.not(':checked') || (self.not(':checked') && $checkboxSibling.not(':checked'))) {
                            dropdown.stop().slideUp(200);
                            openerEl(self).removeClass('open');
                        }
                    }
                    if (self.data('prod-edit') === 'category_manufacture_id') {
                        fadeToggleFeatures($('[data-production-features]'), self.data('check-type'), false);
                    }
                }
            },
            error(data) {
                warn(data.status);
            },
        });
    }
}

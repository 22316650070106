import { hiddenScroll, warn } from '@auxiliary/customMethods';
import { CERTIFICATE_DIR } from '@auxiliary/constants';

export class ModalSliderImage {
    constructor($block) {
        this.$block = $block;
    }

    init() {
        this.listener();
    }
    listener() {
        $(this.$block).each((_, block) => {
            if ($(block).children().length > 0) {
                $(block).off('click.inSlider');
                $(block).on('click.inSlider', '[data-in-modal]', function (e) {
                    e.preventDefault();
                    const $thisFile = $(this);
                    const $files = $(this).closest('[data-block-in-modal]').find('[data-in-modal]'),
                        index = [...$files].indexOf(this);

                    ModalSliderImage._check($files, index, $thisFile);
                });
            }
        });
    }

    static _showPopUp(el) {
        hiddenScroll();
        $(`.${$(el).data('popup')}`).addClass('js-popup-show');
    }

    static _check($files, indexSlide, $thisFile) {
        const $slider = null;
        let checkInitSlider = false;

        const arrFiles = [...$files].map(el => {
            const src = el.src,
                alt = el.alt;
            return new Promise((resolve, reject) => {
                if ($(el).data('in-modal') === 'img') {
                    const img = new Image();
                    img.onload = () => {
                        resolve(img);
                    };
                    img.onerror = () => {
                        reject(src);
                    };
                    img.src = src;
                    img.alt = img.title = alt;
                }
                if ($(el).data('in-modal') === 'pdf') {
                    resolve($(el).data('pdf'));
                }
            })
                .then(file => {
                    if ($(el).data('in-modal') === 'img') {
                        return `<div class="swiper-slide">
                                    <img src="${file.src}" alt="${file.alt}" title="${file.alt}" />
                                </div>`;
                    }
                    if ($(el).data('in-modal') === 'pdf') {
                        return `<div class="swiper-slide">
                                    <div class="swiper-slide__plug">
                                        <embed
                                            src="${window.location.origin + CERTIFICATE_DIR + file}"
                                            type="application/pdf"
                                            pluginspage="${window.location.origin + CERTIFICATE_DIR + file}"
                                        ></embed>
                                    </div>
                                </div>`;
                    }
                })
                .catch(err => {
                    warn(err);
                });
        });

        Promise.all(arrFiles).then(results => {
            const $modalSlider = $(`.${$thisFile.data('popup')}`).find('.modal-slider');

            checkInitSlider = true;

            $(`.${$thisFile.data('popup')}`)
                .find('.swiper-wrapper')
                .html(`${results.join('')}`);
            ModalSliderImage._initSlider($slider, $modalSlider, $thisFile, indexSlide, checkInitSlider);
        });
    }
    static async _initSlider($slider, $modalSlider, $thisFile, indexSlide, checkInit) {
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;
        const swiperListeners =
            $($thisFile).data('in-modal') === 'img'
                ? {
                      on: {
                          imagesReady() {
                              ModalSliderImage._showPopUp($thisFile);
                          },
                      },
                  }
                : {};

        $slider = new Swiper($modalSlider, {
            spaceBetween: 6,
            slidesPerView: 'auto',
            initialSlide: indexSlide,
            navigation: {
                nextEl: '.modal-slider__button-next',
                prevEl: '.modal-slider__button-prev',
            },
            breakpoints: {
                992: {
                    spaceBetween: 5,
                },
            },
            ...swiperListeners,
        });

        if ($($thisFile).data('in-modal') === 'pdf') {
            ModalSliderImage._showPopUp($thisFile);
        }

        $slider.update();
        if (checkInit) {
            $(document)
                .off('click.modalSliderHide')
                .on('click.modalSliderHide', e => {
                    if (!$(`.${$($thisFile).data('popup')}`).hasClass('js-popup-show')) {
                        setTimeout(() => {
                            $slider.destroy();
                        }, 350);

                        checkInit = false;
                    }
                });
        }
        // $slider.slideTo(indexSlide, 0);
    }
}

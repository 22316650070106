export default class CheckedInputs {
    static init(wrap) {
        CheckedInputs.triggerCheckedInputs(wrap);
        wrap.on('change', '.checkbox input[type="checkbox"]', function () {
            const $this = $(this);
            const commonParent = $this.closest('.checked-inputs__item');
            const inputs = commonParent.find('[data-checked-input]');

            if ($this.prop('checked')) {
                inputs.each((_, input) => {
                    $(input)
                        .removeClass('no-hover')
                        .removeAttr('tabindex')
                        .closest('.watch-input')
                        .removeClass('not-allowed');
                });
            } else {
                inputs.each((_, input) => {
                    $(input).val('');
                    $(input)
                        .closest('.watch-input')
                        .removeClass('active')
                        .addClass('not-allowed')
                        .end()
                        .removeClass('error')
                        .addClass('no-hover')
                        .attr('tabindex', '-1')
                        .closest('.watch-input')
                        .find('label.error')
                        .remove()
                        .end()
                        .closest('.watch-input')
                        .find('.form-group__tip-error')
                        .empty();
                });
            }
        });
    }

    static triggerCheckedInputs(wrap) {
        wrap.find('[data-checked-input]').each((_, input) => {
            const $input = $(input);

            if ($input.val()) {
                const commonParent = $input.closest('.checked-inputs__item');
                const checkbox = commonParent.find('input[type=checkbox]');

                $input
                    .removeClass('no-hover')
                    .removeAttr('tabindex')
                    .closest('.watch-input')
                    .removeClass('not-allowed');
                checkbox.prop('checked', true);
            }
        });
    }
}

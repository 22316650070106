import { IntlPhones } from '@components/intlPhone/IntlPhone';

export class Auth {
    constructor($form, options = {}) {
        this.$form = $form;
        this.options = options;
        this.instanceIntlPhone = null;
    }

    init() {
        this.initIntPhone();
    }

    initIntPhone() {
        this.instanceIntlPhone = new IntlPhones(
            document.querySelectorAll('input[type="tel"][data-intl-phone]'),
            this.options
        ).init()[0];
    }
}

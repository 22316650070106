export const TEMPORARILY_CLOSE = 'temporarily-close';
export const CLOSE = 'close';
export const RESTORE = 'restore';
export const DELETE = 'delete';

export const BTN_TEXT_TEMPORARILY_CLOSE = 'Да, временно закрыть';
export const BTN_TEXT_CLOSE = 'Да, закрыть';
export const BTN_TEXT_RESTORE = 'Да, восстановить работу';
export const BTN_TEXT_DELETE = 'Да, удалить';
export const BTN_TEXT_ADD_ADDRESS = 'Добавить адрес';

export const BTN_TEXT_TEMPORARILY_CLOSED_EN = 'Yes, temporarily close';
export const BTN_TEXT_CLOSE_EN = 'Yes, close';
export const BTN_TEXT_RESTORE_EN = 'Yes, restore work';
export const BTN_TEXT_DELETE_EN = 'Yes, delete';
export const BTN_TEXT_ADD_ADDRESS_EN = 'Add an address';

export const URL_CHANGE_STATUS = '/cabinet/user/company/warehouse/change-status-open-warehouse';
export const URL_GET_WAREHOUSES = '/cabinet/user/company/warehouse/anotherWarehouses';
export const URL_CLOSE_AND_CHANGE_WAREHOUSE = '/cabinet/user/company/warehouse/status-closed-and-change-warehouse';
export const URL_DELETE_AND_CHANGE_WAREHOUSE = '/cabinet/user/company/warehouse/del-and-change-warehouse';
export const URL_DELETE_WAREHOUSE = '/cabinet/user/company/warehouse/delete';

export const LINK_TO_ADD_NEW_ADDRESS = '/cabinet/user/company/warehouse/create';

export const STATUS_IS_OPEN = 0;
export const STATUS_IS_TEMPORARILY_CLOSE = 1;
export const STATUS_IS_CLOSE = 2;

export const POPUP_CONTENT = 'content';
export const POPUP_OPTIONS = 'options';
export const POPUP_BUTTONS = 'buttons';

export const OPTION_DEFAULT_CLOSE = 'Выберите адрес (необязательно)';
export const OPTION_DEFAULT_DELETE = 'Выберите адрес';
export const NO_ADDRESSES = 'У вас нет других адресов';

export const OPTION_DEFAULT_CLOSE_EN = 'Select an address (optional)';
export const OPTION_DEFAULT_DELETE_EN = 'Select an address';
export const NO_ADDRESSES_EN = 'You don\'t have any other addresses';

import Edit from '../../edit/Edit';
import Brand from '../Brand';
import OwnProdVisibility from '../OwnProdVisibility';
import { PhonesCreate } from '../PhonesCreate';
import { EmailsCreate } from '../EmailsCreate';
import { deleteFormDataKeys } from '@auxiliary/customMethods';
import { toggleLogo } from './../company.functions';

let phones = [];

export default class EditCompany extends Edit {
    static init(wrap, form, { emitter }) {
        const checkboxBrand = form.find('input[name="is_brand"]');

        EditCompany.sendData({ form, url: '/cabinet/user/company/edit' });

        emitter.on('updated:phones', intlPhones => {
            phones = intlPhones;
        });

        if (!checkboxBrand.prop('checked')) {
            Brand.handleBrand(form, checkboxBrand);
        }

        toggleLogo(form.find(':checkbox[name="has-not-logo"]'), $('#photo'));
        OwnProdVisibility.init(wrap.find('[data-toggle-visibility]'), Brand.removeCheckedCategories);
        PhonesCreate.init(wrap.find('[data-contacts="phones"]'), { emitter });
        EmailsCreate.init(wrap.find('[data-contacts="emails"]'));
    }

    static sendData(options) {
        const { form, url } = options;

        form.off('submit').on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.valid()) {
                const formData = new FormData($this[0]);
                const btn = $this.find('button[type="submit"]');

                btn.addClass('not-allowed');
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        EditCompany._correctFormData($this, formData);
                        EditCompany._removeErrors(form);
                    },
                    success() {
                        EditCompany._success();
                    },
                    error(data) {
                        EditCompany._error(data, btn, form);
                    },
                });
            } else {
                EditCompany._notValid($this);
            }
        });
    }

    static _correctFormData(form, formData) {
        const $notLogo = form.find(':checkbox[name="has-not-logo"]');
        const checkboxProdCategories = form.find(
            'input[type="checkbox"][name^="checkbox_production_category_"]:checked'
        );

        deleteFormDataKeys(formData, $notLogo);

        if (formData.has('legal_address')) {
            formData.delete('legal_address');
        }

        if (formData.has('checkbox_payment_type')) {
            formData.delete('checkbox_payment_type');

            if (!formData.has('is_brand')) formData.set('is_brand', 0);
        }

        if (checkboxProdCategories.length) {
            deleteFormDataKeys(formData, checkboxProdCategories);
        }

        phones.forEach(phone => {
            formData.set(phone.name, phone.number);
        });
    }
}

export default class TogglePassword {
    static toggle(btn) {
        btn.on('click', function () {
            const $this = $(this);
            const password = $this.parent().find('.password');

            $this.toggleClass('active');
            password.attr('type', function (_, attr) {
                return attr === 'password' ? 'text' : 'password';
            });
        });
    }
}

import { setMaxHeight, isShowMoreWithHeight, removeHeight, addHeight } from './showMore.function';
import { TEXT_OPEN_WITH_HEIGHT, TEXT_HIDE_WITH_HEIGHT, TEXT_OPEN_ITEMS, TEXT_HIDE_ITEMS } from './showMore.variables';

export class ShowMore {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$content = this.$wrap.find('[data-show-more-content]');
        this.$btn = this.$wrap.find('[data-show-more-btn]');
        this.emitter = options.emitter;
        this.maxHeight = options.maxHeight || 145;
    }

    showMoreItems(amount) {
        if (amount >= this.$content.length) {
            this.$btn.addClass('hidden');
            return;
        }
        const $wrappedContent = this.$content
            .slice(amount)
            .wrapAll('<ul class="" style="display: none" data-show-more-inner-content></ul>')
            //.wrapAll('<ul class="d-none" data-show-more-inner-content></ul>')
            .parent();
        this.$btn.on('click', e => {
            e.preventDefault();
            if (this.$btn.hasClass('active')) {
                removeHeight(this.$btn, TEXT_OPEN_ITEMS);
                $wrappedContent.stop().slideUp(200);
                return;
            }
            addHeight(this.$btn, TEXT_HIDE_ITEMS);
            $wrappedContent.stop().slideDown(200);
        });
    }

    showMoreWithMaxHeight() {
        if (!isShowMoreWithHeight) {
            return;
        }
        setMaxHeight(this.$content, this.maxHeight);
        this._checkHeight();
        this._editHeight();
        this.$btn.on('click', e => {
            e.preventDefault();
            if (this.$content.hasClass('active')) {
                removeHeight(this.$btn, TEXT_OPEN_WITH_HEIGHT);
                setMaxHeight(this.$content, this.maxHeight)
                    .removeClass('active')
                    .css('max-height', `${this.maxHeight}px`);
                return;
            }
            addHeight(this.$btn, TEXT_HIDE_WITH_HEIGHT);
            setMaxHeight(this.$content, this.maxHeight)
                .addClass('active')
                .css('max-height', `${this.$content[0].scrollHeight}px`);
        });
    }

    _editHeight() {
        this.emitter.on('page:resized', () => {
            this._checkHeight();
        });
    }

    _checkHeight() {
        const contentScrollHeight = this.$content[0].scrollHeight;
        const maxHeight = this.maxHeight;
        const isContentActive = this.$content.hasClass('active');
        if (isContentActive && contentScrollHeight > maxHeight) {
            setMaxHeight(this.$content, contentScrollHeight).css('max-height', `${contentScrollHeight}px`);
        }
        if (contentScrollHeight <= maxHeight) {
            this.$content.addClass('hide-shadow');
            removeHeight(this.$btn, TEXT_OPEN_WITH_HEIGHT).end().addClass('hidden');
            setMaxHeight(this.$content, maxHeight).removeClass('active').css('max-height', `${maxHeight}px`);
        } else {
            this.$content.removeClass('hide-shadow');
            this.$btn.removeClass('hidden');
        }
    }
}

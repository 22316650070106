import { removeExtraSpaces } from '@auxiliary/customMethods';

export const versionInfoModalHTML = () => {
    return removeExtraSpaces(`
        <div id="version-info" class="modal-info modal-info--versions" data-modal-info="versions">
            <div class="modal-info__inner">
                <div class="modal-info__head">
                    <div class="title title--medium modal-info__title">Beta-версия системы</div>
                    <a href="#" class="modal-info__close" data-modal-info-close>
                        <svg>
                            <use xlink:href="#icon-close-thin"></use>
                        </svg>
                    </a>
                </div>
                <div class="modal-info__body custom-scrollbar" data-modal-info-description>
                    <div
                        class="
                            modal-info__text
                            text text--fz-14
                            text--lh-1_5x
                            text--spc-_3
                        "
                    >
                        Вы можете ознакомиться со всеми обновлениями функционала и версий.
                    </div>
                    <a href="/versions" class="btn btn--with-arrow-ml" data-modal-info-close>
                        <span>Подробнее</span>
                        <svg>
                            <use xlink:href="#icon-arrow"></use>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    `);
};

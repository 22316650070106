export default class AuthLoading {
    static init(el) {
        const mq = window.matchMedia('(min-width: 992px)');
        const mqCheckOnce = mq;
        const form = el.find('.auth__wrap-form');

        const callToggleAnimation = AuthLoading._toggleAnimation(el, form);

        const checkMq = () => {
            if (mq.matches) {
                $('body').on('click.login', 'a[href^="/login"]', function (e) {
                    e.preventDefault();
                    callToggleAnimation(e, '/login');
                });
                $('body').on('click.login', 'a[href^="/register"]', function (e) {
                    e.preventDefault();
                    callToggleAnimation(e, '/register');
                });
            } else {
                el.removeClass('main-content__auth--noanimation loading not-allowed');
                $('body').off('click.login');
            }
        };

        checkMq();
        mq.addListener(checkMq);

        if (mqCheckOnce.matches) {
            new Promise(resolve => {
                setTimeout(resolve, 1000);
            }).then(() => {
                el.removeClass('main-content__auth--noanimation');
                callToggleAnimation();
            });
        }
    }

    static _toggleAnimation(el, form) {
        return (parentEv, href) => {
            el.toggleClass('loading');

            form.one('transitionend ', function () {
                el.toggleClass('not-allowed');

                if (href === '/login' && parentEv && parentEv.target.href.match(/\/login/i)[0] === href) {
                    window.location = href;
                } else if (href === '/register' && parentEv && parentEv.target.href.match(/\/register/i)[0] === href) {
                    window.location = href;
                }
            });
        };
    }
}

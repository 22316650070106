import { hiddenScroll } from '@auxiliary/customMethods';

export const showPopup = (popup, name, id = null) => {
    hiddenScroll();
    popup.find('[data-company-name]').html('').text(name).end().addClass('js-popup-show');

    if (id) {
        popup
            .find('[data-company-url]')
            .attr('href', `${window.location.origin}/cabinet/user/company/change-owner/${id}`);
    }
};

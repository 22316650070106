export class DeleteById {
    static init($id, url, urlBack) {
        const $popupCantRemove = $('[data-popup-cant-remove]') || null;
        // const $popupDelete = $(`.${$($id).data('popup')}`);
        $id.off('click.findPopUpId').on('click.findPopUpId', function (e) {
            $(`.${$(this).data('popup')}`)
                .find('[data-remove-confirm]')
                .attr('data-remove-confirm', `${this.getAttribute('data-remove-id')}`);

            $(`.${$(this).data('popup')}`)
                .find('[data-remove-name]')
                .text(`${$(this).data('name')}`);

            if ($popupCantRemove) {
                $popupCantRemove.find('[data-remove-name]').text(`${$(this).data('name')}`);
            }
        });

        $('[data-remove-confirm]')
            .off('click.removeId')
            .on('click.removeId', function (e) {
                const formData = new FormData();
                formData.set('id', this.getAttribute('data-remove-confirm'));
                const dataObj = {
                    url: url,
                    originData: formData,
                    popupDel: $(this).closest('.popup.popup--remove-id'),
                    href: $(this).data('redirect') || '',
                    back: urlBack,
                };

                dataObj.popupDel.addClass('not-allowed');

                DeleteById.deleteDataFromDataBase(dataObj);
            });
    }

    static deleteDataFromDataBase(dataObj) {
        const { url, originData, popupDel, href, back } = dataObj;
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            contentType: false,
            processData: false,
            data: originData,
            success({ status }) {
                if (status === 'ok') {
                    popupDel.removeClass('js-popup-show not-allowed');
                    if (href === 'back') {
                        window.location.href = back;
                    } else {
                        DeleteById._success();
                    }
                } else if (status === 'isset offers') {
                    popupDel.removeClass('js-popup-show not-allowed');
                    $('[data-popup-cant-remove]')?.addClass('js-popup-show');
                }
            },
        });
    }

    static _success() {
        const popup = $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                sessionStorage.clear();
                document.location.reload();
            }, 300);
        });
    }
}

import { Modal } from '@components/UI/modal/Modal';
import { Cookie } from '@components/cookie/Cookie';
import { versionInfoModalHTML } from './versionInfoModal.template';
import { expirationDateInHours } from '@auxiliary/customMethods';

export const versionInfoModal = (options = {}) => {
    if (window.locale && window.locale !== 'ru') return;
    $('body').append(versionInfoModalHTML());

    const modal = new Modal($('#version-info'), null, { emitter: options.emitter });

    modal.showInfo();
    modal.closeInfo();

    options.emitter.once('modalInfo:hidden', () => {
        Cookie.setCookie('dp_beta_v', 'checked', {
            expires: expirationDateInHours(168),
            samesite: 'strict',
        });
    });
};

import { checkValue } from '@auxiliary/customMethods';

export default class DigitInput {
    static _enteringDigits(input) {
        const validVal = input.value.replace(/\D/gi, '');
        const nextInput = input.nextElementSibling;
        const prevInput = input.previousElementSibling;
        let latch = false;

        validVal.length === 1 ? (latch = false) : (latch = true);
        input.value = validVal;

        if (latch && validVal.length > 1) {
            const exist = nextInput ? true : false;

            if (exist && validVal[1]) {
                nextInput.disabled = false;
                nextInput.value = validVal[1];
                input.value = validVal[0];
                nextInput.focus();
                nextInput.selectionStart = nextInput.selectionEnd = nextInput.value.length;
            }
        } else if (latch && input && validVal.length === 0) {
            const exist = prevInput ? true : false;

            if (exist) {
                input.disabled = true;
                prevInput.focus();
            }
        }
    }

    static init(form) {
        const inputsWrap = form.querySelector('.form-group__inputs');
        const inputs = [...inputsWrap.getElementsByClassName('input-digit')];
        //NOTE: comment it, because comment button in blade
        // const btn = $(form).find('button[type="submit"]');
        const inputCode = $(form).find('input[name="code"]');
        let code;

        inputs[0].focus();
        form.reset();
        inputsWrap.addEventListener(
            'keydown',
            e => {
                const key = e.keyCode || e.charCode;

                if (key === 9) {
                    return e.preventDefault();
                } else if (key >= 37 && key <= 40) {
                    return e.preventDefault();
                }
            },
            false
        );

        inputsWrap.addEventListener('paste', e => e.preventDefault(), false);
        inputsWrap.addEventListener('cut', e => e.preventDefault(), false);

        inputsWrap.addEventListener(
            'click',
            function (e) {
                const curInput = $(e.target).closest('.input-digit');

                if (!curInput[0]) return;

                const valLen = curInput[0].value.length;
                curInput[0].setSelectionRange(valLen, valLen);
            },
            false
        );

        inputsWrap.addEventListener('input', function (e) {
            const curInput = e.target;

            DigitInput._enteringDigits(curInput);

            if (inputs.every(checkValue)) {
                code = '';

                inputs.forEach(input => {
                    code += input.value;
                    input.blur();
                });

                // btn.removeClass('btn--disable').addClass('btn--primary');
                inputCode.val(code);
                $(form).trigger('submit');
            }
            // else {
            //     btn.removeClass('btn--primary').addClass('btn--disable');
            // }
        });

        $(form).on('submit', function () {
            // btn.removeClass('btn--primary').addClass('btn--disable');
        });
    }
}

import { toggleSearch, closeSearch, inputSearch, resizeSearch } from './search.functions';

export const search = ($target, $wrap, options = {}) => {
    const $resultWrap = $wrap.find('[data-search="result"]');
    const $headerWrap = $wrap.find('[data-search="header"]');

    toggleSearch($target, $wrap);
    closeSearch($wrap);
    inputSearch($wrap, $resultWrap, $headerWrap);
    resizeSearch($wrap, $resultWrap, $headerWrap, {
        emitter: options.emitter,
    });
};

import { warn, setBlobToFormData } from '@auxiliary/customMethods';

export class Profile {
    constructor($form, options) {
        this.$form = $form;
        this.emitter = options.emitter;
        this.imgData = {};
    }

    init() {
        this.emitter.on('image:uploaded', ([blob, $input]) => {
            this.imgData[$input[0].name] = [blob, $input.val()];
        });
        this.sendForm();
    }

    sendForm() {
        const self = this;

        this.$form.on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.valid()) {
                const formData = new FormData($this[0]);
                const btnSubmit = $this.find('[type=submit]');
                Profile._showLoader();
                btnSubmit.addClass('not-allowed');
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url: '/cabinet/user/profile',
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        if (!$.isEmptyObject(self.imgData)) {
                            setBlobToFormData(formData, self.imgData);
                        }
                    },
                    success() {
                        document.location.reload();
                    },
                    error(data) {
                        warn(`Error: ${data}`);
                        Profile._hideLoader();
                    },
                });
            }
        });
    }

    static _showLoader() {
        $('#loader').addClass('active');
        $('#overlay').removeClass('hidden');
    }

    static _hideLoader() {
        $('#loader').removeClass('active');
        $('#overlay').addClass('hidden');
    }
}

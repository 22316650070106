import * as $ from 'jquery';

export class Share {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.social = '';
        this.shareURL = '';
        this.socialURL = '';
    }

    init() {
        this.$wrap.on('click', '[data-social]', e => {
            e.preventDefault();
            const curSocial = $(e.target).closest('[data-social]');

            this.social = curSocial.data('social');
            this.shareURL = window.location.href;
            this.openShare();
        });
    }

    openShare() {
        switch (this.social) {
            case 'fb':
                this.socialURL = `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}`;
                break;
            case 'vk':
                this.socialURL = `https://vk.com/share.php?url=${this.shareURL}`;
                break;
            case 'ok':
                this.socialURL = `https://connect.ok.ru/offer?url=${this.shareURL}`;
                break;
            case 'tm':
                this.socialURL = `https://t.me/share/url?url=${this.shareURL}`;
                break;
            case 'vb':
                // eslint-disable-next-line max-len
                this.socialURL = `https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=viber://forward?text=${this.shareURL}`;
                break;
            case 'wt':
                this.socialURL = `https://wa.me/?text=${this.shareURL}`;
                break;
            default:
                break;
        }

        if (this.socialURL) {
            const width = 800;
            const height = 500;
            const left = (window.screen.width - width) / 2;
            const top = (window.screen.height - height) / 2;

            const socialWindow = window.open(
                this.socialURL,
                'share_window',
                `height=${height},width=${width},top=${top},left=${left}`
            );

            socialWindow.focus();
        }
    }
}

const addSticky = (wrap, stickyEl, top) => {
    const wrapTopPos = wrap.offset().top;
    const wrapHeight = wrap.height();
    const stickyElHeight = stickyEl.height();
    const botPos = wrapTopPos + wrapHeight;
    const botPosH = botPos - stickyElHeight;

    if (top < wrapTopPos || stickyElHeight >= wrapHeight) {
        stickyEl.removeClass('pos-fix').removeClass('pos-abs');
    } else {
        top >= wrapTopPos && top <= botPosH
            ? stickyEl.removeClass('pos-abs').addClass('pos-fix')
            : stickyEl.removeClass('pos-fix').addClass('pos-abs');
    }
};

const removeSticky = el => {
    el.removeClass('pos-abs pos-fix');
};

export const initSticky = ({ wrap, stickyEl, mq }) => {
    const handleEl = () => {
        const top = $(document).scrollTop();

        addSticky(wrap, stickyEl, top);
    };

    if (mq) {
        const checkMq = () => {
            if (mq.matches) {
                $(window).on('scroll.sticky', function () {
                    handleEl();
                });
                handleEl();
            } else {
                $(window).off('scroll.sticky');
                removeSticky(stickyEl);
            }
        };

        checkMq();
        mq.addListener(checkMq);
    } else {
        $(window).on('scroll.sticky', function () {
            handleEl();
        });
        handleEl();
    }
};

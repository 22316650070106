import Create from '../../create/Create';
import { getCurrentSubmitBtn } from '@auxiliary/customMethods';
import { IntlPhones } from '@components/intlPhone/IntlPhone';

export default class CreateWarehouse extends Create {
    static init(wrap) {
        super.checkSessionMainKey('warehouse');
        getCurrentSubmitBtn();
        this.instanceIntlPhone = null;

        this.instanceIntlPhone = new IntlPhones(
            document.querySelectorAll('input[type="tel"][data-intl-phone]'),
            {}
        ).init()[0];

        super.sendData({
            form: wrap.find('.fields-data--warehouse'),
            step: null,
            url: '/cabinet/user/company/warehouse/create',
            instanceIntlPhone: this.instanceIntlPhone,
        });
    }
}

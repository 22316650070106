export class Pagination {
    static init($wraps) {
        $wraps.each((_, wrap) => {
            const $wrap = $(wrap);
            const $dots = $wrap.find('.page-link:contains(...)');

            Pagination.addClassPagination($dots);
            Pagination.splicePagination($wrap, $dots);
        });
    }

    static addClassPagination($dots) {
        $dots.parent().addClass('dots');
    }

    static splicePagination($wrap, $dots) {
        if (!$dots.length) {
            return;
        }

        const $items = $wrap.find('.page-item');
        const $links = $wrap.find('a.page-link');
        const sortedItems = $links
            .filter(function () {
                return /\d/.test($(this).text());
            })
            .parent();
        const isDotStart = $items.eq(3).hasClass('dots');
        const isDotEnd = $items.eq(5).hasClass('dots');

        if ($dots.length === 1 && isDotEnd) {
            $(sortedItems.splice(-2, 1)).remove();
        } else if ($dots.length === 1 && isDotStart) {
            $(sortedItems.splice(1, 1)).remove();
        }
    }
}

import { ajax } from '@auxiliary/customMethods';

export const geoIpLookup = cb => {
    ajax('GET', 'https://ipinfo.io', null, { dataType: 'jsonp' }).always(res => {
        const countryCode = res && res.country ? res.country : 'ru';
        // eslint-disable-next-line no-console
        console.clear();
        cb(countryCode);
    });
};

export const modifyCountryData = () => {
    const countryData = window.intlTelInputGlobals.getCountryData();

    countryData.forEach(country => {
        country.name = country.name.replace(/.+\((.+)\)/, '$1');
    });
};

export const addZIndex = ($wrap, zIndex) => {
    $wrap.style.setProperty('--zIndex', zIndex);
};

import { Rating } from '@components/UI/rating/Rating';
import { Tooltip } from '@components/UI/tooltip/Tooltip';
import { itemActiveTemplate } from './productOffers.template';
import { DATE_PATTERN } from '@auxiliary/constants';

export const fadeIn = ($el, cb = null, delay = 200) => {
    typeof cb === 'function' ? $el.fadeIn(delay, cb) : $el.fadeIn(delay);
};

export const fadeOut = ($el, cb = null, delay = 200) => {
    typeof cb === 'function' ? $el.fadeOut(delay, cb) : $el.fadeOut(delay);
};

export const toggleClassesInWrap = ($wrap, $list) => {
    $list.addClass('active');
    $wrap.removeClass('not-allowed');
};

export const appendActiveItem = ($list, data) => $list.html('').append(itemActiveTemplate(data));

export const isHiddenPagination = $el => $el.is('[data-pagination-hidden]');

export const initAuxiliaryFn = $list => {
    const rating = new Rating($list.find('> li [data-rating]')[0]);
    rating.init();
    Tooltip.changeSide($list.find('[data-tooltip-content]'));
};

export const generateFormData = id => {
    const formData = new FormData();
    formData.set('id', id);
    return formData;
};

export const scrollToListTop = $list => {
    const top = $list[0].getBoundingClientRect().top;
    if (top === 0) {
        return;
    }
    $('html, body').animate(
        {
            scrollTop: $list.offset().top,
        },
        300
    );
};

export const resetMap = ({ $el, self, $wrap, $list, cb }) => {
    $el.off('click');
    $el.one('click', cb.bind(self, $wrap, $list));
};

export const isDiscountDate = discountDate => {
    if (!discountDate) {
        return false;
    }

    const arrayDate = discountDate.match(DATE_PATTERN);

    return new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]).getTime() >= new Date().getTime();
};

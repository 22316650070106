export class ScrollbarSlider {
    constructor($el, emitter) {
        this.$el = $el;
        this.emitter = emitter;
    }

    async init() {
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;

        const swiper = new Swiper(this.$el, {
            slidesPerView: 'auto',
            resistanceRatio: 0,
            spaceBetween: 5,
            navigation: {
                nextEl: '.scrollbar-slider__btn-next',
                prevEl: '.scrollbar-slider__btn-prev',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
                hide: false,
                dragSize: 'auto',
            },
        });

        this.emitter.on('page:resized', () => {
            swiper.update();
        });
    }
}

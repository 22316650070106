import { renderRequiredClass } from '@components/recursive/featureTree/featuresTree.template';
import { removeExtraSpaces } from '@auxiliary/customMethods';
import { GROUP_DIR } from '@auxiliary/constants';

const renderFeatureInfo = ({ description, name, id }) => {
    return description
        ? `<a
            href="#"
            class="btn-tip-info vertical-align-middle"
            data-tip-name="${name}"
            data-tip-id="${id}"
            data-tip-type="feature" data-btn-tip
        >
            <svg class="icon-tip-info">
                <use xlink:href="#icon-tip-info"></use>
            </svg>
        </a>`
        : '';
};

const hasChildFn = childFeature => {
    return childFeature > 0
        ? '<div class="production-tree__children" data-production-children data-children-hidden></div>'
        : '';
};

const hasChildIconFn = childFeature => {
    return childFeature > 0 ? '<span class="icon-plus-open" data-production-opener></span>' : '';
};

const dataHasOpenerFn = childFeature => {
    return childFeature > 0 ? 'data-production-opener' : '';
};

const dataAttrHasChildFn = childFeature => {
    return childFeature > 0 ? 'hasChild' : 'no-child';
};

// TODO: checks if there are products
const formatFeaturesTemplate = (production, manufactureHide) => {
    if (production === false) {
        return `
            <span class="text text--fz-10
                text--upper
                production__col
                production__col--sale
                production__col--sm-rotate">
                <span data-production-format="sale">Продаем</span>
            </span>`;
    } else {
        return `
            <span class="text text--fz-10
                text--upper production__col
                production__col--prod
                production__col--sm-rotate">
                <span data-production-format="prod">
                    <span class="production__col-inner-sm">Произ-м</span>
                    <span class="production__col-inner">Производим</span>
                </span>
            </span>
            <span class="text text--fz-10
                text--upper
                production__col
                production__col--sale
                production__col--sm-rotate">
                <span data-production-format="sale">Продаем</span>
            </span>`;
    }
};

export const prodFeaturesTemplate = (template, content = false, hasProduction = false, manufactureHide) => {
    if (template === 'empty') {
        return `
            <span class="text text--color-secondary2 text--center text--fz-14 text--spc-_3 text--lh-1_8x">
                Чтобы начать выбирать характеристики продукции,
                <span class="d-block">Вам необходимо выбрать категорию
                    <span class="production__characteristics-empty-lg">
                    слева</span>
                </span>
            </span>
        `;
    }
    if (template === 'content') {
        return `
            <div class="production__title" data-sticky-element>
                <span class="text text--color-secondary text--fz-10 text--upper mt-35 mr-5">Характеристики</span>
                <div class="d-flex production__designation">
                    ${formatFeaturesTemplate(hasProduction, manufactureHide)}
                </div>
            </div>
            ${content}
        `;
    }
};

export const renderGroup = ({ name, icon }, features) => {
    return removeExtraSpaces(`
        <div class="group group--with-overfull-background">
            <div class="group__header">
                <img
                    src="${GROUP_DIR}${icon}"
                    alt="${name}"
                    title="${name}"
                />
                <span
                    class="
                        group__name
                        group__name--fz-10
                        text
                        text--upper
                        text--medium
                        text--spc-_7
                    ">${name}</span>
            </div>
            <div class="group__body custom-scrollbar">
                ${features}
            </div>
        </div>
    `);
};

export const renderParentWrapHTML = (
    { id, name, children_count, required_type, description },
    hasProduction,
    manufactureHide
) => {
    const hasChild = hasChildFn(children_count);
    const hasChildIcon = hasChildIconFn(children_count);
    const dataHasOpener = dataHasOpenerFn(children_count);
    const dataAttrHasChild = dataAttrHasChildFn(children_count);
    const featureInfo = renderFeatureInfo({ description, name, id });
    const manufacture = hasProduction
        ? `<div class="production__col production__col--prod" data-required>
                <span data-production-format="prod">
                    <label class="checkbox__label">
                        <input type="checkbox"
                            name="features_manufacture[${id}]"
                            class="manufacture_feature_id"
                            data-id="${id}"
                            data-type="features_manufacture"
                            data-check-type="prod"
                            data-checkbox=${dataAttrHasChild}
                            data-listen-input="checkbox" hidden>
                        <span class="tree__parent">
                            <span class="checkbox__checker"></span>
                        </span>
                    </label>
                </span>
            </div>`
        : '';

    return removeExtraSpaces(`
        <div class="checkbox mt-20" data-production-parent="checkbox" data-id="${id}">
            <div class="production__label d-flex flex-csb" data-production-item="feature">
                ${hasChildIcon}
                <div class="checkbox__wrap-text">
                    <span
                        class="
                            checkbox__text
                            production__text
                            vertical-align-middle
                        "
                        ${dataHasOpener}
                    >${name}</span>
                    ${featureInfo}
                </div>
                <div class="d-flex" data-checkboxes>
                    ${manufacture}
                    <div
                        class="production__col production__col--sale"
                        data-required>
                        <span data-production-format="sale">
                            <label class="checkbox__label">
                                <input type="checkbox"
                                    class="sale_feature_id"
                                    name="features_sale[${id}]"
                                    data-id="${id}"
                                    data-checkbox=${dataAttrHasChild}
                                    data-type="features_sale"
                                    data-check-type="sale" data-listen-input="checkbox" hidden
                                >
                                <span class="tree__parent">
                                    <span class="checkbox__checker"></span>
                                </span>
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            ${hasChild}
        </div>
    `);
};

export const renderChildrenHTML = (data, hasProduction, hiddenChild) => {
    const { id, name, required_type, children_count } = data;
    const hasChild = hasChildFn(children_count);
    const hasChildIcon = hasChildIconFn(children_count);
    const dataHasOpener = dataHasOpenerFn(children_count);
    const dataAttrHasChild = dataAttrHasChildFn(children_count);
    const featureInfo = renderFeatureInfo({ description: data.description, name, id });
    const manufacture = hasProduction
        ? `<div class="production__col production__col--prod" data-required>
                <span data-production-format="prod">
                    <label class="checkbox__label">
                        <input type="checkbox" name="features_manufacture[${id}]"
                            class="manufacture_feature_id"
                            data-id="${id}"
                            data-type="features_manufacture"
                            data-checkbox=${dataAttrHasChild}
                            data-check-type="prod" data-listen-input="checkbox" hidden>
                        <span class="tree__parent">
                            <span class="checkbox__checker"></span>
                        </span>
                    </label>
                </span>
            </div>`
        : '';

    return removeExtraSpaces(`
        <div class="checkbox tree__child production-tree__child mt-10" data-id="${id}"
            data-production-parent="checkbox">
            <div class="production__label d-flex flex-csb" data-production-item="feature">
                ${hasChildIcon}
                <div class="checkbox__wrap-text">
                    <span
                        class="
                            checkbox__text
                            production__text
                            vertical-align-middle
                            ${renderRequiredClass(required_type)}
                        "
                        ${dataHasOpener}
                    >${name}</span>
                    ${featureInfo}
                </div>
                <div class="d-flex" data-checkboxes>
                    ${manufacture}
                    <div
                        class="production__col production__col--sale" data-required>
                        <span data-production-format="sale">
                            <label class="checkbox__label">
                                <input type="checkbox" name="features_sale[${id}]" data-id="${id}"
                                    class="sale_feature_id"
                                    data-check-type="sale"
                                    data-checkbox=${dataAttrHasChild}
                                    data-type="features_sale"
                                    data-listen-input="checkbox" hidden>
                                <span class="tree__parent">
                                    <span class="checkbox__checker"></span>
                                </span>
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            ${hasChild}
        </div>
    `);
};

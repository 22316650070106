import { deleteFormDataKeys, setBlobToFormData, hasOwnProperty } from '@auxiliary/customMethods';

const fileData = {};

export default class Edit {
    static sendData(options) {
        const { form, url, instanceIntlPhone = null } = options;

        form.off('submit').on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.valid()) {
                const formData = new FormData($this[0]);
                const btn = $this.find('button[type="submit"]');

                if (instanceIntlPhone) {
                    const { name, number } = instanceIntlPhone;
                    number && formData.set(name, number);
                }

                btn.addClass('not-allowed');
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        Edit._correctFormData($this, formData);
                        Edit._removeErrors(form);
                    },
                    success() {
                        Edit._success();
                    },
                    error(data) {
                        Edit._error(data, btn, form);
                    },
                });
            } else {
                Edit._notValid($this);
            }
        });
    }

    static _correctFormData(form, formData) {
        const checkboxProdCategories = form.find(
            'input[type="checkbox"][name^="checkbox_production_category_"]:checked'
        );

        if (formData.has('legal_address')) {
            formData.delete('legal_address');
        }

        if (formData.has('checkbox_payment_type')) {
            formData.delete('checkbox_payment_type');

            if (!formData.has('is_brand')) formData.set('is_brand', 0);
        }

        if (formData.has('checkbox_warehouse_type')) {
            formData.delete('checkbox_warehouse_type');
        }

        if (checkboxProdCategories.length) {
            deleteFormDataKeys(formData, checkboxProdCategories);
        }

        if (!$.isEmptyObject(fileData)) {
            setBlobToFormData(formData, fileData);
        }
    }

    static changeFileSubscriber(emitter, _fileData = fileData) {
        if (emitter) {
            emitter.on('file:uploaded', ([blob, $input]) => {
                _fileData[$input[0].name] = [blob, $input.val()];
            });
            emitter.on('file:delete', $input => {
                const key = $input[0].name;
                if (hasOwnProperty(_fileData, key)) {
                    delete _fileData[key];
                }
            });
        }
    }

    static _success(url = '', selector = null) {
        const popup = url && selector ? $(selector) : $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                sessionStorage.clear();

                if (url) {
                    location.href = url;
                } else {
                    location.reload(true);
                }
            }, 300);
        });
    }

    static _error(data, btn, form) {
        const { errors } = data.responseJSON;

        btn.removeClass('not-allowed');

        if (!errors) {
            alert('Something Wrong!');
            return;
        }

        const keys = Object.keys(errors);
        const values = Object.values(errors);

        Edit._addErrors(form, keys, values);
    }

    static _addErrors(form, names, values) {
        names.forEach((name, i) => {
            let $input = form.find(`[name="${name}"]`);

            if (!$input.length) {
                $input = form.find(`[id="${name}"]`);
            }

            $input.addClass('is-invalid');
            $input.closest('.form-group').find('.form-group__tip-error').text(values[i].join(''));

            const $errors = form.find('.form-group__tip-error:not(:empty)');

            Edit._tabError($errors);

            if ($($errors[0]).is(':visible')) {
                $('html, body').scrollTop($($errors[0]).closest('.form-group').offset().top);
            }
        });

        form.find('[type=submit]').removeClass('not-allowed');
    }

    static _removeErrors(form) {
        const $inputs = form.find('.is-invalid');

        $('.custom-tabs__link').removeClass('custom-tabs__link--error');
        $inputs.each((_, input) => {
            const $input = $(input);

            $input.removeClass('is-invalid');
            $input.closest('.form-group').find('.form-group__tip-error').text('');
        });
    }

    static _tabError(errors) {
        let isScrollTop = false;

        errors.each((_, error) => {
            const $error = $(error);

            if (!$error.is(':visible')) {
                const numErrorTabContent = $error.closest('[data-tab-content]').data('tab-content');
                const errorTab = $(`.custom-tabs__link[data-tab="${numErrorTabContent}"]`);

                if (errorTab.hasClass('custom-tabs__link--active')) {
                    return;
                }
                errorTab.addClass('custom-tabs__link--error');

                if (!isScrollTop) {
                    isScrollTop = true;
                    $('html, body').scrollTop(errorTab.offset().top);
                }
            }
        });
    }

    static _notValid($form) {
        $('.custom-tabs__link').removeClass('custom-tabs__link--error');
        Edit._tabError(
            $form.find('label.error:not(:empty), .features-tree__children.error, .radio--error, [id^="feature_tree_"]')
        );
    }
}

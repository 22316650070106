import { ZoomFile } from './ZoomFile';

export class ZoomImages {
    constructor($wrap) {
        const $images = $wrap.find('img').attr({
            'data-popup': 'popup--zoom',
            'data-zoom': 'img',
        });

        if ($images.length) {
            new ZoomFile($images).init();
        }
    }
}

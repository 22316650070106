import { hiddenScroll } from '@auxiliary/customMethods';

export class ZoomFile {
    constructor($files) {
        this.$files = $files;
    }

    init() {
        this.click();
    }

    click() {
        $(this.$files)
            .off('click.zoom')
            .on('click.zoom', function () {
                ZoomFile._check($(this));
            });
    }
    static _html(file, name) {
        if (name === 'img') {
            return `<img src="${file.src}" alt="${file.alt}" title="${file.alt}" />`;
        }
        if (name === 'pdf') {
            return `
                <div class="plug-square">
                    <embed
                        src="${file}"
                        type="application/pdf"
                        pluginspage="${file}"
                    ></embed>
                </div>
            `;
        }
    }

    static _check(file) {
        return new Promise((resolve, reject) => {
            if ($(file).data('zoom') === 'img') {
                const src = $(file).attr('src'),
                    alt = $(file).attr('alt'),
                    img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.onerror = () => {
                    reject(src);
                };
                img.src = src;
                img.alt = img.title = alt;
            }

            if ($(file).data('zoom') === 'pdf') {
                resolve($(file).data('zoom-src'));
            }
        })
            .then(source => {
                $(`.${file.data('popup')}`)
                    .find('.popup__wrap')
                    .html(ZoomFile._html(source, $(file).data('zoom')));
                $(`.${file.data('popup')}`).addClass('js-popup-show');
                hiddenScroll();
            })
            .catch(err => {
                return false;
            });
    }
}

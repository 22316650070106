import { debounce } from '@auxiliary/customMethods';

export default class Resize {
    static _resized(emitter) {
        emitter.emit('page:resized', [$(window).width(), $(window).height()]);
    }

    static init(emitter) {
        window.addEventListener('resize', debounce(Resize._resized.bind(this, emitter), 50), false);
    }
}

import OwnProdVisibility from './OwnProdVisibility';

let oldIsBrand;

export default class Brand {
    static checkBrand(form, step, isRewriteSession = false) {
        const checkStep = step.attr('data-step');
        const firstStepSession = sessionStorage.getItem('1');
        const mainWrap = form.closest('[data-create]');
        const wrapRadioBtns = mainWrap.find('[data-form-step="2"]').find('[data-toggle-visibility]');
        const radioBtns = wrapRadioBtns.find('.radio');
        const fieldsBlock = mainWrap.find('[data-form-step="2"]').find('[data-container-visibility]');
        const inputs = fieldsBlock.find('[data-required-visibility]');

        if (Number(checkStep) === 1 && firstStepSession) {
            const checkbox = form.find('input[name="is_brand"]');
            const checked = checkbox.prop('checked');
            const wasUseOldIsBrand = typeof oldIsBrand !== 'undefined';

            if (checked) {
                if (wasUseOldIsBrand && isRewriteSession !== oldIsBrand) {
                    inputs.each((_, input) => {
                        const $input = $(input);

                        if (!$input.is('[type="checkbox"]')) {
                            $input.val('');
                        }

                        $input
                            .removeClass('error')
                            .closest('.form-group')
                            .removeClass('form-group--error')
                            .find('label.error')
                            .remove();
                    });
                }

                oldIsBrand = checked;
                wrapRadioBtns.off('change').find('.form-group__wrap-choice').addClass('cursor-not-allowed');
                radioBtns.each((_, radio) => {
                    const inputRadio = $(radio).find('input[type="radio"]');

                    $(radio).addClass('not-allowed');

                    if (inputRadio.is('#production_1')) {
                        inputRadio.prop('checked', true);
                    } else {
                        $(radio).addClass('op-03');
                    }
                });

                fieldsBlock.css('display', 'block');
            } else {
                OwnProdVisibility.init(wrapRadioBtns);
                wrapRadioBtns.find('.form-group__wrap-choice').removeClass('cursor-not-allowed');

                if (wasUseOldIsBrand && checked !== oldIsBrand) {
                    oldIsBrand = checked;
                    Brand._setBrandToDefault(radioBtns, fieldsBlock, inputs);
                    return;
                }

                oldIsBrand = checked;

                if (inputs[0].value || inputs[1].value) {
                    return;
                }

                if (fieldsBlock.is('[style]')) {
                    Brand._setBrandToDefault(radioBtns, fieldsBlock, inputs);
                }
            }
        }
    }

    static _setBrandToDefault(radioBtns, fieldsBlock, inputs) {
        inputs.each((_, input) => {
            const $input = $(input);

            if (!$input.is('[type="checkbox"]')) {
                $input.val('');
            }

            $input
                .removeClass('error')
                .closest('.form-group')
                .removeClass('form-group--error')
                .find('label.error')
                .remove();
        });

        radioBtns.each((_, radio) => {
            const inputRadio = $(radio).find('input[type="radio"]');

            $(radio).removeClass('not-allowed op-03');

            if (inputRadio.is('#production_2')) {
                inputRadio.prop('checked', true);
            }
        });

        fieldsBlock.css('display', 'none');
    }

    // NOTE: For edit company
    static handleBrand(form, checkbox) {
        const wrapRadioBtns = form.find('[data-tab-content="2"]').find('[data-toggle-visibility]');
        const radioBtns = wrapRadioBtns.find('.radio');
        const radioProduction = $('#production_1');
        const radioNoProduction = $('#production_2');

        checkbox.on('change', function () {
            const $this = $(this);
            const event = $.Event('change');

            if ($this.prop('checked')) {
                event.target = radioProduction[0];
                wrapRadioBtns.trigger(event);
                radioProduction.prop('checked', true);
                wrapRadioBtns.off('change').find('.form-group__wrap-choice').addClass('cursor-not-allowed');
                radioBtns.each((_, radio) => {
                    const inputRadio = $(radio).find('input[type="radio"]');

                    $(radio).addClass('not-allowed');

                    if (inputRadio.is('#production_2')) {
                        $(radio).addClass('op-03');
                    }
                });
            } else {
                OwnProdVisibility.init(wrapRadioBtns);
                wrapRadioBtns.find('.form-group__wrap-choice').removeClass('cursor-not-allowed');
                radioBtns.each((_, radio) => {
                    $(radio).removeClass('not-allowed op-03');
                });
                event.target = radioNoProduction[0];
                wrapRadioBtns.trigger(event);
                radioNoProduction.prop('checked', true);
            }
        });
    }
}

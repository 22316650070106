export default class Header {
    static init(wrap, emitter) {
        Header._scrollHeader(wrap);
    }

    static _scrollHeader(wrap, emitter) {
        const h_hght = 5; // высота шапки
        const h_mrg = 0; // отступ когда шапка уже не видна
        const elem = wrap;

        $(window).scroll(function(e) {
            const top = $(this).scrollTop();
            if (top+h_mrg < h_hght) {
                elem.removeClass('header-fix');
            } else {
                elem.addClass('header-fix');
            }
        });
    }
}

export class TabsDefault {
    constructor($wrapTabs) {
        this.$wrapTabs = $wrapTabs;
    }

    init() {
        this.$wrapTabs.on('click', '[data-tabs-link]', function (e) {
            e.preventDefault();
            const $this = $(this);
            const $wrapTabs = $this.closest('[data-tabs-default]');
            $wrapTabs.find('[data-tabs-link]').removeClass('active');
            $wrapTabs.find('[data-tabs-pane]').removeClass('active');

            $this.addClass('active');
            const activeTab = $this.data('tabs-link');
            $wrapTabs.find(`[data-tabs-pane="${activeTab}"]`).addClass('active');
        });
    }
}

import { CLASSES_IN_CATALOG_MENU, CLASSES_IN_MENU } from './catalogMenu.variables';

export const setHeight = ($el, height) => $el.css('--active-catalog-menu-height', `${height}px`);

export const moveMenuLinks = (links, from, to, mq) => {
    const checkMq = () => {
        if (mq.matches) {
            const $items = links.wrap('<li></li>').parent();
            to.append($items);
            $items.find('[data-menu-link]').removeClass(CLASSES_IN_MENU).addClass(CLASSES_IN_CATALOG_MENU);
        } else {
            const $items =
                links.parent()[0].tagName === 'LI'
                    ? links.unwrap().removeClass(CLASSES_IN_CATALOG_MENU).addClass(CLASSES_IN_MENU)
                    : links;
            from.append($items);
        }
    };

    checkMq();
    mq.addListener(checkMq);
};

import {
    BTN_TEXT_TEMPORARILY_CLOSE,
    BTN_TEXT_CLOSE,
    BTN_TEXT_RESTORE,
    BTN_TEXT_DELETE,
    LINK_TO_ADD_NEW_ADDRESS,
} from './constants';
import { removeExtraSpaces } from '@auxiliary/customMethods';

export const templateButtons = (text, addAddress = false) => {
    const buttonAction = addAddress
        ? `<a href="${LINK_TO_ADD_NEW_ADDRESS}" class="btn btn--primary minw-0">
            <span class="text text--spc-1_2 text--lh-1_6x">${text}</span>
        </a>`
        : `<button class="btn btn--primary minw-0" data-action>
            <span class="text text--spc-1_2 text--lh-1_6x">${text}</span>
        </button>`;

    return `
        <button class="btn btn--secondary js-close-popup minw-0">
            <span class="text text--spc-1_2 text--lh-1_6x">Нет, отмена</span>
        </button>
        ${buttonAction}
    `;
};

export const templateTemporarilyClose = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x">
                Вы уверены, что хотите временно закрыть “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-425 mx-auto">
            Временное закрытие означает, что на данный момент объект не работает. 
            На странице Вашей компании возле адреса этого объекта появится уведомление, что он временно закрыт.
        </p>
        <div class="popup__btns mt-5 mb-5">
            ${templateButtons(BTN_TEXT_TEMPORARILY_CLOSE)}
        </div>
    `);
};

export const templateClose = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x">
            Вы уверены, что хотите закрыть “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-425 mx-auto">
            Закрытие означает, что по этому адресу больше нет действующей точки продажи. 
        </p>
        <div class="popup__btns mt-5 mb-5">
            ${templateButtons(BTN_TEXT_CLOSE)}
        </div>
    `);
};

export const templateRestore = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x maxw-415 mx-auto">
                Вы уверены, что хотите восстановить работу объекта 
                “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-415 mx-auto">
            На странице Вашей компании будет отображено, что объект работает. 
        </p>
        <div class="popup__btns mt-5 mb-5">
            ${templateButtons(BTN_TEXT_RESTORE)}
        </div>
    `);
};

export const templateDelete = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x">
                Вы уверены, что хотите удалить “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-425 mx-auto">
            Удаление означает, что этот адрес больше не будет отображаться на странице компании. 
            Возврат данного действия невозможен.
        </p>
        <div class="popup__btns mt-5 mb-5">
            ${templateButtons(BTN_TEXT_DELETE)}
        </div>
    `);
};

export const templatePopupContentClose = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x maxw-425 mx-auto">
                Вы уверены, что хотите закрыть “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-425 mx-auto">
            Закрытие означает, что по этому адресу больше нет действующей точки продажи.
        </p>
        <p class="text text--lh-1_5x text--color-secondary mt-15 text--spc-_3 title--center maxw-425 mx-auto">
            За объектом “<span class="text text--break">${name}</span>” есть закрепленные товары. 
            Вы можете выбрать адрес из списка, на который хотите перенести товары с 
            “<span class="text text--break">${name}</span>”.
        </p>
        <p class="text text--lh-1_5x text--color-secondary mt-15 text--spc-_3 title--center maxw-425 mx-auto">
            Если подходящего адреса нет,
            <a href="${LINK_TO_ADD_NEW_ADDRESS}" 
                class="link text text--lh-1_5x text--fz-10 text--spc-1_2 text--upper">
                добавьте новый
            </a>
        </p>
    `);
};

export const templatePopupSelectOptions = (defaultText, options = '') => {
    const html = options
        ? options
        : `
        <li class="custom-select__option">
            <a class="selected" href="#" data-default-option>${defaultText}</a>
        </li>`;
    return removeExtraSpaces(html);
};

export const templatePopupContentDelete = name => {
    return removeExtraSpaces(`
        <div class="mt-25">
            <h2 class="title title--small title--center text--lh-1_5x maxw-425 mx-auto">
                Вы уверены, что хотите удалить “<span class="text text--break">${name}</span>”?
            </h2>
        </div>
        <p class="text text--lh-1_5x text--color-secondary mt-20 text--spc-_3 title--center maxw-425 mx-auto">
            Удаление означает, что этот адрес больше не будет отображаться на странице компании.
            Возврат данного действия невозможен. 
        </p>
        <p class="text text--lh-1_5x text--color-secondary mt-15 text--spc-_3 title--center maxw-425 mx-auto">
            За объектом “<span class="text text--break">${name}</span>” есть закрепленные товары. 
            Вы можете выбрать адрес из списка, на который хотите перенести товары с 
            “<span class="text text--break">${name}</span>”.
        </p>
        <p class="text text--lh-1_5x text--color-secondary mt-15 text--spc-_3 title--center maxw-425 mx-auto">
            Если подходящего адреса нет,
            <a href="${LINK_TO_ADD_NEW_ADDRESS}" 
                class="link text text--lh-1_5x text--fz-10 text--spc-1_2 text--upper">
                добавьте новый
            </a>
        </p>
    `);
};

export const templateError = () => {
    return removeExtraSpaces(`
        <span class="popup__select-error error">Это поле необходимо заполнить.</span>
    `);
};

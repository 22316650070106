import { checkValue } from '@auxiliary/customMethods';

export default class ToggleActivateInput {
    static init(form) {
        const mainWrap = form.find('.watch-input');

        mainWrap.each((_, wrap) => {
            const input = $(wrap).find('input, textarea');

            if (input.val()) {
                return $(wrap).addClass('active');
            }
        });

        form.on('focus', '.watch-input input, .watch-input textarea', function () {
            $(this).closest('.watch-input').addClass('active');
        });

        form.on('blur', '.watch-input input, .watch-input textarea', function () {
            const $this = $(this);
            const wrap = $this.closest('.watch-input');

            let tickID = setTimeout(() => {
                if ($this.val()) {
                    clearTimeout(tickID);
                    tickID = null;
                    return;
                }

                if (wrap.find('input, textarea').length > 1) {
                    if ([...wrap.find('input, textarea')].some(checkValue)) {
                        clearTimeout(tickID);
                        tickID = null;
                        return;
                    }
                }

                wrap.removeClass('active');
                clearTimeout(tickID);
                tickID = null;
            }, 0);
        });
    }
}

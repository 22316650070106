export const COMPANY_GUARANTEE = 'Гарантия от производителя';
export const SHOP_GUARANTEE = 'Гарантия от магазина';
export const INSTALLATION = 'Установка';
export const DELIVERY = 'Доставка';
export const PACKAGING = 'Упаковка';
export const SHIPMENT = 'Отгрузка';
export const ICON_NAME_INSTALLATION = 'tools';
export const ICON_NAME_DELIVERY = 'delivery';
export const ICON_NAME_PACKAGING = 'box';
export const ICON_NAME_SHIPMENT = 'shipment';
export const AVAILABLE_ON_ORDER = 'Под заказ';
export const AVAILABLE_DO_NOT_HAVE = 'Нет в наличии';
export const AVAILABLE_HAVE = 'В наличии';
export const AVAILABLE_EXPECTED = 'Ожидается';
export const COLOR_SECONDARY = 'text--color-secondary';
export const COLOR_SUCCESS = 'text--color-success';
export const COLOR_INFO = 'text--color-info';
export const SELLER_TYPE_1 = 'Розница';
export const SELLER_TYPE_2 = 'Опт';
export const SELLER_TYPE_3 = 'Опт и розница';
export const PRODUCTION_PERIOD = 'Срок производства: 10 дней';
export const CURRENCY = 'руб';
export const TO = 'до';
export const FROM = 'от';
export const WHOLESALE_PRICES = 'Оптовые цены';
export const ORDER = 'Заказать';
export const DETAILS = 'Подробнее';
export const ABOUT_COMPANY = 'О компании';
export const COMPANY_GUARANTEE_EN = 'Manufacturer\'s warranty';
export const SHOP_GUARANTEE_EN = 'Warranty from the store';
export const INSTALLATION_EN = 'Installation';
export const DELIVERY_EN = 'Delivery';
export const PACKAGING_EN = 'Packaging';
export const SHIPMENT_EN = 'Shipment';
export const ICON_NAME_INSTALLATION_EN = 'tools';
export const ICON_NAME_DELIVERY_EN = 'delivery';
export const ICON_NAME_PACKAGING_EN = 'box';
export const ICON_NAME_SHIPMENT_EN = 'shipment';
export const AVAILABLE_ON_ORDER_EN = 'Under the order';
export const AVAILABLE_DO_NOT_HAVE_EN = 'Out of stock';
export const AVAILABLE_HAVE_EN = 'In stock';
export const AVAILABLE_EXPECTED_EN = 'Expected';
export const COLOR_SECONDARY_EN = 'text--color-secondary';
export const COLOR_SUCCESS_EN = 'text--color-success';
export const COLOR_INFO_EN = 'text--color-info';
export const SELLER_TYPE_1_EN = 'Retail';
export const SELLER_TYPE_2_EN = 'Wholesale';
export const SELLER_TYPE_3_EN = 'Wholesale and retail';
export const PRODUCTION_PERIOD_EN = 'Production period: 10 days';
export const CURRENCY_EN = 'rub';
export const TO_EN = 'to';
export const FROM_EN = 'from';
export const WHOLESALE_PRICES_EN = 'Wholesale prices';
export const DETAILS_EN = 'Details';
export const ORDER_EN = 'Order';
export const ABOUT_COMPANY_EN = 'About company';


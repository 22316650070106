export class DropDown {
    constructor(options) {
        this.options = options;
        this.emitter = this.options.emitter;
        this.dropDownOpen = this.dropDownOpen.bind(this);
        this.dropDownHide = this.dropDownHide.bind(this);
        this._dropdownFixedHide = this._dropdownFixedHide.bind(this);
    }

    init() {
        $('body').on(`click.${this.options.eventNameOpen}`, '[data-dropdown-opener]', this.dropDownOpen);

        // NOTE: for fixed Dropdown
        this.emitter?.on('page:resized', () => {
            const $dropdown = $('[data-dropdown].active').filter((_, $elem) => {
                return ['fixed', 'parent-fixed'].includes($($elem).attr('data-dropdown'));
            });

            if ($dropdown.length) {
                const $opener = $dropdown.find('[data-dropdown-opener]')[0];
                const $content = $dropdown.find('[data-dropdown-content]');
                this.dropDownSetFixed($dropdown, $opener, $content);
            }
            return;
        });

        $('[data-dropdown-parent]').on('scroll.dropdownHide', () => {
            this._dropdownFixedHide();
        });

        $(window)
            .off('scroll.dropdownHide')
            .on('scroll.dropdownHide', () => {
                this._dropdownFixedHide();
            });
    }

    dropDownOpen(e) {
        const $opener = e.target.closest('[data-dropdown-opener]');
        const $target = $(e.target).closest('[data-dropdown]');
        const $content = $target.find('[data-dropdown-content]');

        if (['fixed', 'parent-fixed'].includes($target.attr('data-dropdown'))) {
            this.dropDownSetFixed($target, $opener, $content);
        }

        $opener.getAttribute('data-dropdown-opener') === 'toggle'
            ? $target.toggleClass('active')
            : $target.addClass('active');

        this.dropDownHide($target);
    }

    dropDownHide($dropDown) {
        $('body')
            .off(`click.${this.options.eventNameHide}`)
            .on(`click.${this.options.eventNameHide}`, function (e) {
                if (!$.contains($dropDown[0], e.target)) {
                    $dropDown.removeClass('active').removeAttr('style');
                }
            });
    }

    dropDownSetFixed($dropdown, $opener, $content) {
        $dropdown.removeClass('pos-top');

        if ($dropdown.attr('data-dropdown') === 'fixed') {
            const coords = $opener.closest('[data-dropdown-opener]').getBoundingClientRect();
            $content.css({ position: 'fixed', left: `${coords.left}px`, top: `${coords.bottom}px` });

            const bottom = window.innerHeight - (coords.bottom + ($content.outerHeight() + 10));

            if (bottom < 0) {
                $content.css({ top: `${coords.top - $content.outerHeight(true)}px` });
                $dropdown.addClass('pos-top');
            }
        } else if ($dropdown.attr('data-dropdown') === 'parent-fixed') {
            const coords = $opener.closest('[data-dropdown-parent-fixed]').getBoundingClientRect();

            const bottom =
                window.innerHeight - (coords.bottom + ($($opener).outerHeight() + $content.outerHeight() + 10));

            $dropdown.css({ position: 'fixed', left: `${coords.left}px`, top: `${coords.top}px` });

            if (bottom < 0) {
                $dropdown.addClass('pos-top');
            }
        }
    }

    _dropdownFixedHide() {
        $('[data-dropdown="fixed"].active').removeClass('active');
        $('[data-dropdown="parent-fixed"].active').removeClass('active').removeAttr('style');
    }
}

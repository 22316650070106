export class ItcCustomSelect {
  static EL = 'itc-select';
  static EL_SHOW = 'itc-select_show';
  static EL_OPTION = 'itc-select__option';
  static EL_OPTION_SELECTED = 'itc-select__option_selected';
  static DATA = '[data-select]';
  static DATA_TOGGLE = '[data-select="toggle"]';
  static _params;

  constructor(target, params) {
    const vm = this;
    this._el = typeof target === 'string' ? document.querySelector(target) : target;

    this._params = params || {};
    ItcCustomSelect._params = this._params;

    this._onClickFn = this._onClick.bind(this);
    this._onInputFn = this._onInput.bind(this);

    if (this._params.options) {
      this._el.innerHTML = this.constructor.template(this._params);
      this._el.classList.add(this.constructor.EL);
    }
    this._elToggle = this._el.querySelector(this.constructor.DATA_TOGGLE);
    this._el.addEventListener('click', this._onClickFn);
    this._el.addEventListener('input', this._onInputFn);

    $.fn.extend({
      toggleText: function(a, b) {
          return this.text(this.text() == b ? a : b);
      }
    });

    $(document).on('click', function(e) {
      const $el = $(vm._el);
      if ( !$el.find(e.target).length ) {
          vm.hide();

          const chosen = $el.find('input:checked');
          $el.find('input:first').val(
            chosen
              ? chosen.data('title')
              : 'Выберите из списка'
          );

          chosen
            ? localStorage.setItem('dp_tenders_create_city', JSON.stringify({title: chosen.data('title'), value: chosen.val()}))
            : localStorage.removeItem('dp_tenders_create_city');
      } else {
        vm.show();
      }
    });
  }

  static loadOptions(options) {
    if (!options || !options.length) return;

    localStorage.setItem('dp_tenders_options', JSON.stringify(options));
    // this._el.innerHTML = this.template(this._params);
    // this._el.classList.add(this.constructor.EL);
    this.loadOptionsFromStorage();
  }

  static loadOptionsFromStorage() {
    this._el.innerHTML = this.template(this._params);
    this._el.classList.add(this.constructor.EL);
  }

  static deleteOptions(options) {
    $(this._el).find('.itc-select__options').html('');
  }

  static create(target, params) {
    this._el = typeof target === 'string' ? document.querySelector(target) : target;
    if (this._el) {
      return new this(target, params);
    }
    return null;
  }

  static template(params) {
    let { options } = params;
    const selectedContent = 'Выберите из списка';

    if ( !options || !options.length ) {
      options = JSON.parse(
        localStorage.getItem('dp_tenders_options')
      );
      this._params.options = options;
    }

    return `<input type="text" class="itc-select__toggle" data-select="toggle" placeholder="${selectedContent}" required/>
      <div class="itc-select__dropdown custom-scrollbar">
        <div class="itc-select__options">
          ${ options && options.length ? ItcCustomSelect.tree(options, params).join('') : '' }
        </div>
      </div>`;
  }

  static tree(options, params, group = null, level = 0) {
    const items = [];
    const city = localStorage.getItem('dp_tenders_create_city');
    const cityId = city ? JSON.parse(city).value : null;

    options.filter((el) => el.children == group)
      .forEach((option, index) => {
        items.push(`
          <div class="list_item_0">
            <a href="#" data-id="${index}" data-select="option">
              ${options.filter((el) => el.children == option.value).length ? `<span data-id="${index}">+</span>` : ''}
              ${option.title}
            </a>
            <div class="list_item_1 hide">
              ${
                options.filter((el) => el.children == option.value)
                  .map((chop, i) =>
                    `<a href="#" data-id="${i}" data-select="option">
                      ${chop.title}
                      <input class="itc-select_input" 
                        name="${params.name}" 
                        type="radio" 
                        value="${chop.value}" 
                        data-title="${chop.title}" 
                        ${cityId && chop.value == cityId ? 'checked' : ''}
                      />
                      <p class="checkbox__checker"></p>
                    </a>`
                  )
                  .join('')
              }
            </div>
          </div> 
        `);
      });

    return items;
  }

  static line(options, params) {
    const items = [];
    options.forEach((option, index) => {
        if (option.disabled) return;
        items.push(`
          <div class="list_item_0">
            <a href="#" data-id="${index}" data-select="option" data-value="${option.value}" data-index="${index}">
              ${option.title}
              <input class="itc-select_input" name="${params.name}" type="radio" value="${option.value}" data-title="${option.title}">
              <p class="checkbox__checker"></p>
            </a>
          </div> 
        `);
      });

    return items;
  }

  static declination(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
  }

  _onClick(e) {
    const { target } = e;
    if (!target.dataset) return;

    const type = $(target).find(this.constructor.DATA).get(0)
      ? $(target).find(this.constructor.DATA).get(0).dataset.select
      : target.dataset.select;

    if (type === 'toggle') {
      this.show();
    } else {
      const $target = $(e.target).is('a')
        ? $(e.target)
        : (
          $(e.target).is('span')
            ? $(e.target).parent()
            : $(e.target).prev()
        );

      $target.children('span').toggleText('+', '-');
      $target.parent().find('div').toggleClass('hide'); //slideToggle(200);

      //const element = $(e.target).prev();
      //if ( element.is('input') ) {
      //   element.prop('checked', !element.is(':checked'));
      // }
      const $input = $target.is('input') ? $target : $target.find('input').first();
      if ( $target.is('a') || $target.is('input') ) {
        $input.prop('checked', !$input.is(':checked'));
      }
    }
  }

  _onInput(e) {
    const { target } = e;
    if (!target.dataset.select) return;

    const $optionsBlock = $('.itc-select__options');

    if (target.value.length > 0) {
      const params = Object.assign({}, this._params);
      params.options = params.options.filter((el) => el.title.toUpperCase().indexOf(target.value.toUpperCase()) >=0);

      $optionsBlock.html(
        ItcCustomSelect.line(params.options, params).join('')
      );
    } else {
      $optionsBlock.html(
        ItcCustomSelect.tree(this._params.options, this._params).join('')
      );
    }

    this.show();
  }

  show() {
    // document.querySelectorAll(this.constructor.EL_SHOW)
    //   .forEach((el) => {
    //     el.classList.remove(this.constructor.EL_SHOW);
    //   });
    this._el.classList.add(`${this.constructor.EL_SHOW}`);
  }

  hide() {
    this._el.classList.remove(this.constructor.EL_SHOW);
  }

  toggle() {
    this._el.classList.contains(this.constructor.EL_SHOW) ? this.hide() : this.show();
  }

  dispose() {
    this._el.removeEventListener('click', this._onClickFn);
  }
}

import { hiddenScroll, visibleScroll } from '@auxiliary/customMethods';

let curWidth;
let oldWidth = $(window).width();


export default class Header {
    static initHeaderCompany(wrap, emitter) {
        Header._addNightMode(wrap);
        Header._toggleNavMenu(wrap);
        Header._closeNavMenu(wrap, emitter);
    }

    static _addNightMode(wrap) {
        const mqMax991 = window.matchMedia('(max-width: 991px)');

        const checkMq = () => {
            if (mqMax991.matches) {
                wrap.addClass('header--night');
            } else {
                wrap.removeClass('header--night');
            }
        };

        checkMq();
        mqMax991.addListener(checkMq);
    }

    static _toggleNavMenu(wrap) {
        const btn = wrap.find('.header__btn-nav');
        const overlay = wrap.find('.header__menu-overlay');

        const toggleNavMenu = method => {
            wrap[method]('active');

            if (curWidth && curWidth !== oldWidth) {
                oldWidth = curWidth;
            }

            if (wrap.hasClass('active')) {
                $('html, body').scrollTop(0);
                hiddenScroll();
            } else {
                visibleScroll();
            }
        };

        btn.on('click', function (e) {
            e.preventDefault();
            toggleNavMenu('toggleClass');
        });

        overlay.on('click', function () {
            toggleNavMenu('removeClass');
        });
    }

    static _closeNavMenu(wrap, emitter) {
        emitter.on('page:resized', ([width, _]) => {
            curWidth = width;
            if (wrap.hasClass('active') && curWidth !== oldWidth) {
                oldWidth = curWidth;
                wrap.find('.header__menu-overlay').trigger('click');
            }
        });
    }
}

import { Fields } from '@components/create/fields/Fields';
import { Uploader } from '../uploader/Uploader';
import { createFileHTML, createNewHTML } from './uploadFile.template';
import { isImage, fireSome } from '@auxiliary/customMethods';

window.URL = window.URL || window.webkitURL;

let croppedPhoto;

export class UploadFile extends Uploader {
    static async init($wrap, options) {
        const $createdFiles = $wrap.find('[data-created-file]');
        const mainName = options.mainName ? options.mainName : 'image';
        const additionalName = options.additionalName ? options.additionalName : 'add_images';
        const HTML = createNewHTML(
            $createdFiles.length ? Fields.findMaxNumber($createdFiles) : 0,
            mainName,
            additionalName,
            options.uploader.extensions.join(','),
            $wrap.is('[data-upload-file="file"]')
        );

        if (!croppedPhoto) {
            croppedPhoto = await super.init(document.getElementById('crop-wrap'), options);
        }

        $wrap.on('change.add', 'input[type="file"]', function (e) {
            UploadFile.addField.call(this, e, croppedPhoto, HTML, options);
        });
        $wrap.on('click.remove', '[data-remove-fields]', function (e) {
            UploadFile.removeField.call(this, e, HTML, options);
        });
    }

    static async addField(e, croppedPhoto, HTML, options) {
        const $this = $(this);
        const $parent = $this.closest('.file__wrap');
        const input = e.target;
        const isCorrectSize = input.files[0].size <= options.uploader.maxSize;
        const { lookup } = await import(/* webpackChunkName: "mime-types" */ 'mime-types');
        const type = lookup(input.files[0].name);
        const isCorrectExtension = fireSome(options.uploader.extensions, type);

        UploadFile.readFile(e, $parent, croppedPhoto, options);

        if (!isCorrectSize || !isCorrectExtension) {
            return;
        }

        options.emitter.once('file:beforeUploaded', ([type, fileName, data]) => {
            UploadFile.handleAddFile({ $file: $this, $parent, HTML, data, fileName, type, options });
        });
    }

    static async handleAddFile({ $file, $parent, HTML, data, fileName, type, options }) {
        let blob;

        try {
            if (data instanceof Blob) {
                blob = data;
            } else {
                throw Error(`${data.toString()} is not blob!`);
            }
        } catch (error) {
            throw Error(error.message);
        }

        const $mainParent = $parent.closest('.file');
        const isDocument = (options.uploader.document || options.uploader.certificate) && !isImage(type);
        const $input = $parent.find('input[type="text"][name]');
        const dataFile = Number($file.data('file'));
        const emptyWrap = $mainParent.find('.empty');
        const isFileReady = $parent.is('[data-ready-file]');
        const isMainFile = $file.is('[data-main-file]');
        const countWraps = $mainParent.find('.file__wrap:not(.empty)').length;
        const url = !isDocument ? URL.createObjectURL(blob) : null;
        $parent.replaceWith(createFileHTML($input[0].name, dataFile, url, fileName, isMainFile, isDocument));
        if (isImage(type)) {
            $parent.find('img').on('load', function () {
                URL.revokeObjectURL(this.src);
            });
        }
        if (!isMainFile && !isFileReady && countWraps < options.optionalFile) {
            emptyWrap.before(HTML());
        } else if (isMainFile && !isFileReady && countWraps < options.mainFile) {
            emptyWrap.before(HTML(true));
        }

        options.emitter.emit('file:uploaded', [blob, $(`input[name="${$input[0].name}"]`)]);
    }

    static removeField(e, HTML, options) {
        e.preventDefault();

        const $this = $(this);
        const $parent = $this.closest('.file__wrap');
        const $input = $parent.find('input[type="text"][name]');

        UploadFile.handleRemoveFile({ $input, $parent, HTML, options });
    }

    static handleRemoveFile({ $input, $parent, HTML, options }) {
        const $mainParent = $parent.closest('.file');
        const countReadyFiles = $mainParent.find('[data-ready-file]').length - 1;
        const editFileUpload = countFiles => {
            $parent.remove();

            const fileUpload = $mainParent.find('.file__wrap:not(.empty) .file__upload');

            countReadyFiles < options[countFiles] && !fileUpload.length
                ? $mainParent.find('.empty').before(HTML(countFiles === 'mainFile' ? true : false))
                : null;
        };

        UploadFile.fireEmitOnDelete(options.emitter, $input);

        if ($input.is('[data-main-file]')) {
            editFileUpload('mainFile');
        } else {
            editFileUpload('optionalFile');
        }
    }

    static fireEmitOnDelete(emitter, $input) {
        emitter.emit('file:delete', $input);
    }

    static deleteFileFromDataBase({ url, data }) {
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            data,
            error() {
                alert('Something Wrong! (File deletes)');
            },
        });
    }
}

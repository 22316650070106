import { PaginationJs } from '@components/UI/pagination/PaginationJs';
import { SORT_DATE, SORT_ASCENDING_PRICE, SORT_DESCENDING_PRICE } from '@auxiliary/constants';
import { sort } from '@auxiliary/customMethods';
import { isDiscountDate } from '@components/product/productPage/productOffers.functions';

let paginationJs = null;
const initPagination = (data, list, template, emitter, isMounted = false) => {
    paginationJs = new PaginationJs(list, {
        data,
        template: template,
        emitter,
        isMounted,
    });
    paginationJs.init();
};
const sortPrices = (array, { price, discountPrice, discountDate, wholesales }) => {
    return array.sort((a, b) => {
        const checkDiscount = val => {
            return isDiscountDate(val[discountDate]) && val[discountPrice];
        };

        switch (true) {
            case (checkDiscount(a) || a[price] || a[wholesales][0][price]) >
                (checkDiscount(b) || b[price] || b[wholesales][0][price]):
                return 1;
            case (checkDiscount(a) || a[price] || a[wholesales][0][price]) <
                (checkDiscount(b) || b[price] || b[wholesales][0][price]):
                return -1;
            default:
                return 0;
        }
    });
};

export class Sort {
    static sortProductOffers(data, options) {
        const emitter = options.emitter;
        const dataForSort = data.slice(1);
        emitter.on('custom-select:selected', val => {
            if (paginationJs) {
                paginationJs.destroy();
            }
            if (val === SORT_DATE) {
                Sort._sortProductOffersByDate(data[0], dataForSort, options);
            } else if (val === SORT_ASCENDING_PRICE) {
                Sort._sortProductOffersByAscendingPrice(data[0], dataForSort, options);
            } else if (val === SORT_DESCENDING_PRICE) {
                Sort._sortProductOffersByDescendingPrice(data[0], dataForSort, options);
            }
        });
        if (!paginationJs) {
            initPagination(data, options.list, options.template, emitter, true);
        }
    }

    static _sortProductOffersByDate(ownerItem, data, options) {
        const sortedData = [ownerItem, ...sort(data, 'id')];
        initPagination(sortedData, options.list, options.template, options.emitter);
    }

    static _sortProductOffersByAscendingPrice(ownerItem, data, options) {
        const sortedData = [
            ownerItem,
            ...sortPrices(data, {
                price: 'price',
                discountPrice: 'row_discount_price',
                discountDate: 'discount_date',
                wholesales: 'wholesales',
            }),
        ];
        initPagination(sortedData, options.list, options.template, options.emitter);
    }

    static _sortProductOffersByDescendingPrice(ownerItem, data, options) {
        const sortedData = [
            ownerItem,
            ...sortPrices(data, {
                price: 'price',
                discountPrice: 'row_discount_price',
                discountDate: 'discount_date',
                wholesales: 'wholesales',
            }).reverse(),
        ];
        initPagination(sortedData, options.list, options.template, options.emitter);
    }
}

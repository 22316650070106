import { YM_CODE } from '@auxiliary/constants';

export class YMEvent {
    constructor() {
        this.namesEvents = [];
    }

    static successfulRegistration() {
        if (
            window.ym &&
            location.pathname === '/cabinet/user/profile' &&
            /\/register-confirmation\/?$/.test(document.referrer)
        ) {
            ym(YM_CODE, 'reachGoal', 'registration_success');
        }
    }

    static search() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'search');
    }

    static companyPageView($link) {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'company_page_view');
    }

    static fromCompanyPageToCompanySite() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'from_company_page_to_company_site');
    }

    static productPageView() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'product_page_view');
    }

    static productOffersView() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'product_offers_view');
    }

    static productOffersMapView() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'product_offers_map_view');
    }

    static fromProductOfferToShop() {
        if (!window.ym) {
            return;
        }

        ym(YM_CODE, 'reachGoal', 'from_product_offer_to_shop');
    }
}

import { warn } from '@auxiliary/customMethods';
import { renderFileList } from '@components/UI/showFIleList/showFileList.template';

export class ShowFileList {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$wrapFileList = $('#documents-list');
        this.emitter = options.emitter;
    }

    init() {
        const self = this;
        this.emitter.on('fileList:closed', () => {
            this.$wrapFileList.slideUp(200, function () {
                $(this).removeAttr('style').html('');
            });
        });
        this.$wrap.on('click', '[data-review-id]', function (e) {
            e.preventDefault();
            const $this = $(this);

            $this.addClass('not-allowed');
            self._getFile({
                url: $this.data('url'),
                id: $this.data('review-id'),
                btn: $this,
            });
        });
    }

    _getFile({ url, id, btn }) {
        (async () => {
            try {
                const response = await this.xhr(url, id);
                btn.removeClass('not-allowed');
                response.forEach((item, index) => {
                    this.$wrapFileList.append(renderFileList(item.original_name, item.file, index));
                });
                this.$wrapFileList.slideDown(200);
            } catch (error) {
                warn(`Error: ${error}`);
            }
        })();
    }

    xhr(url, id) {
        return $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            contentType: false,
            processData: false,
            data: id,
        });
    }
}

export const MAP_KEY = process.env.MIX_MAP_KEY;
// export const YANDEX_MAP_KEY = process.env.MIX_YANDEX_MAP_KEY;
export const YANDEX_MAP_KEY = '39880d2a-653f-461e-8104-180f1a2aafd0';
export const CLUSTER_URL =
    // eslint-disable-next-line max-len
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclustererplus@4.0.1.min.js';
export const MAP_URL =
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&language=ru&region=RU`;
export const MAP_URL_WITH_AUTOCOMPLETE =
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&libraries=places&language=ru&region=RU`;
export const MAP_CLUSTERS_IMG =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
export const YANDEX_MAP_URL =
    //     // eslint-disable-next-line max-len
    `https://api-maps.yandex.ru/2.1/?apikey=${YANDEX_MAP_KEY}&lang=ru_RU`;
export const VK_WIDGETS_POST_URL = 'https://vk.com/js/api/openapi.js?169';
export const YM_CODE = 68696833;
export const ERROR_TEXT = 'Произошла ошибка, повторите это действие позже';
export const MIN_SCROLL_HEIGHT = 1400;
export const MAP_ICON = '/images/svg/Location.svg';
export const MAP_ICON_ACTIVE = '/images/svg/Location_active.svg';
export const DATE_PATTERN = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
export const PNG = 'image/png';
export const JPEG = 'image/jpeg';
export const JPG = 'image/jpg';
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLS = 'application/vnd.ms-excel';
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const XLSM = 'application/vnd.ms-excel.sheet.macroenabled.12';
export const XLTX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
export const XLTM = 'application/vnd.ms-excel.template.macroenabled.12';
export const MAIN_STARS = 5;
export const LOCK_TOOLTIP_SIDE_WIDTH = 20;
export const SORT_DATE = 'Дате добавления';
export const SORT_ASCENDING_PRICE = 'Возрастанию цены';
export const SORT_DESCENDING_PRICE = 'Убыванию цены';
export const ITEM = 'Штука';
export const SET = 'Комплект';
export const PACKAGE = 'Упаковка';
export const SQUARE_METRE = 'Квадратный метр';
export const TYPE_NUMBER = 'Только цифры';
export const TYPE_TEXT = 'Текстовое поле';
export const TYPE_PLURAL_VALUE = 'Множественный со значениями';
export const TYPE_SINGULAR_VALUE = 'Одинарный со значениями';
export const TYPE_END = 'Конечный';
export const NO_PHOTO = '/images/svg/no_photo.svg';
export const FEATURE_OBLIGATION_ALWAYS = 'Всегда';
export const FEATURE_OBLIGATION_TO_SELECT = 'На выбор';
export const FEATURE_OBLIGATION_NOT_REQUIRED = 'Необязательно';
export const COMPANY_DIR = '/storage/Company/';
export const PRODUCT_DIR = '/storage/Product/';
export const CERTIFICATE_DIR = '/storage/Certificate/';
export const GROUP_DIR = '/storage/features-group/';
export const TEXT_ERROR_MESSAGE = 'Opps! Something went wrong... ¯/_(ツ)_/¯';

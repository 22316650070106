import { YMEvent } from '@components/metrics/YMEvent/YMEvent';
import { GEvent } from '@components/metrics/gEvent/GEvent';
import MoveEl from '@auxiliary/MoveEl';
import { ShowMore } from '@components/UI/showMore/ShowMore';

export class CompanyPage {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$socials = $wrap.find('[data-company-socials]');
        this.$linkToSite = this.$wrap.find('[data-g-event="from_company_page_to_company_site"]');
        this.emitter = options.emitter;
    }

    init() {
        GEvent.companyPageView();
        YMEvent.companyPageView();

        this.$linkToSite.on('click', () => {
            GEvent.fromCompanyPageToCompanySite();
            YMEvent.fromCompanyPageToCompanySite();
        });

        MoveEl.init({
            el: $('#company-page-about'),
            from: $('#company-page-main'),
            to: $('#company-page-info'),
            methodFrom: 'after',
            methodTo: 'after',
            mq: window.matchMedia('(max-width: 1199px)'),
        });
        MoveEl.init({
            el: $('#company-page-links'),
            from: $('#company-page-main-info'),
            to: $('#company-page-info-inner-top'),
            methodFrom: 'append',
            methodTo: 'after',
            mq: window.matchMedia('(max-width: 767px)'),
        });
        const showMoreWithHeight = new ShowMore($('[data-show-more="with-height"]'), {
            emitter: this.emitter,
        });
        showMoreWithHeight.showMoreWithMaxHeight();
        const showMorePhones = new ShowMore($('[data-show-more="phone-items"]'), {});
        showMorePhones.showMoreItems(2);
        const showMoreEmails = new ShowMore($('[data-show-more="email-items"]'), {});
        showMoreEmails.showMoreItems(this.$socials.length ? 1 : 2);
    }
}

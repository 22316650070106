import Edit from '../../edit/Edit';
import { IntlPhones } from '@components/intlPhone/IntlPhone';

export default class EditWarehouse extends Edit {
    static init(form) {
        this.instanceIntlPhone = null;

        this.instanceIntlPhone = new IntlPhones(
            document.querySelectorAll('input[type="tel"][data-intl-phone]'),
            {}
        ).init()[0];

        super.sendData({
            form,
            url: '/cabinet/user/company/warehouse/edit',
            instanceIntlPhone: this.instanceIntlPhone,
        });
    }
}

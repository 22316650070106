import { renderDefaultLogoTemplate, renderStartLogoTemplate } from './company.template';

const disableLogo = $logoWrap => {
    $logoWrap.empty().append(renderDefaultLogoTemplate());
};

const enableLogo = $logoWrap => {
    $logoWrap.empty().append(renderStartLogoTemplate());
};

export const toggleLogo = ($checkbox, $logoWrap) => {
    $checkbox.on('change', function () {
        const $this = $(this);

        if ($this.prop('checked')) {
            disableLogo($logoWrap);
        } else {
            enableLogo($logoWrap);
        }
    });
};

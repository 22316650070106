import 'jquery-validation/dist/jquery.validate';
import { hiddenScroll, asyncEach, isLastIteration } from '@auxiliary/customMethods';
import { fireResolve, removeErrorForParentFeature } from '@components/recursive/featureTree/featuresTree.functions';
import { ITEM_PRODUCTION_SELECTOR } from './production.variables';

export const replaceAnimated = ($oldEl, $newEl) => {
    return new Promise(resolve => {
        $oldEl.fadeOut(200, function () {
            $(this).replaceWith($newEl);
            $newEl.fadeIn(200, function () {
                resolve($(this));
            });
        });
    });
};

export const checkFeatureObligations = () => {
    const errorsCollection = new Set();

    const dispatch = $features => {
        const mainParentFeaturesList = $features.closest('[data-production-children]');

        return new Promise(resolve => {
            if (!mainParentFeaturesList.length) {
                errorsCollection.clear();
                return resolve([...errorsCollection]);
            }

            asyncEach([...mainParentFeaturesList], (wrap, index, list) => {
                const $currentWrap = $(wrap);
                const $productionFeatureList = $currentWrap
                    .children()
                    .find(`> [data-production-parent] > ${ITEM_PRODUCTION_SELECTOR} .tree__required`);
                const $featureAllObligations = $productionFeatureList.closest(ITEM_PRODUCTION_SELECTOR);
                const $mainParentItem = $currentWrap.siblings(ITEM_PRODUCTION_SELECTOR);
                const $mainParentRequired = $mainParentItem.find('.tree__required');
                const $parentItems = $currentWrap
                    .parents('[data-production-parent]')
                    .find(`> ${ITEM_PRODUCTION_SELECTOR}`);
                const $parentCheckboxes = $mainParentItem.find(':checkbox[data-listen-input="checkbox"]');
                const isParentChecked = Boolean($parentCheckboxes.filter(':checked').length);
                const isLastWrap = isLastIteration(index, list);

                // NOTE: obligation - to select
                const $featuresToSelect = $productionFeatureList
                    .filter('.tree__required--to-select')
                    .closest(ITEM_PRODUCTION_SELECTOR);
                const isCheckedOneOfToSelect = Boolean($featuresToSelect.find(':checkbox:checked').length);

                // NOTE: obligation - always
                const $featuresAlways = $productionFeatureList
                    .filter('.tree__required--always')
                    .closest(ITEM_PRODUCTION_SELECTOR);
                const $checkedFeaturesAlways = $featuresAlways
                    .find(':checkbox:checked')
                    .closest(ITEM_PRODUCTION_SELECTOR);
                const $featureAlwaysParents = $featuresAlways
                    .find(':checkbox:not(:checked)')
                    .closest('[data-required]');
                let $notCheckedFeaturesAlways;
                const isCheckedOneOfAlways = $checkedFeaturesAlways.length;
                const isCheckedAllAlways = Boolean(isCheckedOneOfAlways === $featuresAlways.length);

                // NOTE: Checking siblings checkboxes
                if ($featureAlwaysParents.siblings('[data-required]').length) {
                    $notCheckedFeaturesAlways = $featureAlwaysParents
                        .siblings('[data-required]')
                        .find(':checkbox:not(:checked)')
                        .closest(ITEM_PRODUCTION_SELECTOR);
                } else {
                    $notCheckedFeaturesAlways = $featureAlwaysParents
                        .closest('[data-required]')
                        .closest(ITEM_PRODUCTION_SELECTOR);
                }

                // NOTE: obligation - for all
                if (!isParentChecked) {
                    $featureAllObligations.removeClass('tree__wrap-error');
                    errorsCollection.size ? errorsCollection.delete(index) : null;

                    if (!$mainParentRequired.length) {
                        removeErrorForParentFeature($parentItems, '[data-production-children]');
                        fireResolve(isLastWrap, resolve, [...errorsCollection]);
                        return;
                    }
                }

                // NOTE: obligation - to select
                if (isParentChecked && isCheckedOneOfToSelect && $featuresToSelect.length) {
                    $featuresToSelect.removeClass('tree__wrap-error');

                    if ($featuresAlways.length === 0 || isCheckedAllAlways) {
                        removeErrorForParentFeature($parentItems, '[data-production-children]');
                        errorsCollection.size ? errorsCollection.delete(index) : null;
                    } else {
                        errorsCollection.add(index);
                    }
                } else if (isParentChecked && !isCheckedOneOfToSelect && $featuresToSelect.length) {
                    $featuresToSelect.addClass('tree__wrap-error');
                    $parentItems.addClass('tree__wrap-error');
                    errorsCollection.add(index);
                }

                // NOTE: obligation - always
                if (isParentChecked && isCheckedOneOfAlways) {
                    $checkedFeaturesAlways.removeClass('tree__wrap-error');

                    if (isCheckedAllAlways && ($featuresToSelect.length === 0 || isCheckedOneOfToSelect)) {
                        removeErrorForParentFeature($parentItems, '[data-production-children]');
                        errorsCollection.size ? errorsCollection.delete(index) : null;
                    } else {
                        errorsCollection.add(index);
                    }
                }

                if (isParentChecked && $notCheckedFeaturesAlways.length) {
                    $notCheckedFeaturesAlways.addClass('tree__wrap-error');
                    $parentItems.addClass('tree__wrap-error');
                    errorsCollection.add(index);
                }

                fireResolve(isLastWrap, resolve, [...errorsCollection]);
            });
        });
    };

    return {
        dispatch,
    };
};

export const checkedSome = $els => {
    return [...$els].some(el => $(el).is(':checked'));
};

export const setNotCheckedChildren = ($children, self) => {
    asyncEach([...$children], $el => {
        if (self.not(':checked') && self.data('check-type') === 'prod') {
            if ($($el).data('check-type') === 'prod') {
                $($el).not(self).prop('checked', false);
            }
        }

        if (self.not(':checked') && self.data('check-type') === 'sale') {
            $($el).not(self).prop('checked', false);
        }
    });
};

export const openerEl = el =>
    el.closest('[data-production-parent]').find('.icon-plus-open[data-production-opener]:first');

export const fadeToggleFeatures = ($features, type, checked, animate = 400) => {
    const fnProdHidden = (el, hidden = '') => {
        const $hasChildrenHide = $(el).closest('[data-production-parent]').find('[data-production-children]');
        if ($hasChildrenHide.length) {
            $hasChildrenHide.attr('data-children-hidden', `${hidden}`);
        }
    };
    asyncEach([...$features.find('[data-listen-input="checkbox"]')], el => {
        if ($(el).data('check-type') === type) {
            if (checked) {
                $(el).closest('[data-checkbox]').children().fadeIn(animate);
                $features.find(`[data-production-format="${$(el).data('check-type')}"]`).fadeIn(animate);
                fnProdHidden(el);
            } else {
                $(el).not(self).prop('checked', false);
                $(el).closest('[data-checkbox]').children().fadeOut(animate);
                $features.find(`[data-production-format="${$(el).data('check-type')}"]`).fadeOut(animate);
                fnProdHidden(el, $(el).data('check-type'));
            }
        }
    });
};

export const hiddenProduction = ($formatProd, self) => {
    asyncEach([...$formatProd], $el => {
        $($el).not(self).prop('checked', false);
    });
};

export const popupShow = name => {
    const popup = $(`.popup.popup--${name}`);
    popup.addClass('js-popup-show');
    hiddenScroll();
};

export class MainSlider {
    static async init($slider) {
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;

        new Swiper($slider, {
            spaceBetween: 0,
            slidesPerView: 'auto',
            navigation: {
                nextEl: $($slider).parent().find('.swiper-button-next')[0],
                prevEl: $($slider).parent().find('.swiper-button-prev')[0],
            },
        });
    }
}

import { LOCK_TOOLTIP_SIDE_WIDTH } from '@auxiliary/constants';

const classRight = 'tooltip__content--from-right';
const classLeft = 'tooltip__content--from-left';
let handleCheckSide = null;

export class Tooltip {
    static init($el, emitter) {
        if (!$el.length) {
            return;
        }

        Tooltip.changeSide($el);
        Tooltip._checkSide(emitter);
    }

    static changeSide($el) {
        $el.each((_, tooltip) => {
            if ($(tooltip).is(':hidden')) {
                return;
            }

            tooltip.classList.remove(classLeft, classRight);

            const isClassRightSide = tooltip.classList.contains(classRight);
            const isClassLeftSide = tooltip.classList.contains(classLeft);
            const documentWidth = document.documentElement.clientWidth;
            const rect = tooltip.getBoundingClientRect();
            const sides = {
                left: rect.left,
                right: rect.right,
            };

            if (sides.left < LOCK_TOOLTIP_SIDE_WIDTH) {
                tooltip.classList.add(classLeft);
            } else if (sides.left >= LOCK_TOOLTIP_SIDE_WIDTH && isClassLeftSide) {
                tooltip.classList.remove(classLeft);
            } else if (documentWidth - sides.right < LOCK_TOOLTIP_SIDE_WIDTH) {
                tooltip.classList.add(classRight);
            } else if (documentWidth - sides.right >= LOCK_TOOLTIP_SIDE_WIDTH && isClassRightSide) {
                tooltip.classList.remove(classRight);
            }
        });
    }

    static _checkSide(emitter) {
        if (handleCheckSide) {
            emitter.off('page:resized', handleCheckSide);
        }
        handleCheckSide = () => {
            Tooltip.changeSide($('[data-tooltip-content]'));
        };
        emitter.on('page:resized', handleCheckSide);
    }
}

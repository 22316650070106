export class TenderCategories {
    static init($prodParent, emitter) {
        $prodParent.on('click', '.list_item > a', function(e) {
            e.preventDefault();
            const title = $(this).children('span');
            TenderCategories.toggleText(title);
            $(this).next('.second_list').slideToggle(200);

            if ( $(e.target).is('div.checkbox__checker') ) {
                const checkbox = $(this).find(`[value="${$(this).data('id')}"]`);
                checkbox.prop('checked', !checkbox.is(':checked'));
                TenderCategories.clearRequired();
            }
        });
        $prodParent.on('click', '.second_list_item > a', function(e) {
            e.preventDefault();
            const title = $(this).children('span');
            TenderCategories.toggleText(title);
            $(this).next('.third_list').slideToggle(200);

            if ( $(e.target).is('div.checkbox__checker') ) {
                const checkbox = $(this).find(`[value="${$(this).data('id')}"]`);
                checkbox.closest('.list_item').find('input:first').prop('checked', !checkbox.is(':checked'));
                checkbox.prop('checked', !checkbox.is(':checked'));
                TenderCategories.clearRequired();
            }
        });
        $prodParent.on('click', '.third_list > a', function(event) {
            event.preventDefault();
            const checkbox = $(this).find(`[value="${$(this).data('id')}"]`);
            let hasCheckedBrothers = null;
            if (!checkbox.is(':checked') == false) {
                const inputs = checkbox.closest('.third_list').find('input');
                for (const key in inputs) {
                    if (inputs[key].value !== checkbox.val() && inputs[key].checked) {
                        hasCheckedBrothers = true;
                        break;
                    }
                }
            }
            if (!hasCheckedBrothers) {
                checkbox.closest('.list_item').find('input:first').prop('checked', !checkbox.is(':checked'));
                checkbox.closest('.second_list_item').find('input:first').prop('checked', !checkbox.is(':checked'));
                TenderCategories.clearRequired();
            }
            checkbox.prop('checked', !checkbox.is(':checked'));
        });
    }

    static clearRequired() {
        const requiredCheckboxes = $('.list_item input:checkbox[required]');
        setTimeout(() => {
            if ( requiredCheckboxes.is(':checked') ) {
                requiredCheckboxes.removeAttr('required');
            } else {
                requiredCheckboxes.prop('required', 'required');
            }
        }, 500);
    }

    static toggleText(self) {
        TenderCategories.clearRequired();
        const markdown = self.text().trim();
        return self.text(markdown == '-' ? '+' : (markdown !== '-' && markdown !== '+' ? '' : '-'));
    }
}

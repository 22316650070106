import { Auth } from './Auth';

export class UpdatePhone extends Auth {
    constructor($form, options = {}) {
        super($form, options);
        this.$form = $form;
        this.options = options;
        this.instanceIntPhone = null;
    }
}

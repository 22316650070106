import { NO_PHOTO, COMPANY_DIR } from '@auxiliary/constants';
import { removeURL } from '@auxiliary/customMethods';

export const itemTemplate = data => {
    const image = data.image
        ? `<img src="${window.location.origin}${COMPANY_DIR}${data.image}" alt="logo" title="logo"/>`
        : `<img src="${window.location.origin}${NO_PHOTO}" alt="logo" title="logo"/>`;
    const country = data.country
        ? `<div class="text text--fz-14 text--color-secondary2 mr-10 mt-3">${data.country}</div>`
        : '';
    const linkToSite = data.site
        ? `<a
                href="${data.site}"
                target="_blank"
                rel="noopener noreferrer nofollow noindex"
                class="text text--fz-14 text--color-info input-search__item-link mt-3"
                data-input-search="link"
            >${removeURL(data.site)}</a>`
        : '';

    return `
        <li class="input-search__item" data-input-search="item" data-item-id="${data.id}">
            <div class="input-search__item-img">
                ${image}
            </div>
            <div class="input-search__item-content">
                <div class="text text--fz-16 text--color-secondary3">${data.name}</div>
                <div class="input-search__item-inner-content">
                    ${country}
                    ${linkToSite}
                </div>
            </div>
        </li>
    `;
};

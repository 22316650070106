export default class MqSlider {
    static async init({ $slider, mq }) {
        let swiper = null;
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;

        const checkMq = () => {
            if (mq.matches) {
                $slider.addClass('swiper-container');
                swiper = new Swiper($slider, {
                    spaceBetween: 10,
                    slidesPerView: 'auto',
                    initialSlide: 0,
                    navigation: {
                        nextEl: $($slider).parent().find('.swiper-button-next')[0],
                        prevEl: $($slider).parent().find('.swiper-button-prev')[0],
                    },
                });
                swiper.update();
            } else if (swiper !== null) {
                $slider.removeClass('swiper-container');
                swiper.destroy();
                swiper = null;
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

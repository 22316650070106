export default class MoveEl {
    static init(options) {
        const { el, from, to, methodFrom, methodTo, mq } = options;

        const checkMq = () => {
            if (mq.matches) {
                to[methodTo](el);
            } else {
                from[methodFrom](el);
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

import { rAF, useIntersectionObserver } from '@auxiliary/customMethods';

export class ScrollText {
    constructor($scrollWrap) {
        this.$wrap = $scrollWrap;
        this.$scrollEl = this.$wrap.find('[data-scroll-text]');
        this.unobserve = null;
    }

    init() {
        this.handleScroll();
        this.rAFFn = rAF(this.handleScroll.bind(this));

        const mq = window.matchMedia('(min-width: 1025px)');

        const checkMq = () => {
            if (mq.matches) {
                this.unobserve = useIntersectionObserver(this.$wrap[0], target => {
                    if (target.isIntersecting) {
                        $(window).on('scroll.text', this.rAFFn);
                    } else {
                        $(window).off('scroll.text', this.rAFFn);
                    }
                });
            } else {
                this.unobserve ? this.unobserve() : '';
                $(window).off('scroll.text', this.rAFFn);
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }

    handleScroll() {
        const coordsEl = this.$scrollEl[0].getBoundingClientRect();
        const elTop = coordsEl.top;
        const elW = coordsEl.width;
        const screenW = $(window).outerWidth();
        const screenH = $(window).outerHeight();

        const iteratorElW = ((screenH - elTop) / screenH) * elW;
        const translate = ((screenH - elTop) / screenH) * screenW + iteratorElW;
        this.$scrollEl.css('transform', `translate3d(${-translate}px, 0, 0)`);
    }
}

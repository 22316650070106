export default class CustomSelect {
    static init(wraps, emitter = null) {
        CustomSelect._toggleSelect('customSelectToggle', 'customSelectClose');

        [...wraps].forEach(wrap => {
            const $wrap = $(wrap);
            if ($wrap.is('[data-select-sort]') && emitter) {
                CustomSelect._setValue($wrap, emitter);
            } else {
                CustomSelect._setValue($wrap);
            }
        });
    }

    static initOne(wrap) {
        CustomSelect._setValue(wrap);
    }

    static _toggleSelect(
        toggleNameSpace,
        closeNameSpace,
        delegation = '.js-custom-select-activate .custom-select__input-wrap input',
        search = false // NOTE: Add to markup input for searching
    ) {
        $('body')
            .off(`click.${toggleNameSpace}`)
            .on(`click.${toggleNameSpace}`, `${delegation}`, function () {
                const $this = $(this);
                const wrap = $this.closest('.custom-select');

                if (wrap.hasClass('is-disabled')) return;

                wrap.toggleClass('active');

                CustomSelect.closeSelect(closeNameSpace, wrap, search);
            });
    }

    static closeSelect(closeNameSpace, wrap, search) {
        $('body')
            .off(`click.${closeNameSpace}`)
            .on(`click.${closeNameSpace}`, function (e) {
                const target = e.target;

                if (!$.contains(wrap[0], target)) {
                    wrap.removeClass('active');

                    search && CustomSelect._clearSearch(wrap);
                }
            });
    }

    static _setValue(wrap, emitter = false) {
        const input = wrap.find('.custom-select__input-inner input');
        const optionList = wrap.find('.custom-select__options');
        const selectedOption = optionList?.find('a.selected');
        const inputVal = input.val();

        optionList.off('click').on('click', 'a', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.hasClass('selected')) return;

            optionList.find('a.selected').removeClass('selected');
            $this.addClass('selected');
            input.val($this.text());
            wrap.removeClass('active');
            wrap.addClass('selected');

            if ($this.is('[data-default-option]')) {
                wrap.removeClass('selected');
            }

            if (emitter && typeof emitter === 'object' && typeof emitter.emit === 'function') {
                emitter.emit('custom-select:selected', input.val());
            }
        });

        if (selectedOption.length && inputVal && inputVal !== selectedOption.text()) {
            optionList.find('a').each((_, option) => {
                const $option = $(option);

                if ($option.text() === inputVal) {
                    selectedOption.removeClass('selected');
                    input.val($option.text());
                    $option.addClass('selected');
                }
            });

            return;
        }

        input.val(selectedOption.text());
    }

    static _clearSearch(wrap) {
        const inputSearch = wrap.find('.search-select__search input');

        inputSearch.val() ? inputSearch.val('').trigger('input') : null;
    }

    static destroySelect(toggleNameSpace, closeNameSpace, wrap) {
        $('body').off(`click.${toggleNameSpace}`);
        $('body').off(`click.${closeNameSpace}`);
        wrap.find('.custom-select__options').off('click');
        wrap.removeClass('active');
    }

    static _addZIndex(wrap, i, arr) {
        wrap.style.zIndex = arr.length - i;
    }
}

export const NOT_CORRECT_FIELD = 'Некорректное поле.';
export const REQUIRED_FIELD = 'Это поле необходимо заполнить.';
export const NOT_CORRECT_DATE = 'Не корректная дата.';
export const NOT_CORRECT_PRICE = 'Не корректная цена.';
export const FIELD_WITH_VALUE_ALREADY_EXIST = 'Поле с таким значением уже существует.';
export const REQUIRED_PHOTO = 'Необходимо загрузить фото';
export const PHOTO_SIZE = 'Размер фото не более 5 МБ';
export const URL_MESSAGE = 'Пожалуйста, введите корректный URL.';
export const ADDRESS_ERROR_MESSAGE = 'Выберите адрес с улицей и номером дома из списка.';
export const FEATURE_REQUIRED = 'Необходимо выбрать характеристику.';
export const COMPANY_FORMAT = 'Необходимо выбрать формат работы Вашей компании.';
export const CATEGORY_REQUIRED = 'Необходимо выбрать категорию.';
export const NOT_CORRECT_FIELD_EN = 'Invalid field.';
export const REQUIRED_FIELD_EN = 'This field must be filled in.';
export const NOT_CORRECT_DATE_EN = 'Invalid date.';
export const NOT_CORRECT_PRICE_EN = 'Incorrect price.';
export const FIELD_WITH_VALUE_ALREADY_EXIST_EN = 'A field with this value already exists.';
export const REQUIRED_PHOTO_EN = 'Need to upload a photo';
export const PHOTO_SIZE_EN = 'Photo size no more than 5 MB';
export const URL_MESSAGE_EN = 'Please enter the correct URL.';
export const ADDRESS_ERROR_MESSAGE_EN = 'Select an address with a street and a house number from the list.';
export const FEATURE_REQUIRED_EN = 'A characteristic must be selected.';
export const COMPANY_FORMAT_EN = 'You need to choose the format of your company\'s work.';
export const CATEGORY_REQUIRED_EN = 'A category must be selected.';

import { hideModal, showModal } from './modal.function';

export class Modal {
    constructor($wrapModal, $wrapBtns, options = {}) {
        this.$wrapModal = $wrapModal;
        this.$wrapBtns = $wrapBtns;
        this.target = options.target;
        this.emitter = options.emitter;
        this.emitterName = options.emitterName;
    }

    open() {
        const self = this;
        this.$wrapBtns.off('click.tip').on('click.tip', `${this.target}`, function (e) {
            e.preventDefault();
            const $activeBtn = self.$wrapBtns.find(`${self.target}.active.not-allowed`);
            if ($activeBtn.hasClass('active') && !$('body').hasClass('not-allowed')) {
                $activeBtn.removeClass('active not-allowed');
                return;
            }
            $('body').addClass('not-allowed');
            const $this = $(this);
            $this.addClass('active not-allowed');
            self.emitter.emit('modalInfo:opening', [
                self.$wrapModal,
                $this.data('tip-type'),
                $this.data('tip-name'),
                $this.data('tip-id'),
            ]);
            self.emitter.once('modalInfo:fetch', () => {
                self.$wrapModal.removeClass('op-0 d-block-important');
                showModal(self.$wrapModal, self.emitter);
            });
        });
    }

    close() {
        $('body')
            .removeClass('not-allowed')
            .on('click.hideInfo', e => {
                const target = $(e.target);
                const $btn = target.closest(`${this.target}`);
                const $activeBtn = this.$wrapBtns.find(`${this.target}.active.not-allowed`);
                const link = target.closest('a[href!="#"]');

                if (target.closest('[data-modal-info-close]').length) {
                    e.preventDefault();
                    hideModal(this.$wrapModal, null, this.emitter);
                    $activeBtn.removeClass('active not-allowed');
                } else if (target.closest('[data-modal-info]').length) {
                    if (!link.length) {
                        // NOTE: Links which does not have main parent '[data-modal-info]'
                        return false;
                    }

                    // NOTE: Links which has main parent '[data-modal-info]'
                    return;
                } else if ($btn.length) {
                    hideModal(this.$wrapModal, $btn, this.emitter);
                } else {
                    hideModal(this.$wrapModal, null, this.emitter);
                    $activeBtn.removeClass('active not-allowed');
                }
                $('body').off('click.hideInfo');
            });
    }

    showInfo() {
        showModal(this.$wrapModal, null);
    }

    closeInfo() {
        const self = this;

        self.$wrapModal.on('click.closeInfo', '[data-modal-info-close]', function (e) {
            e.preventDefault();

            const $this = $(this);
            const href = $this.attr('href');

            hideModal(self.$wrapModal, null, self.emitter).then(() => {
                if (href !== '#') {
                    location.href = href;
                }
            });
        });
    }
}

import { useIntersectionObserver } from '@auxiliary/customMethods';

export class AnimateBlocks {
    constructor($els) {
        this.$els = $els;
        this.observes = [];
    }

    init() {
        this.$els.each((i, $el) => {
            this.detectEl($el, i);
        });
    }

    detectEl($el, i) {
        this.observes.push(
            useIntersectionObserver($el, target => {
                if (target.isIntersecting) {
                    $($el).addClass('show');
                    this.observes[i]();
                }
            })
        );
    }
}

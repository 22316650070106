export default class OwnProdVisibility {
    static init(switcher) {
        switcher.off('change').on('change', 'input[name="production"]', function () {
            const $this = $(this);
            const container = $this.closest('[data-toggle-visibility]').next('[data-container-visibility]');
            const inputs = container.find('[data-required-visibility]');

            if ($this.val() === '1') {
                container.stop().slideDown('200', function () {
                    container.removeAttr('style').css('display', 'block');
                });
            } else if ($this.val() === '0') {
                if (inputs.length) {
                    container.stop().slideUp('200', function () {
                        container.removeAttr('style').css('display', 'none');

                        inputs.each((_, input) => {
                            const $input = $(input);

                            if (!$input.is('[type="checkbox"]')) {
                                $input.val('');
                            }

                            $input
                                .removeClass('error')
                                .closest('.form-group')
                                .removeClass('form-group--error')
                                .find('label.error')
                                .remove();
                        });
                    });
                    return;
                }

                container.stop().slideUp('200', function () {
                    container.removeAttr('style').css('display', 'none');
                });
            }
        });
    }
}

import { Auth } from './Auth';

export default class Registration extends Auth {
    constructor($form, options = {}) {
        super($form, options);
        this.$form = $form;
        this.options = options;
        this.instanceIntPhone = null;
    }

    init() {
        this.initIntPhone();
        this._submit();
    }

    _submit() {
        this.$form.find('.form-group__btns').on('click', '.btn', function () {
            const $this = $(this);
            const type = $this.data('name');

            $('#input-type').val(type);
        });

        this.$form.on('submit', function () {
            const $this = $(this);

            if ($this.valid()) {
                $this.find('.form-group__btns .btn').removeClass('btn--secondary').addClass('btn--disable');
            } else {
                return false;
            }
        });
    }
}

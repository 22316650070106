import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';

import { Loader } from '@components/UI/loader/Loader';
import { Emitter } from '@auxiliary/Emitter';
import { utmMarks } from '@components/utmMarks/utmMarks';
import Resize from '@components/Resize';
import { scrollToTop } from '@components/UI/scrollToTop/scrollToTop';
import { Cookie } from '@components/cookie/Cookie';
import { versionInfoModal } from '@components/UI/versionInfoModal/versionInfoModal';
import TogglePassword from '@components/UI/TogglePassword';
import ToggleActivateInput from '@components/UI/ToggleActivateInput';
import { Auth } from '@components/auth/Auth';
import AuthLoading from '@components/auth/AuthLoading';
import Registration from '@components/auth/Registration';
import { UpdatePhone } from '@components/auth/UpdatePhone';
import { ResetPhone } from '@components/auth/ResetPhone';
import DigitInput from '@components/auth/DigitInput';
import GetCode from '@components/auth/GetCode';
import { loadScript, clearSessionStorage, delay } from '@auxiliary/customMethods';
// import { MAP_URL_WITH_AUTOCOMPLETE, YANDEX_MAP_URL, MIN_SCROLL_HEIGHT, PNG, JPEG, JPG } from '@auxiliary/constants';
import {
    MAP_URL_WITH_AUTOCOMPLETE,
    MIN_SCROLL_HEIGHT,
    VK_WIDGETS_POST_URL,
    PNG,
    JPEG,
    JPG,
} from '@auxiliary/constants';

import Validation from '@auxiliary/Validation';
import Masks from '@auxiliary/Masks';
import MoveEl from '@auxiliary/MoveEl';
import CheckedInputs from '@components/UI/CheckedInputs';
import CustomSelect from '@components/UI/customSelect/CustomSelect';
import { SearchSelect } from '@components/UI/customSelect/search/SearchSelect';
import CustomTabs from '@components/UI/CustomTabs';
import CropPhoto from '@components/UI/media/cropPhoto/CropPhoto';
import Popups from '@components/Popups';
import Header from '@components/UI/header/Header';
import HeaderBar from '@components/UI/header/HeaderBar';
import UserMenu from '@components/UI/header/UserMenu';
import FormSteps from '@components/create/FormSteps';
import VisualEditor from '@components/VisualEditor';
import GoogleAutocomplete from '@components/map/GoogleAutocomplete';
import CheckboxData from '@components/UI/CheckboxData';
import CreateCompany from '@components/company/create/CreateCompany';
import EditCompany from '@components/company/edit/EditCompany';
import CreateWarehouse from '@components/warehouse/create/CreateWarehouse';
import EditWarehouse from '@components/warehouse/edit/EditWarehouse';
import { DropDown } from '@components/UI/dropDown/DropDown';
import { Pagination } from '@components/UI/pagination/Pagination';
import { ThumbsGallery } from '@components/UI/thumbsGallery/TumbsGallery';
import { Rating } from '@components/UI/rating/Rating';
import { ProductPage } from '@components/product/productPage/ProductPage';
import { ProductOffers } from '@components/product/productPage/ProductOffers';
import { Tooltip } from '@components/UI/tooltip/Tooltip';
import { ScrollTo } from '@components/UI/ScrollTo';
import { ScrollbarSlider } from '@components/UI/scrollbarSlider/ScrollbarSlider';
import { CompanyPage } from '@components/company/companyPage/CompanyPage';
import { YoutubeVideo } from '@components/UI/youtubeVideo/YoutubeVideo';
import { CatalogMenu } from '@components/UI/header/CatalogMenu';
import CreateCertificate from '@components/certificate/create/CreateCertificate';
import { DeleteById } from '@components/delete/DeleteById';
import { ModalSliderImage } from '@components/UI/modalSlider/ModalSliderImage';
import EditCertificate from '@components/certificate/edit/EditCertificate';
import { Profile } from '@components/profile/Profile';
import { Tip } from '@components/UI/tip/Tip';
import { Accordion } from '@components/UI/accordion/Accordion';
import { UploadItems } from '@components/UI/uploadItems/UploadItems';
import { ShowFileList } from '@components/UI/showFIleList/ShowFileList';
import { ZoomFile } from '@components/UI/zoomFile/ZoomFile';
import { ZoomImages } from '@components/UI/zoomFile/ZoomImages';
import { TenderCategories } from '@components/UI/tender/tenderCategories';
// import { TenderCreateBid } from '@components/UI/tender/tenderCreateBid';
import { Production } from '@components/production/Production';
import { ProductionEdit } from '@components/production/ProductionEdit';
import { ProductionDelete } from '@components/production/ProductionDelete';
import { ToggleElement } from '@components/UI/toggleElement/ToggleElement';
import { Archive } from '@components/archive/Archive';
import { Share } from '@components/share/Share';
import { MainSlider } from '@components/UI/mainSlider/mainSlider';
import { OffsetContainer } from '@components/offsetContainer/OffsetContainer';
import { ProductionFeaturesPopupShow } from '@components/production/ProductionFeaturesPopup';
import { initSticky } from '@components/UI/stickyBlock/stickyBlock';
import stickyBlockWith from '@components/UI/stickyBlock/stickyBlockWith';
import MqSlider from '@components/UI/mqSlider/mqSlider';
import { ScrollText } from '@components/UI/scrollText/ScrollText';
import { TabsDefault } from '@components/UI/tabsDefault/TabsDefault';
import { BtnHide } from '@components/UI/btnHide/BtnHide';
import { AnimateBlocks } from '@components/UI/animateBlocks/AnimateBlocks';
import { CountUpAnimate } from '@components/UI/countUpAnimate/CountUpAnimate';
import { search } from '@components/UI/search/search';
import { Cursor } from '@components/cursor/Cursor';
import { YMEvent } from '@components/metrics/YMEvent/YMEvent';
import ControlWarehouse from '@components/warehouse/control/ControlWarehouse';
import AsideMenuProfile from '@components/UI/asideMenuProfile/AsideMenuProfile';
import YandexAutocomplete from '@components/yandexMap/YandexAutocomplete';
import { loadWidgetsPost } from '@components/vkWidgetsPost/loaderWidgetsPost';
import { DatePicker } from '@components/UI/datepicker/DatePicker';
import { ItcCustomSelect } from '@components/UI/selectAccordion/select';


// Loader
Loader.init(document.getElementById('loader'), document.getElementById('overlay'));

// Emitter
const emitter = new Emitter();

// Utm marks
utmMarks();

// Check successful user registration
delay(300).then(() => YMEvent.successfulRegistration());

// Check Footer
{
    if (!document.querySelector('.footer')) {
        document.querySelector('.wrap-helper__inner').classList.add('wrap-helper__inner--fix-flex');
    }
}


{
    const selectClass = '.select-accordion';
    const select = $(selectClass);

    if (select.length) {
        ItcCustomSelect.create(selectClass, select.data('props'));
    }
}


{
    const datepicker = $('.datepicker');

    if (datepicker.length) {
        (new DatePicker).init();
    }
}
// Add year for copyright
{
    const copyright = document.getElementById('copyright-year');

    if (!!copyright !== !!null) {
        copyright.textContent = new Date().getFullYear();
    }
}

//Mobile menu
{
    const btnMenu = document.getElementById('btn-catalog-menu');

    if (btnMenu) {
        import(/* webpackChunkName: "mobileMenu" */ '@components/nav/mobileMenu').then(
            ({ MobileMenu }) => {
                const mobileMenu = new MobileMenu(btnMenu, { emitter });
                mobileMenu.init();
            }
        );
    }
}

// Scroll to top
{
    const $btn = $('#scroll-to-top');
    const $videoTipBtn = $('#video-tip-btn');
    const mainScrollHeight = $('html, body').prop('scrollHeight');

    if ($videoTipBtn.length) {
        $(window).on('load', async () => {
            $videoTipBtn.addClass('active');
            await delay(2000);
            $videoTipBtn.removeClass('active');
        });
    }

    if ($btn.length && mainScrollHeight >= MIN_SCROLL_HEIGHT) {
        scrollToTop($btn, {
            $scrollableNode: $(window),
            $scrollableTopEl: $('html, body'),
            $dependedBtn: $videoTipBtn,
            dependedBtnClass: 'video-tip-btn--top',
        });
    } else if ($btn.length && mainScrollHeight < MIN_SCROLL_HEIGHT) {
        $btn.remove();
    }
}

// Show version modal
{
    if (typeof Cookie.getCookie('dp_beta_v') === 'undefined') {
        versionInfoModal({
            emitter,
        });
    }
}

// Search
{
    const $target = $('[data-search="target"]');
    const $wrap = $('#search');

    if ($target.length && $wrap.length) {
        search($target, $wrap, { emitter });
    }
}

// Scroll navigation
{
    const $wrap = $('[data-scroll-nav="wrap"]');

    if ($wrap.length) {
        import(/* webpackChunkName: "scroll-nav" */ '@components/UI/scrollNav/scrollNav').then(module => {
            const scrollNav = module.scrollNav;

            scrollNav($wrap);
        });
    }
}

// Share
{
    const $share = $('#share');

    if ($share.length) {
        new Share($share, {}).init();
    }
}

// Clear session storage
clearSessionStorage($('#is-session'), 'creating');

// Create company
{
    const wrap = $('#add-company');
    const steps = $('#form-steps').find('[data-step]');

    if (wrap.length) {
        CreateCompany.init(wrap, wrap.find('[data-form-step]'), steps, { emitter });
    }
}

// Edit company
{
    const wrap = $('#edit-company');
    const btnToggleCompany = $('#btn-toggle-company');

    if (wrap.length) {
        EditCompany.init(wrap, wrap.find('.profile-company__form'), { emitter });
        btnToggleCompany.length &&
            MoveEl.init({
                el: btnToggleCompany,
                from: btnToggleCompany.parent(),
                to: wrap.find('.profile-company__form .form-group.form-group--btn-sb'),
                methodFrom: 'append',
                methodTo: 'append',
                mq: window.matchMedia('(max-width: 1365px)'),
            });
    }
}

// Create warehouse
{
    const wrap = $('#add-warehouse');

    if (wrap.length) {
        CreateWarehouse.init(wrap);
    }
}

// Edit warehouse
{
    const wrap = $('#edit-warehouse');
    const btnRemoveWarehouse = $('#btn-remove-warehouse');

    if (wrap.length) {
        EditWarehouse.init(wrap.find('.fields-data'));
        btnRemoveWarehouse.length &&
            MoveEl.init({
                el: btnRemoveWarehouse,
                from: btnRemoveWarehouse.parent(),
                to: wrap.find('.fields-data .form-group.form-group--btn-sb'),
                methodFrom: 'append',
                methodTo: 'append',
                mq: window.matchMedia('(max-width: 1365px)'),
            });
    }
}

// Delete warehouse
{
    const $warehouseId = $('[data-remove-warehouse]');

    if ($warehouseId.length) {
        DeleteById.init($warehouseId, '/cabinet/user/company/warehouse/delete', '/cabinet/user/company/warehouse/list');
    }
}

// Create product-company
{
    const wrap = $('#add-product-company');
    const steps = $('#form-steps').find('[data-step]');

    if (wrap.length) {
        import(
            /* webpackChunkName: "reate-product-company" */ '@components/product/company/create/CreateProductCompany'
        ).then(module => {
            const CreateProductCompany = module.default;

            CreateProductCompany.init(wrap, steps, emitter);
        });
    }
}

// Edit product-company
{
    const wrap = $('#edit-product');
    const btnRemoveProduct = $('#btn-remove-product');

    if (wrap.length) {
        import(
            /* webpackChunkName: "edit-product-company" */ '@components/product/company/edit/EditProductCompany'
        ).then(module => {
            const editProductCompany = module.EditProductCompany;

            editProductCompany.init(wrap, wrap.find('.profile-company__form'), { emitter });
            btnRemoveProduct.length &&
                MoveEl.init({
                    el: btnRemoveProduct,
                    from: btnRemoveProduct.parent(),
                    to: wrap.find('.fields-data .form-group.form-group--btn-sb'),
                    methodFrom: 'prepend',
                    methodTo: 'append',
                    mq: window.matchMedia('(max-width: 1365px)'),
                });
        });
    }
}

// Create certificate
{
    const wrap = $('#add-certificate');

    if (wrap.length) {
        CreateCertificate.init(wrap, { emitter });
    }
}

// Delete certificate
{
    const $certificateId = $('[data-remove-certificate]');

    if ($certificateId.length) {
        DeleteById.init(
            $certificateId,
            '/cabinet/user/company/certificate/delete',
            '/cabinet/user/company/certificate/list'
        );
    }
}

// Edit certificate
{
    const wrap = $('#edit-certificate');

    if (wrap.length) {
        EditCertificate.init(wrap.find('.fields-data'), { emitter });
    }
}

// Create-product review
{
    const $wrap = $('#create-product-review');
    const $steps = $('#form-steps').find('[data-step]');

    if ($wrap.length && $steps.length) {
        import(
            /* webpackChunkName: "create-product-review" */ '@components/review/product/create/CreateProductReview'
        ).then(module => {
            const createProductReview = module.CreateProductReview;

            createProductReview.init($wrap, $steps, emitter);
        });
    }
}

// Create-company review
{
    const $wrap = $('#create-company-review');
    const $steps = $('#form-steps').find('[data-step]');

    if ($wrap.length && $steps.length) {
        import(
            /* webpackChunkName: "create-company-review" */ '@components/review/company/create/CreateCompanyReview'
        ).then(module => {
            const createCompanyReview = module.CreateCompanyReview;

            createCompanyReview.init($wrap, $steps, emitter);
        });
    }
}

// Edit-company review
{
    const $wrap = $('#edit-company-review');

    if ($wrap.length) {
        import(/* webpackChunkName: "edit-company-review" */ '@components/review/company/edit/EditCompanyReview').then(
            module => {
                const editCompanyReview = module.EditCompanyReview;

                editCompanyReview.init($wrap, { emitter });
            }
        );
    }
}

// Edit-product review
{
    const $wrap = $('#edit-product-review');

    if ($wrap.length) {
        import(/* webpackChunkName: "edit-product-review" */ '@components/review/product/edit/EditProductReview').then(
            module => {
                const editProductReview = module.EditProductReview;

                editProductReview.init($wrap, { emitter });
            }
        );
    }
}

// Delete review
{
    const $reviewData = $('[data-remove-review]');

    if ($reviewData.length) {
        DeleteById.init($reviewData, '/cabinet/user/review/delete');
    }
}

// Catalog menu
{
    const $wrap = $('#catalog-menu');
    const $navigation = $('#navigation');
    const $btn = $('#btn-catalog-menu');

    if ($wrap.length && $navigation.length && $btn.length) {
        const catalogMenu = new CatalogMenu($wrap, $navigation, $btn, { emitter });
        catalogMenu.init();
    }
}

// Toggle night header
{
    const headerCompany = $('#header-company');

    if (headerCompany.length) {
        Header.initHeaderCompany(headerCompany, emitter);
    }
}

// Toggle header bar
{
    const header = $('.header');

    if (header.length) {
        HeaderBar.init(header, emitter);
    }
}

// Toggle user menu
{
    const userInfo = $('#user-info');
    const headerCompany = $('#header-company');

    if (userInfo.length) {
        UserMenu.init(userInfo);
    }

    if (headerCompany.length && userInfo.length) {
        UserMenu.shiftItems(userInfo, window.matchMedia('(max-width: 767px)'));
    } else if (!headerCompany.length && userInfo.length) {
        UserMenu.shiftItems(userInfo, window.matchMedia('(max-width: 991px)'));
    }
}

// Toggle password
{
    const el = $('.toggle-password');

    if (el.length) {
        TogglePassword.toggle(el);
    }
}

// Toggle activate input and init validation
{
    const formInputs = $('.form-inputs');

    if (formInputs.length) {
        ToggleActivateInput.init(formInputs);
        Validation.init();
    }
}

// Auth
{
    const $form = $('#auth');

    if ($form.length) {
        new Auth($form, { hiddenInput: 'phone' }).init();
    }
}

// Auth loading
{
    const el = $('.main-content__auth--animation');

    if (el.length) {
        AuthLoading.init(el);
    }
}

// Registration
{
    const $form = $('#registration');

    if ($form.length) {
        new Registration($form, { hiddenInput: 'phone' }).init();
    }
}

// Update phone
{
    const $form = $('#update-phone');

    if ($form.length) {
        new UpdatePhone($form, { hiddenInput: 'phone' }).init();
    }
}

// Reset phone
{
    const $form = $('#reset-phone');

    if ($form.length) {
        new ResetPhone($form, { hiddenInput: 'phone' }).init();
    }
}

// Input Digit init & Get code
{
    if ($('.input-digit').length) {
        const formDigitCode = document.querySelector('.form-digit-code');

        DigitInput.init(formDigitCode);
        GetCode.init($(formDigitCode));
    }
}

// Init masks
{
    if ($('.is-mask').length) {
        Masks.init();
    }
}

// Toggle checked inputs
{
    const wraps = $('[data-checked-inputs]');

    if (wraps.length) {
        wraps.each((_, wrap) => {
            CheckedInputs.init($(wrap));
        });
    }
}

// Init custom selects
{
    const customSelect = $('.js-custom-select-activate');

    if (customSelect.length) {
        CustomSelect.init(customSelect, emitter);
    }
}

// Init search-select
{
    const wrap = $('.js-search-select');

    if (wrap.length) {
        SearchSelect.init(wrap);
    }
}

// Init custom tabs
{
    const customTabs = $('[data-custom-tab]');
    const customTabsHandle = $('[data-tabs-wrap]');

    if (customTabs.length) {
        CustomTabs.init(customTabs.find('.custom-tabs__container'));
    }

    if (customTabsHandle.length) {
        customTabsHandle.each((_, wrap) => {
            CustomTabs.handleTabs($(wrap));
        });
    }
}

// Init show file
{
    const $wrap = $('[data-list-review]');

    if ($wrap.length) {
        const showFileList = new ShowFileList($wrap, { emitter });
        showFileList.init();
    }
}

// Init popups
{
    const popups = $('.popup');

    if (popups.length) {
        Popups.init(popups, emitter);
    }
}

// Init crop photo
{
    const wrap = $('#photo');

    if (wrap.length) {
        const cropWrap = document.getElementById('crop-wrap');
        const isResizable = cropWrap.dataset.type === 'resizable';

        CropPhoto.init(wrap, cropWrap, {
            uploader: {
                crop: true,
                maxSize: 5000000,
                extensions: [PNG, JPEG, JPG],
                type: isResizable ? 'square' : 'circle',
                resizable: isResizable,
                dataType: isResizable ? 'base64' : 'blob',
            },
            emitter,
        });
    }
}

// Edit profile
{
    const $form = $('.personal-data');

    if ($form.length) {
        const profile = new Profile($form, { emitter });
        profile.init();
    }
}

// Init form-steps
{
    const formSteps = $('#form-steps');
    const isCompany = Boolean($('.main-content__profile-company').length);

    if (formSteps.length) {
        FormSteps.init(formSteps, isCompany);
    }
}

// Init visual editor
{
    const visualEditor = $('.visual-editor');

    if (visualEditor.length) {
        VisualEditor.init(visualEditor);
    }
}

// Init Google Autocomplete
{
    const autocomplete = document.getElementById('google-autocomplete');

    if (!!autocomplete !== !!null) {
        loadScript(MAP_URL_WITH_AUTOCOMPLETE, () => {
            new GoogleAutocomplete(autocomplete, {}).init();
        });
    }
}

// Init Yandex Autocomplete
{
    const autocomplete = document.getElementById('yandex-autocomplete');
    if (!!autocomplete !== !!null) {
        new YandexAutocomplete(autocomplete, {}).init();
    }
}

// Init toggle element
{
    const $wrapList = $('[data-toggle-wrap]');

    if ($wrapList.length) {
        $wrapList.each((_, wrap) => {
            new ToggleElement($(wrap), {}).init();
        });
    }
}

// {
//     const $wrap = $('#production');

//     if ($wrap.length) {
//         const radioTree = $('#radio-tree');
//         const checkedCategoriesInput = $('#checked-categories');
//         const selectSKU = $('.search-select--sku');
//         const selectBrand = $('.search-select--brand');
//         // Recursive init NOTE: dispatch emitter (categories-tree:change)
//         CategoriesTree.init(radioTree, checkedCategoriesInput, null, emitter);

//         // Recursive set after reloading (NOTE: dispatch emitter (categories-tree:set))
//         CategoriesTree.setCategoriesToCheckboxes(radioTree.find('input:radio'), checkedCategoriesInput, emitter);

//         // Search SKU (NOTE: Get callback with ProductOffer)
//         SearchSKU.init(selectSKU, selectBrand, emitter, ProductVariation.init);

//         // Init features tree (NOTE: subscribe emitter (categories-tree:change && categories-tree:set))
//         ProductionFeaturesTree.init($('#features-main'), emitter);
//     }
// }

// Delete production
{
    const $productionId = $('[data-remove-production]');

    if ($productionId.length) {
        DeleteById.init(
            $productionId,
            '/cabinet/user/company/production/delete',
            '/cabinet/user/company/production/list'
        );
    }
}

// Init to get checkbox's data
{
    const wrap = $('[data-checkbox-wrap]');

    if (wrap.length) {
        CheckboxData.init(wrap);
    }
}

// Upload items
{
    const $wrap = $('[data-wrap-upload-items]');

    if ($wrap.length) {
        $wrap.each((_, wrap) => {
            const uploadItems = new UploadItems($(wrap), {});
            uploadItems.init();
        });
    }
}

// Init dropdown
{
    const $els = $('[data-dropdown]');

    if ($els.length) {
        const dropDown = new DropDown({ eventNameOpen: 'dropDownOpen', eventNameHide: 'dropDownHide', emitter });
        dropDown.init();
    }
}

// Edit pagination
{
    const $wrap = $('.pagination-wrap');

    if ($wrap.length) {
        Pagination.init($wrap);
    }
}

// Gallery slider
{
    const $galleries = $('.gallery-top');
    const $thumbs = $('.gallery-thumbs');

    if ($galleries.length && $thumbs.length) {
        $galleries.each((i, gallery) => {
            const thumbsGallery = new ThumbsGallery($(gallery), $thumbs[i]);
            thumbsGallery.init();
        });
    }
}

// Count rating
{
    const $wraps = $('[data-rating]');

    if ($wraps.length) {
        $wraps.each((_, wrap) => {
            const rating = new Rating(wrap);
            rating.init();
        });
    }
}

// Accordion
{
    const $wrap = $('[data-accordion-wrap]');

    if ($wrap.length) {
        const accordion = new Accordion($wrap, { duration: 200 });
        accordion.init();
    }
}

// Product page
{
    const $wrap = $('#product-page');

    if ($wrap.length) {
        const productPage = new ProductPage($wrap, { emitter });
        productPage.init();
    }
}

// Product info
{
    const $hrefs = $('[data-href]');
    if ($hrefs.length) {
        const scrollTo = new ScrollTo($hrefs, '', { emitter });
        scrollTo.init();
    }
}

// Product offers (map)
{
    const $wrap = $('#product-offers');
    const $btn = $('#toggle-map');

    if ($wrap.length) {
        const productOffers = new ProductOffers($wrap, $btn, { emitter });
        productOffers.init();
    }
}

// Tooltip
{
    Tooltip.init($('[data-tooltip-content]'), emitter);
}

// Offset left container
{
    const $container = $('[data-offset-container]');
    if ($container.length) {
        OffsetContainer.init($container, window.matchMedia('(min-width: 1624px)'));
    }
}

// Scrollbar slider
{
    const $wrap = $('[data-scrollbar-slider]');

    if ($wrap.length) {
        const scrollbarSlider = new ScrollbarSlider($wrap, emitter);
        scrollbarSlider.init();
    }
}

// Filters
{
    const $filter = $('[data-filter="filter"]');
    const $btnProductsFilter = $('#product-filter');
    const $btnCompaniesFilter = $('#companies-filter');

    if ($btnProductsFilter.length && $filter.length) {
        import(/* webpackChunkName: "product-filter" */ '@components/filter/productFilter/ProductFilter').then(
            ({ ProductFilter }) => {
                const productFilter = new ProductFilter($btnProductsFilter, $filter, { emitter });

                productFilter.init();
            }
        );
    } else if ($btnCompaniesFilter.length && $filter.length) {
        import(/* webpackChunkName: "company-filter" */ '@components/filter/companyFilter/CompanyFilter').then(
            ({ CompanyFilter }) => {
                const companyFilter = new CompanyFilter($btnCompaniesFilter, $filter, { emitter });

                companyFilter.init();
            }
        );
    }
}

// City
{
    const $city = $('[data-city="city"]');
    const $btnProductsFilter = $('#city-btn');

    if ($city.length) {
        import(/* webpackChunkName: "city" */ '@components/UI/city/city').then(
            ({ City }) => {
                const city = new City($btnProductsFilter, $city, { emitter });

                city.init();
            }
        );
    }
}

// Order
{
    window.addEventListener('load', function () {
        setTimeout(() => {
          const $order = $('[data-order="order"]');
          const $orderBtn = $('.order-btn');
          if ($order.length) {
              import(/* webpackChunkName: "city" */ '@components/UI/order/order').then(
                  ({ Order }) => {
                      const order = new Order($orderBtn, $order, { emitter });
                      order.init();
                  }
              );
          }
        }, 500);
    });
}

// Tender show
{
    const $tender = $('[data-tender="tender"]');
    const $btnTender = $('.tender-btn');

    if ($tender.length) {
        import(/* webpackChunkName: "tender" */ '@components/UI/tender/tender').then(
            ({ Tender }) => {
                const tender = new Tender($btnTender, $tender, { emitter });
                tender.init();
            }
        );
    }
}
// Tender create
{
    const $tender = $('[data-tender="tender-create"]');
    const $btnTender = $('.tender_create__btn');

    if ($tender.length) {
        import(/* webpackChunkName: "tenderCreate" */ '@components/UI/tender/tenderCreate').then(
            ({ TenderCreateForm }) => {
                const tenderCreateForm = new TenderCreateForm($btnTender, $tender, { emitter });
                tenderCreateForm.init();
            }
        );
    }
}

{
    const $prodParent = $('#tenderCategories');

    if ($prodParent.length) {
        TenderCategories.init($prodParent, emitter);
    }
}
// Bid create
{
    const $bid = $('[data-tender="tender-create-bid"]');
    const $btnTender = $('.bid-btn');

    if ($bid.length) {
        import(/* webpackChunkName: "tenderCreateBid" */ '@components/UI/tender/tenderCreateBid').then(
            ({ TenderCreateBid }) => {
                const bid = new TenderCreateBid($btnTender, $bid, { emitter });
                bid.init();
            }
        );
    }
}
{
    const $tender = $('[data-tender="tender-bids"]');

    if ($tender.length) {
        import(/* webpackChunkName: "tenderBids" */ '@components/UI/tender/tenderBids').then(
            ({ TenderBids }) => {
                const tenderBids = new TenderBids();
                tenderBids.init();
            }
        );
    }
}

// Tender edit
{
    const $tender = $('[data-tender="tender-edit"]');
    const $btnTender = $('.tender_edit__btn');

    if ($tender.length) {
        import(/* webpackChunkName: "tenderEdit" */ '@components/UI/tender/tenderEdit').then(
            ({ TenderEdit }) => {
                const tenderEdit = new TenderEdit($btnTender, $tender, { emitter });
                tenderEdit.init();
            }
        );
    }
}


// Company page
{
    const $wrap = $('#company-page');

    if ($wrap.length) {
        const companyPage = new CompanyPage($wrap, { emitter });
        companyPage.init();
    }
}

// Youtube video
{
    const $link = $('#youtube-link');
    const $wrap = $('#company-page-info');

    if ($link.length) {
        const youtubeLink = new YoutubeVideo($link, $wrap, { emitter });
        youtubeLink.init();
    }
}

// Ordinary Youtube video
{
    const $wrap = $('[data-youtube-video="wrap"]');
    const $link = $('[data-youtube-video="link"]');

    if ($wrap.length && $link.length) {
        new YoutubeVideo($link, $wrap, { emitter }).onPlayYoutubeVideo();
    }
}

// Cursor
{
    const $field = $('[data-cursor="field"]');
    const $image = $field.find('> img');
    const $cursor = $('[data-cursor="cursor"]');

    if ($field.length && $image.length && $cursor.length) {
        new Cursor($field, $cursor, $image, { emitter }).init();
    }
}

// Video tip
{
    const $link = $('#video-tip-btn');
    const $popup = $('.popup--video');

    if ($link.length && $popup.length) {
        const youtubeLink = new YoutubeVideo($link, null, { emitter });
        youtubeLink.init();
    }
}

// Tips-info
{
    const $wrap = $('#tip-info');
    const $tablesWrap = $('#tables-wrap-feature-info');

    if ($wrap.length) {
        const featureInfo = new Tip($wrap, $('body'), {
            emitter,
            target: '[data-btn-tip]',
        });

        featureInfo.init();
    }

    if ($wrap.length && $tablesWrap.length) {
        $tablesWrap.find('[data-tip-type="feature"]').each((_, btn) => {
            const $btn = $(btn);
            MoveEl.init({
                el: $btn,
                from: $btn.parent(),
                to: $btn.closest('.product__features-prop').siblings('.product__features-value'),
                methodFrom: 'append',
                methodTo: 'append',
                mq: window.matchMedia('(max-width: 479px)'),
            });
        });
    }
}

//Modal slider
{
    const $blockModal = $('[data-block-in-modal]'),
        $files = $('[data-in-modal]');

    if ($blockModal.length && $files.length) {
        const slider = new ModalSliderImage($blockModal);
        slider.init();
    }
}

//Slider main
{
    const $sliderMain = $('.slider-main');

    if ($sliderMain.length) {
        MainSlider.init($sliderMain);
    }
}

//Mq Slider
{
    const $slider = $('[data-mq-slider]');

    if ($slider.length) {
        MqSlider.init({ $slider, mq: window.matchMedia(`(max-width: ${$slider.data('mq-slider')}px)`) });
    }
}

//Zoom picture
{
    const $zoomFiles = $('[data-zoom]');

    if ($zoomFiles.length) {
        const zoom = new ZoomFile($zoomFiles);
        zoom.init();
    }
}

//Zoom only images
{
    const $wrap = $('[data-has-zoom-img]');

    if ($wrap.length) {
        new ZoomImages($wrap);
    }
}

//Production
{
    const $prodParent = $('#production'),
        $prodEdit = $('[data-production-edit]'),
        $prodBtnsRemove = $('[data-remove-production="can-remove"]'),
        $prodBtnsShowFeatures = $('[data-show-features]');

    if ($prodParent.length) {
        Production.init($prodParent, emitter);
    }

    if ($prodEdit.length) {
        ProductionEdit.init($prodEdit);
    }

    if ($prodBtnsRemove.length) {
        ProductionDelete.init($prodBtnsRemove);
    }

    if ($prodBtnsShowFeatures.length) {
        ProductionFeaturesPopupShow.init($prodBtnsShowFeatures);
    }
}

// Add sticky
{
    const wrap = $('[data-sticky-wrap]');
    const stickyEl = wrap.find('[data-sticky-element]');

    if (wrap.length && stickyEl.length) {
        initSticky({ wrap, stickyEl, mq: false });
        stickyBlockWith.init({
            wrap,
            stickyEl,
            emitter,
            mq: window.matchMedia('(max-width: 1199px)'),
        });
    }
}

//Archive
{
    const $btnsArchive = $('[data-archive]'),
        $btnsPopArchive = $('[data-archive-popup]');

    if ($btnsArchive.length) {
        const archive = new Archive($btnsArchive, $btnsPopArchive);
        archive.init();
    }
}

// Scroll text
{
    const $scrollTextWrap = $('[data-scroll-text-wrap]');

    if ($scrollTextWrap.length) {
        const scrollText = new ScrollText($scrollTextWrap);
        scrollText.init();
    }
}

//Banner title MoveEl
{
    const $wrap = $('[data-banner-move-el]');
    const $el = $wrap.find('.banner-space__title');

    if ($wrap.length) {
        MoveEl.init({
            el: $el,
            from: $el.parent(),
            to: $wrap,
            methodFrom: 'prepend',
            methodTo: 'prepend',
            mq: window.matchMedia('(max-width: 991px)'),
        });
    }
}

// Banner btn hide
{
    const $btnHide = $('[data-btn-hide]');

    if ($btnHide.length) {
        const btnHide = new BtnHide($btnHide);
        btnHide.init();
    }
}

// Tabs default
{
    const $tabsDefault = $('[data-tabs-default]');

    if ($tabsDefault.length) {
        $tabsDefault.each((_, $wrap) => {
            const tabs = new TabsDefault($($wrap));
            tabs.init();
        });
    }
}

// Animate Block
{
    const $els = $('[data-el-animate]');

    if ($els.length) {
        const animateBlocks = new AnimateBlocks($els);
        animateBlocks.init();
    }
}

// Count up animate
{
    const $countEls = $('[data-count-up]');

    if ($countEls.length) {
        const countUpAnimate = new CountUpAnimate($countEls, {
            useEasing: true,
            useGrouping: true,
            separator: ' ',
            decimal: '.',
        });
        countUpAnimate.init();
    }
}

// NOTE: Control Warehouse
{
    const $controlActions = $('[data-control-warehouse]');

    if ($controlActions.length) {
        new ControlWarehouse({ emitter }).init();
    }
}

// NOTE: Aside-menu for Profile
{
    const $asideMenu = document.querySelector('[data-aside-menu]') || null;

    if ($asideMenu) {
        const asideMenuProfile = new AsideMenuProfile($asideMenu);

        const mq = window.matchMedia('(min-width: 992px)');

        const checkMq = () => {
            if (mq.matches) {
                asideMenuProfile.destroy();
                asideMenuProfile.init();
            } else {
                asideMenuProfile.destroy();
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

//NOTE: Comments
{
    const $comments = document.querySelectorAll('[data-comment]');

    if ($comments.length) {
        import(/* webpackChunkName: "comment" */ '@components/UI/comment/Comment').then(module => {
            const Comment = module.default;

            for (let i = 0; i < $comments.length; i++) {
                // new Validation($forms[i], {}).init();
                new Comment($comments[i], {}).init();
            }
        });
    }
}

//NOTE: Likes
{
    const $likes = document.querySelectorAll('[data-likes]');

    if ($likes.length) {
        import(/* webpackChunkName: "likes" */ '@components/UI/likes/Likes').then(module => {
            const Likes = module.default;

            for (let i = 0; i < $likes.length; i++) {
                // new Validation($forms[i], {}).init();
                new Likes($likes[i], {}).init();
            }
        });
    }
}

// NOTE: Load VKWidgets Post
{
    const $postsData = $('[id^="vk_post"]');

    if ($postsData.length) {
        $postsData.each((_, postData) => {
            loadScript(VK_WIDGETS_POST_URL, () => {
                loadWidgetsPost(postData);
            });
        });
    }
}
// Init resize
Resize.init(emitter);

import Create from '../../create/Create';
import { UploadCertificateFile } from '../UploadCertificateFile';
import { setBlobToFormData, getCurrentSubmitBtn } from '@auxiliary/customMethods';
import { PDF, PNG, JPEG, JPG } from '@auxiliary/constants';

const imageData = {};

export default class CreateCertificate extends Create {
    static init(wrap, options) {
        super.checkSessionMainKey('certificate');
        getCurrentSubmitBtn();

        CreateCertificate.sendData({
            form: wrap.find('.fields-data--certificate'),
            step: null,
            url: '/cabinet/user/company/certificate/create',
        });

        // Uploading files
        UploadCertificateFile.init($('[data-upload-file="file"]'), {
            mainFile: 1,
            optionalFile: 0,
            uploader: { maxSize: 5000000, extensions: [PDF, PNG, JPEG, JPG], certificate: true },
            emitter: options.emitter,
        });

        CreateCertificate.changeFileSubscriber(options.emitter, imageData);
    }

    static sendData(options) {
        const { form, url } = options;

        form.off('submit').on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);
            const btnSubmit = $this.find('button[type="submit"][data-current-submit-btn]');

            if ($this.valid()) {
                const formData = new FormData($this[0]);

                $this.addClass('not-allowed');
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        CreateCertificate.correctFormData(formData);
                        CreateCertificate.removeErrors(form);
                    },
                    success() {
                        if (btnSubmit.val() === 'save' && form.is('[data-form-last]')) {
                            CreateCertificate._success();
                            CreateCertificate.checkLastStep(form, 'list');
                        } else if (btnSubmit.val() === 'save_more' && form.is('[data-form-last]')) {
                            CreateCertificate._success();
                        }
                    },
                    error(data) {
                        CreateCertificate.createError({ data, form });
                    },
                });
            }
        });
    }

    static correctFormData(formData) {
        if (!$.isEmptyObject(imageData)) {
            setBlobToFormData(formData, imageData);
        }
    }

    static _redirect(href) {
        window.location.href = href;
    }

    static _success() {
        const popup = $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                sessionStorage.clear();
                document.location.reload();
            }, 300);
        });
    }
}

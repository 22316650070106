import {
    TEMPORARILY_CLOSE,
    CLOSE,
    RESTORE,
    DELETE,
    URL_CHANGE_STATUS,
    URL_GET_WAREHOUSES,
    URL_CLOSE_AND_CHANGE_WAREHOUSE,
    URL_DELETE_AND_CHANGE_WAREHOUSE,
    URL_DELETE_WAREHOUSE,
    STATUS_IS_TEMPORARILY_CLOSE,
    STATUS_IS_OPEN,
    STATUS_IS_CLOSE,
    POPUP_CONTENT,
    POPUP_OPTIONS,
    POPUP_BUTTONS,
    BTN_TEXT_CLOSE,
    BTN_TEXT_DELETE,
    BTN_TEXT_ADD_ADDRESS,
    OPTION_DEFAULT_CLOSE,
    OPTION_DEFAULT_DELETE,
    NO_ADDRESSES,
} from './constants';
import {
    templateClose,
    templateRestore,
    templateDelete,
    templateButtons,
    templateTemporarilyClose,
    templatePopupSelectOptions,
    templatePopupContentClose,
    templatePopupContentDelete,
    templateError,
} from './template';
import { ajax, warn, popupHandlerShow } from '@auxiliary/customMethods';
import { TEXT_ERROR_MESSAGE } from '@auxiliary/constants';
import {
    generateUrlChangeStatus,
    generateUrlGetWarehouses,
    generateUrlChangeWarehouse,
    generateUrlDelete,
    generateOptionsHtml,
} from './functions';

export default class ControlWarehouse {
    constructor() {
        this.popupLast = $('[data-popup-warehouse][data-step="last"]');
        this.popupLastHtml = this.popupLast.find('[data-popup-html]');
        this.popupFirst = $('[data-popup-warehouse][data-step="first"]');
        this.popupFirstHtml = this.popupFirst.find('[data-popup-html]');
        this.popupSelect = this.popupFirst.find('[data-popup-select]');
        this.popupSelectInput = this.popupSelect.find('[data-popup-select-input]');
        this.dropdownContent = null;
        this.data = {};
        this.controlHandler = this.controlHandler.bind(this);
        this.nextControlHandler = this.nextControlHandler.bind(this);
        this.requestHandler = this.requestHandler.bind(this);
        this._setDefaultValueOption = this._setDefaultValueOption.bind(this);
        this._getOptionsHtml = this._getOptionsHtml.bind(this);
        this._validateSelect = this._validateSelect.bind(this);
        this._removeErrorSelect = this._removeErrorSelect.bind(this);
    }

    init() {
        $('body').on('click.controlWarehouse', '[data-control-warehouse]', this.controlHandler);

        $('[data-popup-warehouse]')
            .off('click.FirstControlWarehouse')
            .on('click.FirstControlWarehouse', '[data-action]', this.nextControlHandler);

        this.popupSelect.off('click.Select').on('click.Select', 'a', () => {
            this._removeErrorSelect();
        });
    }

    controlHandler(event) {
        const $target = event.target.closest('[data-control-warehouse]');
        this.dropdownContent = null;
        this.data = {};
        this.popupFirstHtml.html('');
        this.popupLastHtml.html('');
        this._removeErrorSelect();
        this.popupSelect.removeClass('is-disabled');

        if ($target.hasAttribute('data-control-warehouse')) {
            this.data = { ...$target.dataset };
            this.dropdownContent = $target.closest('[data-dropdown-content]');
            const { action, name, products, id } = this.data;

            const isProducts = Number(products);

            switch (action) {
                case TEMPORARILY_CLOSE:
                    this.popupLastHtml.html(templateTemporarilyClose(name));
                    popupHandlerShow(this.popupLast, 'show');
                    break;
                case RESTORE:
                    this.popupLastHtml.html(templateRestore(name));
                    popupHandlerShow(this.popupLast, 'show');
                    break;
                case CLOSE:
                    isProducts === 0
                        ? this.popupLastHtml.html(templateClose(name))
                        : this.requestHandler('GET', generateUrlGetWarehouses(URL_GET_WAREHOUSES, id));
                    isProducts === 0
                        ? popupHandlerShow(this.popupLast, 'show')
                        : this.dropdownContent?.classList.add('not-allowed');
                    break;
                case DELETE:
                    isProducts === 0
                        ? this.popupLastHtml.html(templateDelete(name))
                        : this.requestHandler('GET', generateUrlGetWarehouses(URL_GET_WAREHOUSES, id));
                    isProducts === 0
                        ? popupHandlerShow(this.popupLast, 'show')
                        : this.dropdownContent?.classList.add('not-allowed');
                    break;
                default:
                    break;
            }
        }
    }

    nextControlHandler(event) {
        const $target = event.target.closest('[data-action]');
        const step = $target.closest('[data-popup-warehouse]').getAttribute('data-step');
        const { name, action, products, id } = this.data;

        if ($target && step === 'first') {
            const changeId = this.popupSelect.find('a.selected').attr('data-select-id') || null;
            this.data = { ...this.data, changeId };

            switch (action) {
                case CLOSE:
                    popupHandlerShow(this.popupFirst, 'hidden');
                    this.popupLastHtml.html(templateClose(name));
                    popupHandlerShow(this.popupLast, 'show');
                    break;
                case DELETE:
                    if (this._validateSelect()) {
                        popupHandlerShow(this.popupFirst, 'hidden');
                        this.popupLastHtml.html(templateDelete(name));
                        popupHandlerShow(this.popupLast, 'show');
                    }
                    break;
                default:
                    break;
            }
        } else if ($target && step === 'last') {
            event.preventDefault();

            const changeId = this.data.changeId;

            const isProducts = Number(products);

            this.popupLast.addClass('not-allowed');

            switch (action) {
                case TEMPORARILY_CLOSE:
                    this.requestHandler(
                        'GET',
                        generateUrlChangeStatus(URL_CHANGE_STATUS, id, STATUS_IS_TEMPORARILY_CLOSE)
                    );
                    break;
                case RESTORE:
                    this.requestHandler('GET', generateUrlChangeStatus(URL_CHANGE_STATUS, id, STATUS_IS_OPEN));
                    break;
                case CLOSE:
                    changeId
                        ? this.requestHandler(
                              'GET',
                              generateUrlChangeWarehouse(URL_CLOSE_AND_CHANGE_WAREHOUSE, id, changeId)
                          )
                        : this.requestHandler('GET', generateUrlChangeStatus(URL_CHANGE_STATUS, id, STATUS_IS_CLOSE));
                    break;
                case DELETE:
                    isProducts === 0 && this.requestHandler('GET', generateUrlDelete(URL_DELETE_WAREHOUSE, id));
                    changeId &&
                        this.requestHandler(
                            'GET',
                            generateUrlChangeWarehouse(URL_DELETE_AND_CHANGE_WAREHOUSE, id, changeId)
                        );
                    break;
                default:
                    break;
            }
        }
    }

    async requestHandler(type, url, formData = '') {
        try {
            const { status, data } = await ajax(type, url, formData);

            const warehouses = data?.warehouses || null;

            this.popupLast.removeClass('not-allowed');
            this.dropdownContent?.classList.remove('not-allowed');

            if (status === 'ok' && !warehouses) {
                popupHandlerShow($('[data-popup-warehouse]'), 'hidden');
                this._success();
            } else if (status === 'Not isset another address') {
                popupHandlerShow(this.popupLast, 'hidden');
                this._insertHtmlPopupFirst();
                this.popupSelect.addClass('is-disabled');
                this.popupSelectInput.val(NO_ADDRESSES);
                popupHandlerShow(this.popupFirst, 'show');
            } else if (warehouses.length) {
                const options = this._getOptionsHtml(warehouses);
                popupHandlerShow(this.popupLast, 'hidden');
                this._insertHtmlPopupFirst(options);
                this._setDefaultValueOption();
                popupHandlerShow(this.popupFirst, 'show');
            }
        } catch (error) {
            warn(error);
            const { status, statusText } = error;
            const errorStatusText = status && statusText ? `status: ${status} ${statusText}` : '';
            alert(`${TEXT_ERROR_MESSAGE}\n${errorStatusText}`);
            this.popupLast.removeClass('not-allowed');
            this.dropdownContent?.classList.remove('not-allowed');
        }
    }

    _success() {
        const popup = $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                document.location.reload();
            }, 300);
        });
    }

    _insertHtmlPopupFirst(options = '') {
        const { action, name } = this.data;
        [...this.popupFirstHtml].forEach($el => {
            const itemName = $el.getAttribute('data-popup-html');
            const isStatusClose = action === CLOSE;
            const isStatusDelete = action === DELETE;
            switch (true) {
                case itemName === POPUP_CONTENT && isStatusClose:
                    $el.innerHTML = templatePopupContentClose(name);
                    break;
                case itemName === POPUP_OPTIONS && isStatusClose:
                    $el.innerHTML = templatePopupSelectOptions(options ? OPTION_DEFAULT_CLOSE : NO_ADDRESSES, options);
                    break;
                case itemName === POPUP_BUTTONS && isStatusClose:
                    $el.innerHTML = templateButtons(BTN_TEXT_CLOSE);
                    break;
                case itemName === POPUP_CONTENT && isStatusDelete:
                    $el.innerHTML = templatePopupContentDelete(name);
                    break;
                case itemName === POPUP_OPTIONS && isStatusDelete:
                    $el.innerHTML = templatePopupSelectOptions(options ? OPTION_DEFAULT_DELETE : NO_ADDRESSES, options);
                    break;
                case itemName === POPUP_BUTTONS && isStatusDelete:
                    $el.innerHTML = options
                        ? templateButtons(BTN_TEXT_DELETE)
                        : templateButtons(BTN_TEXT_ADD_ADDRESS, true);
                    break;
                default:
                    break;
            }
        });
    }

    _getOptionsHtml = options => {
        const { action } = this.data;
        const defaultText = action === CLOSE ? OPTION_DEFAULT_CLOSE : OPTION_DEFAULT_DELETE;

        return generateOptionsHtml(defaultText, options);
    };

    _setDefaultValueOption() {
        const { action } = this.data;

        switch (action) {
            case CLOSE:
                this.popupSelectInput.val(OPTION_DEFAULT_CLOSE);
                break;
            case DELETE:
                this.popupSelectInput.val(OPTION_DEFAULT_DELETE);
                break;
            default:
                break;
        }
    }

    _validateSelect() {
        const notValid = [OPTION_DEFAULT_CLOSE, OPTION_DEFAULT_DELETE].includes(this.popupSelectInput.val());
        if (notValid) {
            this._removeErrorSelect();
            const error = templateError();
            this.popupSelectInput.addClass('error');
            $(error).insertAfter(this.popupSelect);
            return false;
        }
        return true;
    }

    _removeErrorSelect() {
        this.popupSelect.siblings('span.error').remove();
        this.popupSelectInput.removeClass('error');
    }
}

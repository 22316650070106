const parseWidgetsPost = str => {
    return (
        str
            .split(';')
            .map(v => v.split('='))
            .reduce((acc, v) => {
                if (v[0]?.trim() && v[1]?.trim()) {
                    acc[v[0]?.trim()] = v[1]?.trim();
                }
                return acc;
            }, {}) || {}
    );
};

export const loadWidgetsPost = $postData => {
    const innerText = $postData.innerText;
    const data = parseWidgetsPost(innerText);
    $postData.innerText = '';
    $postData.classList.add('revert');

    if (Object.keys(data)?.length) {
        const loader = () => {
            const { element_id, owner_id, post_id, hash } = data;
            // eslint-disable-next-line no-undef
            VK.Widgets.Post(element_id, owner_id, post_id, hash);
        };
        loader();
    }
};

import { PNG, JPEG } from '@auxiliary/constants';

export const removeErrors = ($error, $labelError) => {
    if ($error.length) {
        $error.remove();
    } else if ($labelError.length) {
        $labelError.remove();
    }
};

export const hasAlpha = (ctx, canvas) => {
    const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let hasAlphaPixels = false;
    for (let i = 3, n = data.length; i < n; i += 4) {
        if (data[i] < 255) {
            hasAlphaPixels = true;
            break;
        }
    }
    return hasAlphaPixels;
};

export const setWhiteBackground = (ctx, canvas) => {
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data } = imgData;
    for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) {
            data[i] = 255;
            data[i + 1] = 255;
            data[i + 2] = 255;
            data[i + 3] = 255;
        }
    }
    ctx.putImageData(imgData, 0, 0);
};

export const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.src = url;
    });

export const setImageToCanvas = (img, imgW, imgH) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = imgW;
    canvas.height = imgH;
    ctx.drawImage(img, 0, 0, imgW, imgH);

    return {
        canvas,
        ctx,
    };
};

export const createPlaceholderImg = (width = 1000, height = 1000, bg = '#fff') => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;
    ctx.fillStyle = bg;
    ctx.fillRect(0, 0, width, height);

    return canvas.toDataURL('image/jpeg');
};

export const setImageToCanvasAndCheckAlpha = (img, imgW, imgH) => {
    const { canvas, ctx } = setImageToCanvas(img, imgW, imgH);

    return hasAlpha(ctx, canvas);
};

export const generateBlobImage = (img, width, height, quality = 0.8) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width || img.width;
    canvas.height = height || img.height;
    const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
    const x = canvas.width / 2 - (img.width / 2) * scale;
    const y = canvas.height / 2 - (img.height / 2) * scale;
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

    return new Promise(resolve => {
        canvas.toBlob(
            file => {
                resolve(file);
            },
            hasAlpha(ctx, canvas) ? PNG : JPEG,
            quality
        );
    });
};

export const scaleToFill = async (img, quality = 0.8) => {
    const placeholderImag = await createImage(createPlaceholderImg());
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = placeholderImag.width;
    canvas.height = placeholderImag.height;

    const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
    const x = canvas.width / 2 - (img.width / 2) * scale;
    const y = canvas.height / 2 - (img.height / 2) * scale;

    ctx.drawImage(placeholderImag, 0, 0);
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

    return new Promise(resolve => {
        canvas.toBlob(
            file => {
                resolve(file);
            },
            'image/jpeg',
            quality
        );
    });
};

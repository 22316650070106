import { GEvent } from '@components/metrics/gEvent/GEvent';
import { YMEvent } from '@components/metrics/YMEvent/YMEvent';

export class ProductPage {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.emitter = options.emitter;
    }

    init() {
        GEvent.productPageView();
        YMEvent.productPageView();
    }
}

import { warn, ajax, scrollTo } from '@auxiliary/customMethods';
import { popupShow } from './production.function';
import Create from '@components/create/Create';
export class ProductionCreate extends Create {
    static init($form, options) {
        // Show alert before leave page
        ProductionCreate.showAlertBeforeLeavePage();

        $form.off('submit.Prod').on('submit.Prod', async function (e) {
            e.preventDefault();
            const $btnSubmit = $form.find('button[type="submit"]');
            const $errors = $form.find('label.error');
            const $featuresObligationList = $('#features-main').find('.tree__required');

            const errorData = await options.dispatchFeatureObligations($featuresObligationList);
            const hasErrors = Boolean(errorData.length);
            const $groupErrors = $errors.closest('.form-group--error').first();

            //TODO: delete empty errors label categories
            $form.find('#categories').find('label.error:not(#prod_category_parent-error)').addClass('error-d-none');

            if ($(this).valid() && !hasErrors) {
                ProductionCreate._submit($form, $btnSubmit);
            } else {
                if (!hasErrors && $groupErrors.length) {
                    scrollTo($groupErrors);
                } else if (hasErrors) {
                    scrollTo(
                        $('#features-main').find('.group__body > [data-production-parent] > .tree__wrap-error').first()
                    );
                }
            }
        });
    }

    static _submit($form, $btnSubmit) {
        const $data = $($form).find('[data-listen-input]:checked'),
            $features_manufacture = $($data).filter('[data-type="features_manufacture"]'),
            $features_sale = $($data).filter('[data-type="features_sale"]'),
            $categories = $($data).filter('[data-type="categories"]'),
            $category_manufacture = $($form).find('[data-type="category_manufacture"]').is(':checked');
        // $category_sale = $($form).find('[data-type="category_sale"]').is(':checked');

        const dataObj = {
            categories: `;${[...$categories].map(el => $(el).data('id')).join(';')};`,
            category_manufacture: $category_manufacture === true ? 1 : 0,
            category_sale: 1,
            features_manufacture: [...$features_manufacture].map(el => $(el).data('id')),
            features_sale: [...$features_sale].map(el => $(el).data('id')),
        };

        if ($form.is('[data-production-edit]')) {
            dataObj.id = $form.find('input[name="id"]').val();
            dataObj.category = $form.find('input[name="category"]').val();
        }

        $btnSubmit.addClass('not-allowed');

        ProductionCreate._createData(dataObj, $($form).data('action'), $btnSubmit);
    }

    static _createData(dataObj, action, $btnSubmit) {
        return (async () => {
            try {
                const formData = new FormData();
                Object.keys(dataObj).forEach(key => {
                    formData.append(key, dataObj[key]);
                });
                const response = await ajax('POST', `/cabinet/user/company/production/${action}`, formData);

                if (response.status === 'ok') {
                    ProductionCreate._success();
                    ProductionCreate.removeAlertBeforeLeavePage();
                }
            } catch (error) {
                if (error.status === 422) {
                    popupShow('exist-prod');
                    $btnSubmit.removeClass('not-allowed');
                }
                if (error.status === 205) {
                    popupShow('reload-page');
                    $('body')
                        .off('click.reloadPage')
                        .on('click.reloadPage', () => document.location.reload());
                } else {
                    warn(error);
                }
            }
        })();
    }

    static _success() {
        const popup = $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                window.location.href = '/cabinet/user/company/production/list';
            }, 300);
        });
    }
}

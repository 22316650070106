// import * as $ from 'jquery';
import { getScrollbarWidth } from '@auxiliary/customMethods';

export class OffsetContainer {
    static init($el, mq) {
        const checkMq = () => {
            if (mq.matches) {
                if ($el.data('offset-container') === 'left') {
                    $el.css('margin-left', `calc((100vw - (1625px + ${getScrollbarWidth()}px)) / 2)`);
                }
            } else {
                $el.removeAttr('style');
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

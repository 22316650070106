import { regexpYoutubeLink, query } from './youtubeVideo.variables';

export const youtubeParser = url => {
    const match = url.match(regexpYoutubeLink);
    return match && match[2].length == 11 ? match[2] : false;
};

export const setYoutubePoster = id => `https://i.ytimg.com/vi/${id}/mqdefault.jpg`;

export const generateURL = id => `https://www.youtube.com/embed/${id}${query}`;

export const pauseVideo = element => {
    const iframe = element.querySelector('iframe');
    iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo', args: '' }), '*');
};

export const createAndAppendYoutubeVideo = ($wrap, id) => {
    const iframe = document.createElement('iframe');

    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('src', generateURL(id));

    $wrap.append(iframe);
};

import { removeExtraSpaces } from '@auxiliary/customMethods';
import {
    FEATURE_OBLIGATION_ALWAYS,
    FEATURE_OBLIGATION_TO_SELECT,
    GROUP_DIR,
    TYPE_NUMBER,
    TYPE_TEXT,
    TYPE_PLURAL_VALUE,
    TYPE_SINGULAR_VALUE,
} from '@auxiliary/constants';

const renderFeatureInfoBtn = (name, id) => {
    return `
        <a
            href="#"
            class="btn-tip-info vertical-align-middle"
            data-tip-name="${name}"
            data-tip-id="${id}"
            data-tip-type="feature"
            data-btn-tip
        >
            <svg class="icon-tip-info">
                <use xlink:href="#icon-tip-info"></use>
            </svg>
        </a>
    `;
};

const btnInfo = (description, name, id) => `${description ? `${renderFeatureInfoBtn(name, id)}` : ''}`;

const addSelectorBasedRequiredType = (requiredType, selector) => {
    let selectors = '';

    switch (requiredType) {
        case FEATURE_OBLIGATION_ALWAYS:
        case FEATURE_OBLIGATION_TO_SELECT:
            selectors = selector;
            break;
        default:
            selectors;
            break;
    }

    return selectors;
};

export const renderRequiredClass = requiredType => {
    let requiredClass = '';

    switch (requiredType) {
        case FEATURE_OBLIGATION_ALWAYS:
            requiredClass = 'tree__required tree__required--always';
            break;
        case FEATURE_OBLIGATION_TO_SELECT:
            requiredClass = 'tree__required tree__required--to-select';
            break;
        default:
            requiredClass;
            break;
    }

    return requiredClass;
};

export const renderGroup = ({ name, icon }, features) => {
    return removeExtraSpaces(`
        <div class="group">
            <div class="group__header">
                <img
                    src="${GROUP_DIR}${icon}"
                    alt="${name}"
                    title="${name}"
                />
                <span class="group__name">${name}</span>
            </div>
            <div class="group__body custom-scrollbar">
                ${features}
            </div>
        </div>
    `);
};

export const renderWrapHTML = () => {
    return removeExtraSpaces(`
            <div class="features-tree__children" data-feature-children></div>
        `);
};

export const renderParentHTML = ({ id, type, name, description, children_count, required_type }) => {
    const isNumber = type === TYPE_NUMBER;
    const isText = type === TYPE_TEXT;
    const pluralValue = type === TYPE_PLURAL_VALUE;
    const singularValue = type === TYPE_SINGULAR_VALUE;
    const hasChildren = Boolean(children_count);
    const featureTypeField = isNumber || isText ? 'features__flex mt-10' : 'mt-20';
    const endFeature = hasChildren ? '' : 'data-end-feature';
    const featureTypeValues =
        (pluralValue && hasChildren) || (singularValue && hasChildren)
            ? `
                <svg class="tree__icon">
                    <use xlink:href="#icon-close"></use>
                </svg>
            `
            : '';
    const input =
        isNumber || isText
            ? `<input
                    type="text"
                    class="features-tree__input ${isNumber ? 'input-decimal' : ''} no-hover"
                    placeholder="Ввод"
                    name="feature_tree_input_${id}"
                    data-id="${id}"
                    data-feature-filed="${isNumber ? 'number' : 'string'}"
                    readonly
                >`
            : '';
    return removeExtraSpaces(`
        <div
            class="checkbox features-tree__parent ${featureTypeField}" data-feature-parent>
            <label
                class="
                    checkbox__label
                    tree__head
                    ${addSelectorBasedRequiredType(required_type, 'tree__wrap-error')}
                "
                data-feature-item
            >
                <input
                    type="checkbox"
                    name="feature_tree_${id}"
                    data-id="${id}"
                    data-type="${type}"
                    data-listen-input
                    data-required-type="${required_type}"
                    ${endFeature}
                    hidden
                >
                <span class="tree__parent">
                    <span class="checkbox__checker"></span>
                    <span
                        class="
                            checkbox__wrap-text
                            ${renderRequiredClass(required_type)}
                        "
                    >
                        <span class="checkbox__text checkbox__text--wrap vertical-align-middle">${name}</span>
                        ${btnInfo(description, name, id)}
                    </span>
                </span>
                ${featureTypeValues}
            </label>
            ${input}
        </div>
    `);
};

export const renderChildrenHTML = (data, parentType) => {
    const { id, name, type, product_feature_id, description, children_count, required_type, checked } = data;
    const hasChildren = Boolean(children_count);
    const endFeature = hasChildren ? '' : 'data-end-feature';
    const input =
        type === TYPE_NUMBER
            ? `<input
                    type="text"
                    class="features-tree__input input-decimal no-hover"
                    placeholder="Ввод"
                    name="feature_tree_input_${id}"
                    data-id="${id}"
                    data-feature-filed="number"
                    readonly
                >`
            : type === TYPE_TEXT
            ? `<input
                    type="text"
                    class="features-tree__input no-hover"
                    placeholder="Ввод"
                    name="feature_tree_input_${id}"
                    data-id="${id}"
                    data-feature-filed="string"
                    readonly
                >`
            : '';
    if (parentType === TYPE_SINGULAR_VALUE) {
        return removeExtraSpaces(`
            <div class="radio tree__child mt-10" data-feature-parent>
                <input type="radio"
                    id="feature_tree_${id}"
                    name="feature_tree_radio_${product_feature_id}"
                    data-id="${id}"
                    data-type="${type}"
                    data-listen-input
                    data-required-type="${required_type}"
                    ${endFeature}
                    ${checked && 'checked'}
                    hidden
                >
                <label
                    for="feature_tree_${id}"
                    class="
                        radio__label
                        ${addSelectorBasedRequiredType(required_type, 'tree__wrap-error')}
                    "
                    data-feature-item
                >
                    <span class="radio__checker"></span>
                    <span class="radio__wrap-text">
                        <span
                            class="
                                radio__text
                                radio__text--custom
                                vertical-align-middle
                                ${renderRequiredClass(required_type)}
                            "
                        >${name}</span>
                        ${btnInfo(description, name, id)}
                    </span>
                </label>
                ${input}
            </div>
        `);
    } else {
        return removeExtraSpaces(`
            <div class="checkbox tree__child mt-10" data-feature-parent>
                <label
                    class="
                        checkbox__label
                        ${addSelectorBasedRequiredType(required_type, 'tree__wrap-error')}
                    "
                    data-feature-item
                >
                    <input
                        type="checkbox"
                        name="feature_tree_${id}"
                        data-id="${id}"
                        data-type="${type}"
                        data-listen-input
                        data-required-type="${required_type}"
                        ${endFeature}
                        ${checked && 'checked'}
                        hidden
                    >
                    <span class="checkbox__checker"></span>
                    <span class="checkbox__wrap-text">
                        <span
                            class="
                                checkbox__text
                                checkbox__text--custom
                                vertical-align-middle
                                ${renderRequiredClass(required_type)}
                            "
                        >${name}</span>
                        ${btnInfo(description, name, id)}
                    </span>
                </label>
                ${input}
            </div>
        `);
    }
};

export const searchField = ({ id }) => {
    return removeExtraSpaces(`
        <div class="search-field" data-search-field>
            <input
            type="search"
            tabindex="0"
            data-search-features=${id}
            autocorrect="off"
            aria-label="Search"
            placeholder="Поиск" />
        </div>
        `);
};

export const searchEmpty = () => {
    return removeExtraSpaces(`
        <div class="search-empty-text">
            Ничего не найдено
        </div>
        `);
};

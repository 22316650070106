import { geoIpLookup, modifyCountryData, addZIndex } from './intlPhone.functions';

const defaultOptions = {
    initialCountry: 'auto',
    geoIpLookup,
    separateDialCode: true,
    preferredCountries: ['az', 'am', 'by', 'ge', 'kz', 'lv', 'lt', 'md', 'ru', 'tj', 'tm', 'uz', 'ua', 'ee'],
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js',
};

export class IntlPhone {
    constructor($input, options) {
        this.$input = $input;
        this.name = this.$input.name;
        this.id = this.$input.dataset.intlPhone && Number(this.$input.dataset.intlPhone);
        this.$wrap = this.$input.closest('[data-intl-phone-wrap]');
        this.$error = this.$wrap.querySelector('.form-group__tip-error');
        this.iti = null;
        this.options = {
            ...defaultOptions,
            dropdownContainer: $(this.$input).siblings('[data-intl-phone-dropdown]')[0],
            ...options,
        };

        this.options.zIndex && addZIndex(this.$wrap, this.options.zIndex);
    }

    init() {
        import(/* webpackChunkName: "intl-tel-input" */ 'intl-tel-input').then(module => {
            modifyCountryData();
            this.iti = module.default(this.$input, this.options);
            this.$input.addEventListener('countrychange', this._onChangeCountry);
        });
    }

    _onChangeCountry = e => {
        e.target.value = '';
    };

    destroy() {
        if (this.iti) {
            this.iti.destroy();
        }

        this.$input.removeEventListener('countrychange', this._onChangeCountry);
    }

    get number() {
        return this.iti ? this.iti.getNumber() : '';
    }

    get isValidNumber() {
        return this.iti ? this.iti.isValidNumber() : false;
    }

    get inputId() {
        return this.inputId;
    }
}

export class IntlPhones {
    constructor($inputs, options = {}) {
        this.$inputs = $inputs;
        this.options = options;
        this.instancesIntlPhones = [];
    }

    init() {
        for (let i = 0; i < this.$inputs.length; i++) {
            const $input = this.$inputs[i];
            const intlPhone = new IntlPhone($input, this.options);
            const zIndex = typeof this.options.zIndex === 'function' ? this.options.zIndex() : this.$inputs.length - i;

            addZIndex($input.closest('[data-intl-phone-wrap]'), zIndex);
            this.instancesIntlPhones.push(intlPhone);
            intlPhone.init();
        }

        return this.instancesIntlPhones;
    }
}

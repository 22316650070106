import { isHoverableDevice } from '@auxiliary/customMethods';
import { youtubeParser, setYoutubePoster, createAndAppendYoutubeVideo, pauseVideo } from './youtubeVideo.function';

export class YoutubeVideo {
    constructor($link, $wrap, options) {
        this.$link = $link;
        this.$wrap = $wrap;
        this.$poster = $link.find('img');
        this.id = youtubeParser(this.$link[0].href);
        this.emitter = options.emitter;
    }

    init() {
        this._insertYoutubePoster();
    }

    onPlayYoutubeVideo() {
        this.$link.one('click', e => {
            if (!this.id) {
                return;
            }

            e.preventDefault();
            this.$link.remove();
            this.$wrap.addClass('active');
            createAndAppendYoutubeVideo(this.$wrap, this.id);

            if (isHoverableDevice.matches) {
                this.emitter.emit('destroy:cursor');
            }
        });
    }

    _insertYoutubePoster() {
        if (this.id) {
            if (this.$poster.length) {
                this.$poster[0].src = setYoutubePoster(this.id);
            }

            this._loadVideo();
            this._pauseVideo();
        } else {
            this.$wrap && this.$wrap.addClass('company-page__info--without-video');
            this.$link.closest('.company-page__video-poster').remove();
        }
    }

    _loadVideo() {
        this.emitter.once('video:opened', $wrap => {
            createAndAppendYoutubeVideo($wrap, this.id);
        });
    }

    _pauseVideo() {
        this.emitter.on('video:closed', $wrap => {
            pauseVideo($wrap[0]);
        });
    }
}

import { warn } from '@auxiliary/customMethods';
import { YANDEX_MAP_URL } from '@auxiliary/constants';
import ymaps from 'ymaps';
import {
    defaultGeolocation,
    COUNTRY,
    ADMINISTRATIVE_AREA_LEVEL_1,
    LONG_NAME,
    ADMINISTRATIVE_AREA_LEVEL_2,
    ADMINISTRATIVE_AREA_LEVEL_3,
    SUBLOCALITY_LEVEL_1,
    SUBLOCALITY_LEVEL_2,
    SUBLOCALITY_LEVEL_3,
    LOCALITY,
    ROUTE,
    STREET_NUMBER,
    PREMISE,
    ESTABLISHMENT,
    LNG,
    LTD,
} from './map.variables';

export default class YandexAutocomplete {
    constructor(input, options) {
        this.input = input;
        this.autocomplete = null;
        this.inputCountry = document.getElementById(COUNTRY);
        this.inputArea = document.getElementById(ADMINISTRATIVE_AREA_LEVEL_1);
        this.inputCity = document.getElementById(LOCALITY);
        this.inputRoute = document.getElementById(ROUTE);
        this.inputHouse = document.getElementById(STREET_NUMBER);
        this.data = {
            [COUNTRY]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_1]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_2]: LONG_NAME,
            [ADMINISTRATIVE_AREA_LEVEL_3]: LONG_NAME,
            [SUBLOCALITY_LEVEL_1]: LONG_NAME,
            [SUBLOCALITY_LEVEL_2]: LONG_NAME,
            [SUBLOCALITY_LEVEL_3]: LONG_NAME,
            [LOCALITY]: LONG_NAME,
            [ROUTE]: LONG_NAME,
            [STREET_NUMBER]: LONG_NAME,
            [PREMISE]: LONG_NAME,
            [ESTABLISHMENT]: LONG_NAME,
            [LNG]: LNG,
            [LTD]: LTD,
        };
        this.dataKeys = Object.keys(this.data);
        this.myMap = null;
        this.maps = null;
    }

    async init() {
        this.maps = await ymaps.load(YANDEX_MAP_URL);

        if (this.input.value) {
            const ltd = document.getElementById(this.data.ltd).value || null;
            const lng = document.getElementById(this.data.lng).value || null;
            const geolocation = ltd && lng ? [ltd, lng] : defaultGeolocation;

            this._setAutocomplete(this.input, geolocation);
            return;
        }

        this._checkUserLocation().then(userGeolocation => {
            this._setAutocomplete(this.input, userGeolocation);
        });
    }

    _getFullAddressLine(data) {
        const string = Object.keys(this.data).reduce((acc, key) => {
            if (data[key]) {
                acc += acc === '' ? `${data[key]},` : ` ${data[key]},`;
            }
            return acc;
        }, '');

        const lastCommaIndex = string.lastIndexOf(',');
        return string.substring(0, lastCommaIndex);
    }

    _getResults(data) {
        return {
            [COUNTRY]: data?.getCountry(),
            [ADMINISTRATIVE_AREA_LEVEL_1]: data?.getAdministrativeAreas()[0],
            [LOCALITY]: data?.getLocalities()[0],
            [ROUTE]: data?.getThoroughfare() || '',
            [STREET_NUMBER]: data?.getPremiseNumber() || '',
            [PREMISE]: data?.getPremise() || '',
        };
    }

    _getSearchResults(data) {
        return data.reduce((acc, el) => {
            switch (el.kind) {
                case 'country':
                    acc[COUNTRY] = el.name;
                    break;
                case 'province':
                    acc[ADMINISTRATIVE_AREA_LEVEL_1] = el.name;
                    break;
                case 'locality':
                    !acc[LOCALITY] && (acc[LOCALITY] = el.name);
                    break;
                case 'street':
                    acc[ROUTE] = el.name;
                    break;
                case 'house':
                    acc[STREET_NUMBER] = el.name;
                    break;
                default:
                    break;
            }
            return acc;
        }, {});
    }

    _setAutocomplete(input, userGeolocation) {
        const maps = this.maps;
        let myPlacemark = null;
        this.myMap = new maps.Map('yandex-map', {
            center: userGeolocation,
            zoom: 17,
        });

        this.myMap.controls.remove('rulerControl');

        const myGeoObject = new maps.GeoObject({
            geometry: {
                type: 'Point',
                coordinates: userGeolocation,
            },
        });
        myGeoObject.options.set('iconColor', '#161616');
        this.myMap.geoObjects.add(myGeoObject);

        const createPlacemark = coords => {
            return new maps.Placemark(
                coords,
                {
                    iconCaption: 'поиск...',
                },
                {
                    preset: 'islands#icon',
                    iconColor: '#161616',
                    draggable: true,
                    type: 'Point',
                }
            );
        };

        const getAddress = async coords => {
            myPlacemark.properties.set('iconCaption', 'поиск...');
            const res = await maps.geocode(coords);
            const firstGeoObject = res.geoObjects.get(0);
            const result = this._getResults(firstGeoObject);
            this._bindDataToForm({
                ...result,
                [LTD]: firstGeoObject?.geometry?.getCoordinates()[0],
                [LNG]: firstGeoObject?.geometry?.getCoordinates()[1],
            });

            myPlacemark.properties.set({
                iconCaption: [
                    firstGeoObject.getLocalities().length
                        ? firstGeoObject.getLocalities()
                        : firstGeoObject.getAdministrativeAreas(),
                    firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                ]
                    .filter(Boolean)
                    .join(', '),
                balloonContent: firstGeoObject.getAddressLine(),
            });
            this._removeError(input);
            input.value = this._getFullAddressLine(result);
        };

        this.myMap.events.add('click', e => {
            const coords = e.get('coords');

            if (myPlacemark) {
                myPlacemark.geometry.setCoordinates(coords);
            } else {
                this.myMap.geoObjects.remove(myGeoObject);
                myPlacemark = createPlacemark(coords);
                this.myMap.geoObjects.add(myPlacemark);
                myPlacemark.events.add('dragend', () => {
                    getAddress(myPlacemark.geometry.getCoordinates());
                });
            }
            getAddress(coords);
        });

        const searchControl = this.myMap.controls.get('searchControl');
        searchControl.events.add(
            'resultselect',
            async e => {
                const results = searchControl.getResultsArray();

                const selected = e.get('index');
                const point = results[selected].geometry.getCoordinates();

                const myGeocoder = maps.geocode(point);

                myGeocoder.then(res => {
                    res.geoObjects.options.set('iconColor', '#161616');
                    this.myMap.geoObjects.add(res.geoObjects);
                    const data = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                        .Address.Components;

                    const searchResult = { ...this._getSearchResults(data), [LTD]: point[0], [LNG]: point[1] };

                    this._bindDataToForm(searchResult);
                    this._removeError(input);
                    input.value = this._getFullAddressLine(this._getSearchResults(data));
                });
            },
            this
        );
    }

    _checkUserLocation() {
        if (navigator.geolocation) {
            return new Promise(resolve => {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const userGeolocation = [position.coords.latitude, position.coords.longitude];

                        resolve(userGeolocation);
                    },
                    error => {
                        warn(error.message);
                        resolve(defaultGeolocation);
                    }
                );
            });
        } else {
            return new Promise(resolve => {
                resolve(defaultGeolocation);
            });
        }
    }

    _removeError($input) {
        const $parent = $input.closest('.form-group__inner');
        $($parent).siblings('label.error').first()?.remove();
    }

    _bindDataToForm(results) {
        Object.keys(results).forEach(key => {
            const $input = document.getElementById(key);
            $input && ($input.value = results[key]);
        });
    }
}

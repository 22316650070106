export default class AsideMenuProfile {
    constructor($menu) {
        this.$menu = $menu;
        this.$menuToggler = $menu.querySelector('[data-aside-menu-toggler]');
        this.$body = document.body;
        this.handlerToggle = this.handlerToggle.bind(this);
        this.isDiminishMenu = localStorage.getItem('is-diminish-menu');
    }

    init() {
        if (this.isDiminishMenu) {
            this.$body.classList.add('is-diminish-menu');
            this.$menuToggler.classList.add('is-active');
        }

        this.$menuToggler.addEventListener('click', this.handlerToggle);
    }

    handlerToggle(e) {
        const $target = e.target.closest('[data-aside-menu-toggler]');

        if ($target) {
            this.$body.classList.remove('await-action');
            if (this.$body.classList.contains('is-diminish-menu')) {
                this.$body.classList.remove('is-diminish-menu');
                localStorage.removeItem('is-diminish-menu');
            } else {
                this.$body.classList.add('is-diminish-menu');
                localStorage.setItem('is-diminish-menu', true);
            }
            $target.classList.toggle('is-active');
        }
    }

    destroy() {
        this.$menuToggler.removeEventListener('click', this.handlerToggle);
        this.$menuToggler.classList.remove('is-active');
        this.$body.classList.remove('is-diminish-menu');
    }
}

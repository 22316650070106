export const createFileHTML = (name, number, url, fileName, mainFile = false, isDocument = false) => {
    const attrClass = mainFile ? 'class="img-validate image-upload" data-main-file required' : '';
    const file = isDocument
        ? `<label class="file__img file__img--document">
                <img
                    src="${window.location.origin}/images/svg/document.svg"
                    alt="uploaded document"
                    title="uploaded document"
                />
            </label>`
        : `<label class="file__img">
                <img src="${url}" alt="uploaded image" title="uploaded image"/>
            </label>`;

    return `
        <div class="file__wrap" data-ready-file data-created-file data-count="${number}">
            <input type="text" ${attrClass} name="${name}" value="${fileName}" hidden tabindex="-1">
            ${file}
            <a href=# class="file__remove mt-10" data-remove-fields>
                <svg>
                    <use xlink:href="#icon-close"></use>
                </svg>
                <span class="text text--upper text--ff-normsmid">Удалить</span>
            </a>
        </div>
    `;
};

export const createNewHTML = (number, mainName, additionalName, extensions, isDocument = false) => {
    let count = number;

    return (mainFile = false) => {
        count++;
        const attrClass = mainFile ? 'class="img-validate file-upload" required' : 'disabled';
        const name = mainFile ? `${mainName}` : `${additionalName}[${count}]`;
        const dataMain = mainFile ? 'data-main-file' : '';
        const btnText = isDocument ? 'файл' : 'фото';

        return `
            <div class="file__wrap" data-created-file data-count="${count}">
                <input type="text" ${attrClass} name="${name}" hidden tabindex="-1">
                <label class="file__upload">
                    <div class="file__upload-inner">
                        <input
                            type="file"
                            ${dataMain}
                            data-file="${count}"
                            accept="${extensions}"
                            hidden
                            tabindex="-1"
                        />
                        <img
                            src="${window.location.origin}/images/svg/Photo.svg"
                            alt="photo"
                            title="photo"
                        />
                        <span>Загрузить ${btnText}</span>
                    </div>
                </label>
            </div>
        `;
    };
};

import { Rating } from '@components/UI/rating/Rating';
import { Tooltip } from '@components/UI/tooltip/Tooltip';
import { DropDown } from '@components/UI/dropDown/DropDown';
import { warn } from '@auxiliary/customMethods';

let isDropDownInit = false;

export class PaginationJs {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$mainWrap = $wrap.closest('[data-product-id]');
        this.$list = this.$wrap.find('[data-product-list]');
        this.data = options.data;
        this.template = options.template;
        this.emitter = options.emitter;
        this.isMounted = options.isMounted;
        this.paginationjs = null;
    }

    async init() {
        const self = this;

        try {
            const paginationjs = await import(/* webpackChunkName: "paginationjs" */ 'paginationjs');

            this.paginationjs = paginationjs.default;
            this.$wrap.pagination({
                dataSource: self.data,
                pageSize: 5,
                pageRange: 1,
                hideWhenLessThanOnePage: true,
                prevText: '',
                nextText: '',
                callback(data, pagination) {
                    if (self.data.length <= 5) {
                        pagination.el.attr('data-pagination-hidden', '');
                    }
                    const $list = self.$list;
                    $list.html('');
                    data.map(item => $list.append(self.template(item))).join('');
                    $list.find('> li [data-rating]').each((_, wrap) => {
                        const rating = new Rating(wrap);
                        rating.init();
                    });
                    Tooltip.init($list.find('[data-tooltip-content]'), self.emitter);
                    if (!isDropDownInit) {
                        isDropDownInit = true;
                        const dropDown = new DropDown({ eventNameOpen: 'dropDownOpen', eventNameHide: 'dropDownHide' });
                        dropDown.init();
                    }
                    if (self.isMounted) {
                        self.emitter.emit('pagination:didMounted');
                    }
                },
            });
        } catch (error) {
            warn(error.message);
            alert(error.message);
        }
    }

    destroy() {
        if (this.$wrap.pagination && this.paginationjs) {
            this.$wrap.pagination('destroy');
        }
    }
}

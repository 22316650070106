import { Cookie } from '@components/cookie/Cookie';
import { expirationDateInHours } from '@auxiliary/customMethods';

const setToCookieUtmMarks = () => {
    const params = new URLSearchParams(location.search);

    params.forEach((value, key) => {
        if (Cookie.getCookie(key)) {
            return;
        }

        Cookie.setCookie(key, value, {
            expires: expirationDateInHours(168),
            samesite: 'strict',
        });
    });
};

export const utmMarks = () => {
    const hasUtmMarks = /(utm_source=|utm_medium=|utm_campaign=|utm_content=|utm_term=)/g.test(location.search);

    if (!hasUtmMarks) {
        return;
    }

    setToCookieUtmMarks();
};

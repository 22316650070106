export class DatePicker {
    constructor(className = null) {
        this.className = className;
    }

    init() {
        const $datePicker = $('.datepicker');
        $datePicker.each((i, dp) => {
            const params = $(dp).hasClass('tender_list__filter') ? {} : { minDate: new Date() };
            $(dp).datepicker(params);
        });

        $('body').on('click', this.className ? '.datepicker': this.className, this.datepickerOpen);
        this.initDatepicker();
    }

    initDatepicker() {
        $.datepicker.regional['ru'] = {
            closeText: 'Закрыть',
            prevText: '&#x3c;Пред',
            nextText: 'След&#x3e;',
            currentText: 'Сегодня',
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekHeader: 'Нед',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['ru']);
    }

    datepickerOpen(e) {
        if ( !$(e.target).hasClass('datepicker') ) return;

        e.preventDefault();
        const dp = $(this).datepicker(
            $.extend({
                inline: true,
                changeYear: true,
                changeMonth: true,
            },
            $.datepicker.regional['ru']
        ));
        dp.datepicker('show');
    }
}


import { useIntersectionObserver } from '@auxiliary/customMethods';

export class CountUpAnimate {
    constructor($els, options) {
        this.$els = $els;
        this.options = options;
    }

    async init() {
        const module = await import(/* webpackChunkName: "countup" */ 'countup');
        const CountUp = module.default;

        this.$els.each(i => {
            let countUp;

            useIntersectionObserver(this.$els[i], target => {
                if (target.isIntersecting) {
                    const value = $(this.$els[i]).data('count-up');

                    countUp = new CountUp(this.$els[i], 0, value, 0, 5, this.options);
                    countUp.start();
                } else {
                    countUp = null;
                }
            });
        });
    }
}

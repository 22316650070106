import { debounce, setLoader, ajax, warn, delay } from '@auxiliary/customMethods';
import { itemTemplate } from './inputSearch.template';
import { showPopup } from './inputSearch.functions';
import { Loader } from './../loader/Loader';

export class InputSearch {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$search = this.$wrap.find('[data-input-search="search"]');
        this.$list = this.$wrap.find('[data-input-search="list"]');
        this.searchOptions = options.search || {};
        this.popups = options.popups;
        this.formData = new FormData();
        this.data = [];
        this.current = null;
        this.emitter = options.emitter || null;
        this.init();
    }

    init() {
        this.setItem = this.setItem.bind(this);
        this.search = this.search.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.$list.on('click', '[data-input-search="item"]', this.setItem);
        this.$search.on('input', debounce(this.search, 400));
        $('body').on('click.searchInputClose', this.handleClose);

        if (this.hasCompanyPopup) {
            this.emitter.on('searchInputCompany:closed', () => {
                this.current = null;
            });
        }
    }

    open() {
        this.$wrap.addClass('input-search--open');
    }

    close() {
        this.$wrap.removeClass('input-search--open');
    }

    handleClose(e) {
        const target = e.target;

        if (this.hasCompanyPopup) {
            if (
                target.closest('[data-bind-company]') === this.popups.bindCompany[0] ||
                target.closest('[data-complain-company]') === this.popups.complainCompany[0]
            ) {
                return;
            }
        }

        if (!$.contains(this.$wrap[0], target)) {
            this.close();
            delay(200).then(() => {
                this.$list.html('');
            });
        }
    }

    async search(e) {
        try {
            const { value } = e.target;

            if (!value || (!this.searchOptions.type && !this.searchOptions.url)) {
                this.close();
                return;
            }

            this.$list.html(setLoader('li'));

            if (this.searchOptions.dataKeys && this.searchOptions.dataKeys.length) {
                this.searchOptions.dataKeys.forEach(key => {
                    if (key === 'type') {
                        this.formData.set(key, 'all');
                        return;
                    }
                    this.formData.set(key, value);
                });
            }

            const response = await ajax(this.searchOptions.type, this.searchOptions.url, this.formData);
            const data = response.data[this.searchOptions.responseKey];
            this.data = data;

            if (!data.length) {
                this.close();
                return;
            }

            if (!this.isOpen) {
                this.open();
            }

            this.$list.html('');
            data.forEach(item => {
                this.$list.append(itemTemplate(item));
            });
        } catch (error) {
            warn(error);
            this.close();
        }
    }

    setItem(e) {
        const target = $(e.target);

        if (target.closest('a[data-input-search="link"]').length) {
            return;
        }

        e.preventDefault();
        const item = target.closest('[data-input-search="item"]');
        const id = item.data('item-id');
        const currentItem = this.data.find(item => item.id === id);

        this.current = {
            id,
            name: currentItem.name,
            userId: Boolean(currentItem.user_id),
        };

        if (this.hasCompanyPopups) {
            if (!this.current.userId && this.popups.bindCompany.length) {
                showPopup(this.popups.bindCompany, this.current.name, this.current.id);
                this.initShowLoader(this.popups.bindCompany.find('[data-company-url]'));
            } else if (this.current.userId && this.popups.complainCompany.length) {
                showPopup(this.popups.complainCompany, this.current.name);
            }
        }
    }

    initShowLoader(el) {
        el.off('click').on('click', () => {
            Loader.showLoader();
        });
    }

    get currentItem() {
        return this.current;
    }

    get isOpen() {
        return this.$wrap.hasClass('input-search--open');
    }

    get hasCompanyPopups() {
        return this.popups.bindCompany && this.popups.complainCompany;
    }

    get hasCompanyPopup() {
        return this.popups.bindCompany || this.popups.complainCompany;
    }

    destroy() {
        this.$list.off('click');
        this.$search.off('input');
    }
}

export class ToggleElement {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.$btn = null;
        this.$btnWrap = null;
    }

    init() {
        const self = this;
        this.$wrap.on('click', '[data-toggle-button]', function (e) {
            e.preventDefault();
            const $this = $(this);
            self.$btn = $this;
            $this.addClass('not-allowed');
            self.$btnWrap = $this.closest('[data-toggle-button-template]');
            const $elements = self.$wrap.find('[data-toggle-element]');
            self._toggleElement($elements);
        });
    }

    _toggleElement($elements) {
        const $activeEl = $elements.filter('[data-toggle-element="active"]');
        const $hiddenEl = $elements.filter('[data-toggle-element="hidden"]');

        $activeEl.slideUp(200, () => {
            $activeEl
                .attr('data-toggle-element', 'hidden')
                .find('label.error')
                .remove()
                .end()
                .find('[data-toggle-field]')
                .removeAttr('required')
                .removeClass('error');
            $hiddenEl.slideDown(200, () => {
                $hiddenEl.attr('data-toggle-element', 'active').find('[data-toggle-field]').attr('required', true);
                this.$btn.removeClass('not-allowed');
                this.$btnWrap
                    .attr('data-toggle-button-template', 'hidden')
                    .siblings('[data-toggle-button-template="hidden"]')
                    .attr('data-toggle-button-template', 'active');
            });
        });
    }
}

import { hiddenScroll, visibleScroll } from '../auxiliary/customMethods';

export default class Popups {
    static init(popups, emitter) {
        window.addEventListener('load', () => {
            popups.removeAttr('style');
        });

        $('body').on('click.popups', e => Popups._delegation(e, emitter));
    }

    static _delegation(e, emitter) {
        let target = e.target;

        if (target.correspondingUseElement) {
            target = target.correspondingUseElement;
        }

        const el =
            target.closest('.js-popup-btn') || target.closest('.popup__overlay') || target.closest('.js-close-popup');

        if (!el) return;

        e.preventDefault();
        const $el = $(el);
        const photo = $('#photo');
        const photoInput = photo.find('input[type="file"]');
        const isVideoPopup = $el.is('[data-popup="popup--video"]') || $el.closest('.popup--video').length;
        const isFileList = $el.closest('[data-popup="file-list"]').length;
        const isBindCompany = $el.closest('[data-bind-company]').length;
        const isComplainCompany = $el.closest('[data-complain-company]').length;

        if ($el.hasClass('js-close-popup') || $el.hasClass('popup__overlay')) {
            visibleScroll();

            if ($el.closest('.popup--crop') && photo.length && photoInput.val()) {
                photoInput.val('');
            } else if (isVideoPopup) {
                emitter.emit('video:closed', $el.closest('.popup--video').find('.popup__wrap'));
            } else if (isFileList) {
                emitter.emit('fileList:closed');
            } else if (isBindCompany || isComplainCompany) {
                emitter.emit('searchInputCompany:closed');
            }

            $('.js-popup-show').length && $('.js-popup-show').removeClass('js-popup-show');
        } else if ($el.hasClass('js-popup-btn')) {
            hiddenScroll();
            const foundPopup = $(`.${$el.attr('data-popup')}`).addClass('js-popup-show');

            if (isVideoPopup) {
                emitter.emit('video:opened', foundPopup.find('.popup__wrap'));
            }
        }
    }
}

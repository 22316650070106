import Edit from '../../edit/Edit';
import { PDF, PNG, JPEG, JPG } from '@auxiliary/constants';
import { UploadCertificateFile } from '../UploadCertificateFile';

export default class EditCertificate extends Edit {
    static init(form, options) {
        EditCertificate.changeFileSubscriber(options.emitter);
        EditCertificate.sendData({ form, url: '/cabinet/user/company/certificate/edit' });

        // Uploading files
        UploadCertificateFile.init($('[data-upload-file="file"]'), {
            mainFile: 1,
            optionalFile: 0,
            uploader: { maxSize: 5000000, extensions: [PDF, PNG, JPEG, JPG], certificate: true },
            emitter: options.emitter,
        });
    }
}

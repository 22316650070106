import { hiddenScroll, replaceFileName, visibleScroll, isImage, isDocument, fireSome } from '@auxiliary/customMethods';
import {
    scaleToFill,
    generateBlobImage,
    removeErrors,
    createImage,
    setImageToCanvasAndCheckAlpha,
} from './uploader.function';
import { loader } from './uploader.tempalte';

export class Uploader {
    static async init(cropWrap, options) {
        if (!options.uploader.crop || options.uploader.document || options.uploader.certificate) {
            return null;
        }

        try {
            await import(/* webpackChunkName: "exif-js" */ 'exif-js');

            const croppie = await import(/* webpackChunkName: "croppie" */ 'croppie');
            const Croppie = croppie.default;
            const croppedPhoto = new Croppie(cropWrap, {
                viewport: {
                    width: 175,
                    height: 175,
                    type: options.uploader.type || 'square',
                },
                boundary: {
                    width: 250,
                    height: 250,
                },
                enableExif: true,
                enableOrientation: true,
                enableResize: options.uploader.resizable || false,
            });

            return croppedPhoto;
        } catch (error) {
            alert(error.message);
        }
    }

    static async readFile(event, $wrap, croppedPhoto, options) {
        const input = event.target;
        const $error = $wrap.find('.file__error');
        const $labelError = $wrap.find('label.error');
        const isFile = input.files && input.files[0];
        const $fileInner = $wrap.find('.file__upload-inner');

        if (!isFile) {
            Uploader.createError($wrap, input, $error, $labelError, {
                isDocument: options.uploader.document,
                isCertificate: options.uploader.certificate,
            });
            return;
        }

        const fileName = input.files[0].name;
        const size = input.files[0].size;
        const { lookup } = await import(/* webpackChunkName: "mime-types" */ 'mime-types');
        const type = lookup(fileName);
        const isCorrectSize = size <= options.uploader.maxSize;
        const isCorrectExtension = fireSome(options.uploader.extensions, type);

        if (!isCorrectSize || !isCorrectExtension) {
            Uploader.createError($wrap, input, $error, $labelError, {
                isDocument: options.uploader.document,
                isCertificate: options.uploader.certificate,
            });
            return;
        }

        const reader = new FileReader();

        if (options.uploader.crop && isImage(type) && !(options.uploader.document || options.uploader.certificate)) {
            removeErrors($error, $labelError);

            reader.onload = async e => {
                const croppedPhotoInstance = await croppedPhoto;

                croppedPhotoInstance
                    .bind({
                        url: e.target.result,
                    })
                    .then(() => {
                        $('.popup--crop').addClass('js-popup-show');
                        hiddenScroll();
                        Uploader.rotatePhoto(croppedPhotoInstance);
                        input.value = '';
                        Uploader._uploadingFile(type, fileName, options.emitter);
                    });
            };
            reader.readAsDataURL(input.files[0]);
        } else if (
            (isDocument(type) || isImage(type)) &&
            (!options.uploader.crop || options.uploader.document || options.uploader.certificate)
        ) {
            removeErrors($error, $labelError);
            Uploader.showLoader($fileInner);

            if (isImage(type)) {
                reader.onload = async e => {
                    let blob;
                    const img = await createImage(e.target.result);

                    if (options.uploader.isScaleFill) {
                        blob = await scaleToFill(img);
                    } else {
                        blob = await generateBlobImage(img);
                    }

                    Uploader._uploadingFile(type, fileName, options.emitter, blob);
                };
                reader.readAsDataURL(input.files[0]);
            } else {
                Uploader.readDocument({ reader, type, fileName, options, input: input.files[0] });
            }
        } else {
            Uploader.createError($wrap, input, $error, $labelError, {
                isDocument: options.uploader.document,
                isCertificate: options.uploader.certificate,
            });
        }
    }

    static readDocument({ reader, type, fileName, options, input }) {
        reader.onload = e => {
            const blob = new Blob([new Uint8Array(e.target.result)], { type });
            Uploader._uploadingFile(type, fileName, options.emitter, blob);
        };
        reader.readAsArrayBuffer(input);
    }

    static _uploadingFile(type, fileName, emitter, blob = undefined) {
        if (emitter) {
            emitter.emit('file:beforeUploaded', [type, replaceFileName(fileName), blob ? blob : undefined]);
        }
    }

    static uploadFile($wrap, croppedPhoto, cb, options) {
        $('#upload-photo')
            .off('click')
            .on('click', async function (e) {
                e.preventDefault();
                const croppedPhotoInstance = await croppedPhoto;
                const img = croppedPhotoInstance.elements.img;
                const isPngWithAlpha =
                    options.format === 'jpeg' ? setImageToCanvasAndCheckAlpha(img, img.width, img.height) : false;

                croppedPhotoInstance
                    .result({
                        type: options.type || 'blob',
                        size: options.size || 'viewport',
                        circle: options.circle || false,
                        format: isPngWithAlpha ? 'png' : options.format || 'png',
                        quality: options.quality || 0.7,
                    })
                    .then(res => {
                        cb($wrap, res);
                        $('.popup--crop').removeClass('js-popup-show');
                        visibleScroll();
                    });
            });
    }

    static rotatePhoto(croppedPhoto) {
        $('#rotate-photo')
            .off('click')
            .on('click', function (e) {
                e.preventDefault();
                croppedPhoto.rotate(-90);
            });
    }

    static showLoader($el) {
        return $el.length ? $el.replaceWith(loader) : null;
    }

    static createError($wrap, input, $error, $labelError, { isDocument = false, isCertificate = false }) {
        const format = isDocument
            ? 'jpeg, jpg, png, pdf, doc, xls, xlsx, xlsm, xltx, xltm,'
            : isCertificate
            ? 'pdf, png, jpg, jpeg,'
            : 'png, jpg, jpeg,';

        input.value = '';
        removeErrors($error, $labelError);
        $wrap.append(`
            <span class="file__error">
                Формат ${
                    $wrap.hasClass('file--logo') ? 'лого' : $wrap.hasClass('file--image') ? 'фото' : 'файла'
                }: ${format} <br> не больше 5Mb
            </span>
        `);

        let timer = setTimeout(() => {
            $wrap.find('.file__error').slideUp(200, function () {
                $(this).remove();

                clearTimeout(timer);
                timer = null;
            });
        }, 3000);
    }
}

export class Archive {
    constructor($btns, $btnsPopup) {
        this.$btns = $btns;
        this.$btnsPopup = $btnsPopup;
    }

    init() {
        let $popup = null,
            $btnThis = null;
        if (this.$btnsPopup.length) {
            $(this.$btnsPopup).each(function (_, $btn) {
                $($btn)
                    .off('click.ArchivePopup')
                    .on('click.ArchivePopup', function (e) {
                        e.preventDefault();

                        $(`.${$(this).data('popup')}`)
                            .find('[data-archive-confirm]')
                            .attr('data-offer-id', `${$(this).data('offer-id')}`)
                            .attr('data-url', `${$(this).data('url')}`);

                        $(`.${$(this).data('popup')}`)
                            .find('[data-archive-name]')
                            .text(`${$(this).data('name')}`);
                        $popup = $(`.${$(this).data('popup')}`);
                        $btnThis = $(this);
                    });
            });
        }
        $(this.$btns).each(function (_, $btn) {
            $($btn)
                .off('click.Archive')
                .on('click.Archive', function (e) {
                    e.preventDefault();
                    const id = new FormData();
                    id.append('id', $(this).data('offer-id'));

                    const dataObj = {
                        btn: $btnThis,
                        popupArchive: $popup,
                        id: id,
                        url: $(this).data('url'),
                        confirm: $(this).data('archive-confirm') || '',
                    };

                    $(this).addClass('not-allowed');

                    Archive.archiveDataFromDataBase(dataObj);
                });
        });
    }

    static archiveDataFromDataBase(dataObj) {
        const { id, url, confirm, popupArchive, btn } = dataObj;

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            data: id,
            processData: false,
            contentType: false,
            success() {
                if (confirm === 'confirm') {
                    btn.addClass('not-allowed');
                    popupArchive.removeClass('js-popup-show not-allowed');
                }

                Archive._success();
            },
        });
    }

    static _success() {
        const popup = $('.popup.popup--success-edit');

        popup.addClass('js-popup-show not-allowed');
        new Promise(resolve => {
            setTimeout(() => {
                popup.removeClass('js-popup-show not-allowed');
                resolve();
            }, 1000);
        }).then(() => {
            setTimeout(() => {
                sessionStorage.clear();
                document.location.reload();
            }, 300);
        });
    }
}

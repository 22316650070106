import { ProductionFeaturesAjax } from './ProductionFeaturesAjax';
import { ProductionCreate } from './ProductionCreate';
import 'jquery-validation/dist/jquery.validate';
import { prodFeaturesTemplate } from './productionFeatures.template';
import {
    replaceAnimated,
    setNotCheckedChildren,
    openerEl,
    checkFeatureObligations,
    hiddenProduction,
} from './production.function';

let category_id = null;
export class Production {
    static init($prodParent, emitter) {
        const $featuresWrap = $prodParent.find('#features');
        const $formatManufacture = $prodParent.find('[data-type="category_manufacture"]');
        const $categoryBlock = $prodParent.find('[data-category-block]');
        const featureObligations = checkFeatureObligations();
        const categoryValue = $prodParent.find('input[name="category"]');

        if (categoryValue.length) {
            category_id = categoryValue.val();
        }

        ProductionCreate.init($prodParent, { dispatchFeatureObligations: featureObligations.dispatch });

        $($prodParent)
            .off('click.openParent')
            .on('click.openParent', '[data-production-opener]', function (e) {
                Production._listenerOpen(e, $(this), 'click', $featuresWrap);
            });

        $($prodParent)
            .off('change.openParent')
            .on('change.openParent', '[data-listen-input]', function (e) {
                const $this = $(this);

                if ($this.data('listen-input') === 'radio') {
                    Production._listenerRadio(
                        e,
                        $this,
                        $prodParent,
                        $featuresWrap,
                        $formatManufacture,
                        $categoryBlock,
                        emitter
                    );
                    Production._listenerOpen(e, $this, 'radio');
                }
                if ($this.data('listen-input') === 'checkbox') {
                    Production._listenerCheckbox(e, $this, $featuresWrap);
                    Production._listenerOpen(e, $this, 'checkbox', $featuresWrap);

                    if ($this.closest('.tree__wrap-error').length) {
                        const $featuresObligationList = $('#features-main').find('.tree__required');

                        featureObligations.dispatch($featuresObligationList);
                    }
                }
            });
    }

    static _addFeatures(self) {
        const $parent = $(self).closest('[data-production-parent="checkbox"]');
        if (!$parent.length) {
            return;
        }

        const id = $parent.data('id'),
            $wrap = $parent.find('[data-production-children]:first');
        $wrap.children().is('[data-production-children-wrap]')
            ? false
            : ProductionFeaturesAjax.init($wrap, { id: id, category_id: Production.getCategoryId }, 'checkbox');
    }

    static _listenerOpen(e, self, event, $checkerHide = false) {
        e.preventDefault();
        const $parent = $(self).closest('[data-production-parent]');
        const $children = self.closest('[data-production-parent]').find('[data-production-children]:first'),
            $checkboxSibling =
                event === 'checkbox' ? self.closest('[data-checkboxes]').find('[data-listen-input]').not(self) : null;
        if ($checkerHide) {
            $checkerHide.attr('data-features-hidden') === 'prod'
                ? $children.attr('data-children-hidden', 'prod')
                : $children.attr('data-children-hidden', '');
        }

        if ($children.length) {
            switch (event) {
                case 'click':
                    openerEl(self).toggleClass('open');
                    $children.stop().slideToggle(200);
                    if ($parent.data('production-parent') === 'checkbox') {
                        // TODO: add available sub features
                        Production._addFeatures(self);
                    }
                    break;
                case 'radio':
                    openerEl(self).addClass('open');
                    $children.stop().slideDown(200);
                    break;
                case 'checkbox':
                    if (
                        (self.is(':checked') && !self.is('[data-checked="checked"]')) ||
                        (self.not(':checked') &&
                            !self.is('[data-checked="checked"]') &&
                            $checkboxSibling.is(':checked'))
                    ) {
                        openerEl(self).addClass('open');
                        $children.stop().slideDown(200);
                    } else if (!self.is('[data-checked="checked"]')) {
                        openerEl(self).removeClass('open');
                        $children.stop().slideUp(200);
                    }
                    break;
            }
        }
    }

    //NOTE: categories
    static _listenerRadio(e, self, $prod, $wrap, $formatManufacture, $categoryBlock, emitter) {
        const $parentFirst = self.parents('[data-production-parent]').find('[data-listen-input]:first');
        const $parent = self.closest('[data-production-parent]');
        const $lastChild = self.closest('[data-production-parent]').find('[data-production-children]:last');
        const $errors = $prod.find('#categories').find('.radio--error');
        const hasProduction = Boolean($('#production').data('has-production'));

        //TODO: set no checked & no required 'categories:radio'
        $($prod)
            .find('[data-listen-input="radio"]')
            .each((_, el) => {
                if ($(el).not(self).prop('checked') === true) {
                    $(el).prop('checked', false);
                }
                if ($(el).prop('required') === true) {
                    $(el).attr('required', false);
                    Production.removeValidate($(el));
                }
            });

        // TODO: parent category set checked
        $parentFirst.prop('checked', true);

        // TODO: delete category errors
        if ($errors.length) {
            $errors.removeClass('radio--error').find('> .tree__error').stop().slideUp(200);
        }

        // TODO: add validate categories & get empty features
        if ($lastChild.length) {
            const $inputRadioRequired = $parent.find('input:radio').attr('required', true);
            $parent.addClass('radio--error').find('> .tree__error').stop().slideDown(200);
            Production.addValidate($inputRadioRequired);
            Production.getEmptyFeatures($prod);
        } else {
            $wrap.attr('data-features-hidden', '');
            $formatManufacture.closest('label').addClass('not-allowed');
            category_id = $(self).data('id');
            $categoryBlock.addClass('cursor-not-allowed');
            // TODO: ajax request 'add features'
            ProductionFeaturesAjax.init(
                $wrap,
                $(self).data('id'),
                'radio',
                hasProduction,
                $formatManufacture,
                $categoryBlock,
                emitter
            );
        }
    }

    //NOTE: features
    static _listenerCheckbox(e, self, $wrap) {
        const $checkboxSibling = self.closest('[data-checkboxes]').find('[data-listen-input]').not(self);

        // TODO: add available sub features
        if (self.is(':checked') && self.is('[data-checkbox="hasChild"]') && self.data('id') !== 'category_id') {
            Production._addFeatures(self);
            self.attr('data-checkbox', 'no-child');
        }

        // TODO: hide & no checked the format of work (production) features
        if (self.not('[data-checked]').prop('checked') === false && self.data('id') === 'category_id') {
            $wrap.is('[data-features-hidden]') ? $wrap.attr('data-features-hidden', 'prod') : '';
            const $formatProd = $wrap.find('[data-listen-input="checkbox"][data-check-type="prod"]:checked');
            if ($formatProd.length) {
                hiddenProduction($formatProd, self);
            }
        }

        // TODO: add the format of work 'production' features
        if (self.prop('checked') === true && self.data('id') === 'category_id') {
            $wrap.is('[data-features-hidden]') ? $wrap.attr('data-features-hidden', '') : '';
        }

        // TODO: set checked 'production', 'sale' is set checked too
        if (self.is(':checked') && self.data('check-type') === 'prod') {
            $checkboxSibling.prop('checked', true);
        }

        // TODO: set no checked 'sale', 'production' is set no checked too
        if (
            self.not('[data-checked]').not(':checked') &&
            self.data('check-type') === 'sale' &&
            $checkboxSibling.not('[data-checked]').is(':checked')
        ) {
            $checkboxSibling.prop('checked', false);
        }

        const $parents = self.parents('[data-production-parent]').find('[data-checkboxes]:first');
        const $children = self
            .not('[data-checked]')
            .closest('[data-production-parent]')
            .find('[data-production-children]')
            .find('[data-checkboxes]')
            .find('[data-listen-input="checkbox"]:checked');

        // TODO: checked parents features
        $parents.find('[data-listen-input="checkbox"]:not(:checked)').each((_, el) => {
            if (self.is(':checked') && self.data('check-type') === 'prod') {
                $(el).prop('checked', true);
            }

            if (self.is(':checked') && self.data('check-type') === 'sale') {
                if ($(el).data('check-type') === 'sale') {
                    $(el).prop('checked', true);
                }
            }
        });

        // TODO: set no checked available sub features
        if ($children.length) {
            setNotCheckedChildren($children, self);
        }
    }

    static set setCategoryId(id) {
        category_id = id;
    }

    static get getCategoryId() {
        return category_id;
    }

    static getEmptyFeatures($prod) {
        const $features = $prod.find('[data-production-features]');
        if ($features.length) {
            const $empty = $('<div/>', {
                id: 'features-main',
                class: 'production__characteristics-empty',
                html: prodFeaturesTemplate('empty'),
            });
            $empty.css('display', 'none');

            replaceAnimated($features, $empty);
        }
    }

    static addValidate($inputs) {
        $inputs.each((_, input) => {
            $(input).rules('add', {
                messages: {
                    required: '',
                },
            });
        });
    }

    static removeValidate($inputs) {
        $inputs.each((_, input) => {
            $(input).rules('remove');
        });
    }
}

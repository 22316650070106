export class GEvent {
    static search(value) {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'search', {
            search_term: value,
        });
    }

    static companyPageView() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'company_page_view');
    }

    static fromCompanyPageToCompanySite() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'from_company_page_to_company_site');
    }

    static productPageView() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'product_page_view');
    }

    static productOffersView() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'product_offers_view');
    }

    static productOffersMapView() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'product_offers_map_view');
    }

    static fromProductOfferToShop() {
        if (!window.dataLayer) {
            return;
        }

        gtag('event', 'from_product_offer_to_shop');
    }
}

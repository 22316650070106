export class ThumbsGallery {
    constructor($gallery, $thumbs) {
        this.$gallery = $gallery;
        this.$thumbs = $thumbs;
    }

    async init() {
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;

        const galleryThumbs = new Swiper(this.$thumbs, {
            spaceBetween: 6,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                1366: {
                    spaceBetween: 8,
                },
                992: {
                    spaceBetween: 5,
                },
            },
        });

        new Swiper(this.$gallery, {
            spaceBetween: 10,
            navigation: {
                nextEl: '.gallery__button-next',
                prevEl: '.gallery__button-prev',
            },
            pagination: {
                el: '.gallery__pagination',
                type: 'fraction',
            },
            thumbs: {
                swiper: galleryThumbs,
            },
        });
    }
}

import { YMEvent } from '@components/metrics/YMEvent/YMEvent';
import { GEvent } from '@components/metrics/gEvent/GEvent';
import { ajax, hiddenScroll, visibleScroll, debounce, warn, setLoader } from '@auxiliary/customMethods';
import { ERROR_TEXT } from '@auxiliary/constants';

let lastSearchHeight;

const getSearchRes = async value => {
    let res;

    try {
        const { data } = await ajax('GET', `/search?q=${value}`);

        GEvent.search(value);
        YMEvent.search();

        res = data;
    } catch (error) {
        warn(error.statusText);
        res = `<div class="text text--medium text--fz-13 text--color-info text--spc-_7">${ERROR_TEXT}</div>`;
    }

    return res;
};

const adjustSearch = ($wrap, $resultWrap, $headerWrap, windowHeight) => {
    const searchHeight = $resultWrap.outerHeight(true) + $headerWrap.outerHeight(true);
    const hasSearchFixed = $wrap.hasClass('search--fixed');

    if (!hasSearchFixed && searchHeight > windowHeight) {
        lastSearchHeight = searchHeight;
        $wrap.addClass('search--fixed');
    } else if (hasSearchFixed && lastSearchHeight <= windowHeight) {
        lastSearchHeight = undefined;
        $wrap.removeClass('search--fixed');
    }
};

const debouncedSearch = debounce(async ($wrap, $resultWrap, $headerWrap, e) => {
    const value = e.target.value.trim();

    lastSearchHeight = undefined;
    $wrap.removeClass('search--fixed');

    if (!value) {
        $resultWrap.empty();

        return;
    }

    $resultWrap.empty().html(setLoader('div'));

    const res = await getSearchRes(value);

    $resultWrap.empty().html(res);
    adjustSearch($wrap, $resultWrap, $headerWrap, $(window).height());
});

export const toggleSearch = ($target, $wrap) => {
    const $input = $wrap.find('[data-search="input"]');

    $target.off('click').on('click', e => {
        e.preventDefault();
        window.scrollTo({ top: 0 });

        const isActiveSearch = $wrap.hasClass('active');

        if (isActiveSearch) {
            visibleScroll();
            $wrap.removeClass('active');
        } else {
            hiddenScroll();
            $wrap.addClass('active');
            $input[0].focus();
        }
    });
};

export const closeSearch = $wrap => {
    $wrap.off('click').on('click', '[data-search="close"]', () => {
        visibleScroll();
        $wrap.removeClass('active');
    });
};

export const inputSearch = ($wrap, $resultWrap, $headerWrap) => {
    $wrap.find('[data-search="input"]').on('input', debouncedSearch.bind(null, $wrap, $resultWrap, $headerWrap));
};

export const resizeSearch = ($wrap, $resultWrap, $headerWrap, { emitter }) => {
    let searchResultHeight = $resultWrap.outerHeight(true);

    emitter.on('page:resized', ([_, height]) => {
        const visibleResultInner = Boolean($resultWrap.find('[data-search="result-inner"]').length);
        searchResultHeight = $resultWrap.outerHeight(true);

        if (searchResultHeight === 0 || !visibleResultInner) {
            return;
        }

        adjustSearch($wrap, $resultWrap, $headerWrap, height);
    });
};

import { setHeight, moveMenuLinks } from './catalogMenu.function';
import { visibleScroll, hiddenScroll } from '@auxiliary/customMethods';

export class CatalogMenu {
    constructor($wrap, $navigation, $btn, options) {
        this.$wrap = $wrap;
        this.$menuList = this.$wrap.find('#catalog-menu-list');
        this.$menuCategories = this.$wrap.find('#catalog-menu-categories');
        this.$menuBaseCategories = this.$wrap.find('#catalog-menu-categories li');
        this.$menuSubCategories = this.$wrap.find('div#catalog-menu-subcategories');
        this.$menuRightPanel = this.$wrap.find('.header__catalog-menu-inner-right');
        this.$navigation = $navigation;
        this.$btn = $btn;
        this.emitter = options.emitter;
        this.height = document.documentElement.clientHeight;
    }

    init() {
        moveMenuLinks(
            $('[data-menu-link]'),
            this.$navigation,
            $('#catalog-menu-list'),
            window.matchMedia('(max-width: 991px)')
        );
        setHeight(this.$wrap, this.height);
        this._toggleCatalogMenu();
        this._toggleCatalogMenuOnMobile();
        this._mouseoverCatalogMenu();
        this._checkNewHeight();
    }

    _mouseoverCatalogMenu() {
        $(this.$menuSubCategories).addClass('d-none');
        $(this.$menuSubCategories).first().removeClass('d-none');

        $(this.$menuBaseCategories).removeClass('light');
        $(this.$menuBaseCategories).first().addClass('light');

        this.$menuBaseCategories.on('mouseover', e => {
            e.preventDefault();

            $(this.$menuBaseCategories).removeClass('light');
            $(e.currentTarget).addClass('light');

            const id = e.currentTarget.dataset['category'];
            $(this.$menuSubCategories).removeClass('d-none');

            const items = this.$menuSubCategories.filter((i, item) => id !== item.dataset['category']);
            $(items).addClass('d-none');
        });
    }

    _toggleCatalogMenu() {
        this.$btn.on('click', e => {
            e.preventDefault();
            window.scrollTo({ top: 0 });
            this.$btn.hasClass('active') ? visibleScroll() : hiddenScroll();
            this.$btn.toggleClass('active');

            this.$menuRightPanel.toggleClass('active');
        });
    }

    _toggleCatalogMenuOnMobile() {
        this.$wrap.on('click', '[data-toggle-catalog]', e => {
            e.preventDefault();
            const $this = $(e.target).closest('[data-toggle-catalog]');
            if ($this.is('[data-toggle-catalog="open"]')) {
                this.$menuList.fadeOut(200, () => {
                    this.$menuCategories.fadeIn(200);
                });
                return;
            }
            this.$menuCategories.fadeOut(200, () => {
                this.$menuList.fadeIn(200);
            });
        });
    }

    _checkNewHeight() {
        this.emitter.on('page:resized', ([_, curHeight]) => {
            if (curHeight !== this.height) {
                this.height = curHeight;
                setHeight(this.$wrap, this.height);
            }
        });
    }
}

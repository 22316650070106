let editor;
export default class VisualEditor {
    static init(wrap) {
        import(/* webpackChunkName: "jodit" */ 'jodit').then(({ Jodit }) => {
            wrap.each(function () {
                const $this = this;

                editor = new Jodit($this, {
                    language: 'ru',
                    toolbarSticky: false,
                    showCharsCounter: false,
                    showWordsCounter: false,
                    showXPathInStatusbar: false,
                    askBeforePasteHTML: false,
                    askBeforePasteFromWord: false,
                    defaultActionOnPaste: 'insert_clear_html',
                    limitChars: true | 2000,
                    limitHTML: true,
                    height: 200,
                    disabled: $($this).is('[readonly]') ? true : false,
                    placeholder: '',
                    buttons: ['bold', 'underline', 'link', 'ul', 'ol', 'align'],
                    buttonsMD: ['bold', 'underline', 'link', 'ul', 'ol', 'align'],
                    buttonsSM: ['bold', 'underline', 'link', 'ul', 'ol', 'align'],
                    buttonsXS: ['bold', 'underline', 'link', 'ul', 'ol', 'align'],
                    events: {
                        change(editor) {
                            if (editor.length) {
                                $($this).closest('.form-group').find('label.error').remove();
                            }
                        },
                        focus(e) {
                            const wrap = $(e.target).closest('.form-group__textarea');

                            wrap.addClass('form-group__textarea--active');
                        },
                        blur(e) {
                            const target = e.target;

                            if (target.innerHTML) return;

                            const wrap = $(target).closest('.form-group__textarea');

                            wrap.removeClass('form-group__textarea--active');
                        },
                    },
                });
            });
        });
    }

    static setValue(value) {
        editor.value = value;
    }
}

import CompanyContacts from './CompanyContacts';
// import Validation from '@auxiliary/Validation';

export class EmailsCreate extends CompanyContacts {
    static init($wrap) {
        const $createdFields = $wrap.find('[data-created-fields]');
        const HTML = EmailsCreate.createHTML($createdFields.length ? super.findMaxNumber($createdFields) : 0);

        $wrap.on('click.add', '[data-add-fields]', function (e) {
            EmailsCreate.addContact.call(this, e, HTML, $wrap);
        });
        $wrap.on('click.remove', '[data-remove-fields]', EmailsCreate.removeContact);
    }

    static addContact(e, HTML, $wrap) {
        e.preventDefault();

        const $this = $(this);

        $this.before(HTML());

        // NOTE: disable required fields
        // const $inputEmail = $wrap.find('[data-created-fields]').last().find('[data-unique="email"]');

        // if ($inputEmail.length) {
        //     Validation.addUniqueValidation($inputEmail);
        // }
    }

    static removeContact(e) {
        e.preventDefault();

        const $this = $(this);
        const parent = $this.closest('[data-created-fields]');
        // const $inputEmail = parent.find('[data-unique="email"]');
        if ($this.is('[data-email]')) {
            //NOTE: For edit company
            EmailsCreate.deleteDataFromDataBase({
                url: '/cabinet/user/company/email/delete',
                originData: {
                    company_id: $this.data('company-id'),
                    email: $this.data('email'),
                    email_information: $this.data('email-information'),
                    key: $this.data('email-key'),
                },
            });
        }

        // NOTE: disable required fields
        // if ($inputEmail.length) {
        //     Validation.removeValidation($inputEmail);
        // }
        parent.remove();
    }

    static createHTML(number) {
        let count = number;

        return () => {
            const editCount = ++count - 1;

            return `
                <div
                    class="contacts-fields__inner-box contacts-fields__inner-box--created"
                    data-created-fields
                    data-count="${editCount}"
                >
                    <div class="contacts-fields__main-field
                            form-group form-group--input-mt
                            form-group--input watch-input">
                        <span class="form-group__tip">Email</span>
                        <div class="form-group__inner">
                            <input
                                type="email"
                                id="emails.${editCount}.email"
                                name="emails[${editCount}][email]"
                            >
                        </div>
                        <span class="form-group__tip-error"></span>
                    </div>
                    <div class="contacts-fields__descr-field form-group form-group--input watch-input">
                        <span class="form-group__tip">Описание</span>
                        <div class="form-group__inner">
                            <input
                                type="text"
                                id="emails.${editCount}.email_information"
                                name="emails[${editCount}][email_information]"
                            >
                        </div>
                        <span class="form-group__tip-error"></span>
                    </div>
                    <a href="#" class="contacts-fields__remove btn-remove-fields" data-remove-fields>
                        <svg>
                            <use xlink:href="#icon-close"></use>
                        </svg>
                        <span>Удалить</span>
                    </a>
                </div>
            `;
        };
    }
}

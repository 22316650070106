let sliderTabs;
export default class CustomTabs {
    static init(el, mqString = '(max-width: 991px)') {
        //NOTE: Bug with a lot of tabs (container hides tabs)
        // const mq = window.matchMedia(mqString);
        // const checkMq = () => (mq.matches ? CustomTabs._on(el) : CustomTabs._off());

        // checkMq();
        // mq.addListener(checkMq);
        CustomTabs._on(el);
    }

    static async _on(el) {
        const module = await import(/* webpackChunkName: "swiper" */ 'swiper');
        const Swiper = module.default;

        sliderTabs = new Swiper(el, {
            slidesPerView: 'auto',
            resistanceRatio: 0,
            freeMode: true,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.custom-tabs__btn--next',
                prevEl: '.custom-tabs__btn--prev',
            },
            breakpoints: {
                991: {
                    navigation: {
                        nextEl: '.custom-tabs__btn--next',
                        prevEl: '.custom-tabs__btn--prev',
                    },
                },
            },
        });

        const tabs = sliderTabs.slides;

        $.each(tabs, (i, tab) => {
            const activeTab = $(tab).find('.custom-tabs__link').hasClass('custom-tabs__link--active');

            if (activeTab) {
                sliderTabs.slideTo(i);
            }
        });
    }

    static _off() {
        if (sliderTabs) {
            sliderTabs.destroy();
        }
    }

    static handleTabs(wrap) {
        const tabs = wrap.find('[data-tabs]');

        tabs.on('click', '[data-tab]', function (e) {
            e.preventDefault();

            const $this = $(this);
            const prevActiveTabContent = wrap.find('.active[data-tab-content]');
            const activeTab = $this.data('tab');
            const activeTabContent = wrap.find(`[data-tab-content="${activeTab}"]`);

            if (
                activeTabContent.hasClass('active') ||
                tabs.hasClass('not-allowed') ||
                $this.hasClass('custom-tabs__link--active')
            ) {
                return;
            }

            tabs.addClass('not-allowed');
            $this.closest('[data-tabs]').find('.custom-tabs__link--active').removeClass('custom-tabs__link--active');
            $this.removeClass('custom-tabs__link--error').addClass('custom-tabs__link--active');

            prevActiveTabContent.removeClass('active').fadeOut(300, function () {
                activeTabContent.addClass('active').fadeIn(300, () => {
                    tabs.removeClass('not-allowed');
                });
            });
        });
    }
}

import { popupShow } from './production.function';
import { warn, asyncEach, ajax, removeExtraSpaces, isLastIteration, setLoader } from '@auxiliary/customMethods';
import { GROUP_DIR } from '@auxiliary/constants';

export class ProductionFeaturesPopupShow {
    static init($btnShow) {
        $($btnShow)
            .off('click.FeaturesPopupShow')
            .on('click.FeaturesPopupShow', function (e) {
                e.preventDefault();
                const $self = $(this),
                    id = $self.data('id'),
                    $productionList = $self.closest('.production-list');

                $productionList.addClass('not-allowed');

                $(`.${$self.data('popup')}`)
                    .find('[data-category-name]')
                    .text(`${$self.data('name')}`);

                ProductionFeaturesPopupShow.getFeaturesData(id).then(data => {
                    if (!data.length) {
                        $productionList.removeClass('not-allowed');
                        return;
                    }

                    const $featuresList = $(`.${$self.data('popup')}`).find('[data-features-list]');

                    $featuresList.empty().append(setLoader('li'));
                    popupShow('production-features');
                    $productionList.removeClass('not-allowed');

                    const groupLastIndex = data.length - 1;
                    const initParentFeatures = ProductionFeaturesPopupShow._renderFeatures(
                        groupLastIndex,
                        $featuresList
                    );

                    data.forEach((item, i) => {
                        initParentFeatures({
                            features: item.production_features_tree,
                            name: item.name,
                            icon: item.icon,
                            groupIndex: i,
                        });
                    });
                });
            });
    }

    static _renderGroup({ name, icon }, features) {
        return removeExtraSpaces(`
            <li class="group group--first-mt-0">
                <div class="group__header">
                    <img
                        src="${GROUP_DIR}${icon}"
                        alt="${name}"
                        title="${name}"
                    />
                    <span
                        class="
                            group__name
                            group__name--fz-10
                            text
                            text--upper
                            text--medium
                            text--spc-_7
                        ">${name}</span>
                </div>
                <div class="group__body group__body--p-0 custom-scrollbar text text--fz-14">
                    ${features}
                </div>
            </li>
        `);
    }

    static _renderFeatures(groupLastIndex, $wrap) {
        const productionGroupsFeaturesHTML = [];

        return ({ name, icon, features, groupIndex }) => {
            const productionFeaturesHTML = [];

            asyncEach(features, (item, i, list) => {
                productionFeaturesHTML.push(ProductionFeaturesPopupShow._renderFeaturesHTML(item));

                if (isLastIteration(i, list)) {
                    productionGroupsFeaturesHTML.push(
                        ProductionFeaturesPopupShow._renderGroup({ name, icon }, productionFeaturesHTML.join(''))
                    );

                    if (groupIndex === groupLastIndex) {
                        $wrap.empty().append(productionGroupsFeaturesHTML.join(''));
                    }
                }
            });
        };
    }

    static getFeaturesData(id) {
        return (async () => {
            try {
                const formData = new FormData();
                formData.append('id', id);
                const response = await ajax('POST', '/get-production-features', formData);

                if (!response.length) {
                    return [];
                }

                return response;
            } catch (error) {
                warn(error);
            }
        })();
    }

    static _setFormat(format) {
        switch (format) {
            case 'manufacture':
                return `<div class="production-list__format-inner flex-aic">
                            <svg class="production-list__format-icon">
                                <use xlink:href="#icon-check"></use>
                            </svg>
                            <span class="text text--fz-10 text--spc-1 text--upper">Производим</span>
                        </div>`;
            case 'sale':
                return `<div class="production-list__format-inner flex-aic">
                            <svg class="production-list__format-icon">
                                <use xlink:href="#icon-check"></use>
                            </svg>
                            <span class="text text--fz-10 text--spc-1 text--upper">Продаем</span>
                        </div>`;
        }
    }

    static _renderFeaturesHTML(feature) {
        const manufacture = feature.manufacture ? ProductionFeaturesPopupShow._setFormat('manufacture') : '',
            sale = feature.sale ? ProductionFeaturesPopupShow._setFormat('sale') : '',
            hasChildren = feature.children,
            hasPlusOpen = hasChildren
                ? `<span class="production-list__inner crs-pointer" data-accordion-target="plus-open">
                        <span class="production-list__text">${feature.feature.name}</span>
                        <span class="icon-plus-open open"></span>
                    </span>`
                : `<span class="production-list__inner">
                        <span class="production-list__text">${feature.feature.name}</span>
                </span>`;

        const accordion = {
            wrap: hasChildren ? 'data-accordion-wrap' : '',
            list: hasChildren ? 'data-accordion-list' : '',
        };

        const children = hasChildren
            ? `<div class="features-list__children" ${
                  accordion.list
              } style="display: block;">${ProductionFeaturesPopupShow.renderChildrenHTML(hasChildren)}
            </div>`
            : '';

        return removeExtraSpaces(`
            <div ${accordion.wrap}>
                <div class="features-list__item production-list">
                    <div class="production-list__item">
                        ${hasPlusOpen}
                    </div>
                    <div class="production-list__format production-list__cell-mq-sm production-list__format--md">
                        <div class="production-list__format-item mr-30">
                            ${manufacture}
                        </div>
                        <div class="production-list__format-item">
                            ${sale}
                        </div>
                    </div>
                </div>
                ${children}
            </div>
        `);
    }

    static renderChildrenHTML(data) {
        let html = '';
        asyncEach(data, feature => {
            const manufacture = feature.manufacture ? ProductionFeaturesPopupShow._setFormat('manufacture') : '',
                sale = feature.sale ? ProductionFeaturesPopupShow._setFormat('sale') : '',
                hasChildren = feature.children,
                hasPlusOpen = hasChildren
                    ? `<span class="production-list__inner crs-pointer" data-accordion-target="plus-open">
                        <span class="production-list__text">${feature.feature.name}</span>
                        <span class="icon-plus-open open"></span>
                    </span>`
                    : `<span class="production-list__inner">
                        <span class="production-list__text">${feature.feature.name}</span>
                    </span>`;

            const accordion = {
                wrap: hasChildren ? 'data-accordion-wrap' : '',
                list: hasChildren ? 'data-accordion-list' : '',
            };

            const children = hasChildren
                ? `<div class="features-list__sub-children" ${
                      accordion.list
                  } style="display: block;">${ProductionFeaturesPopupShow.renderChildrenHTML(hasChildren)}
            </div>`
                : '';
            html += `<div ${accordion.wrap}>
                        <div class="features-list__item">
                            <div class="production-list features-list__child w-100per">
                                <div class="production-list__item">
                                    ${hasPlusOpen}
                                </div>
                                <div class="production-list__format
                                            production-list__cell-mq-sm
                                            production-list__format--md">
                                    <div class="production-list__format-item mr-30">
                                        ${manufacture}
                                    </div>
                                    <div class="production-list__format-item">
                                        ${sale}
                                    </div>
                                </div>
                            </div>
                        </div>
                        ${children}
                    </div>
                `;
        });

        return html;
    }
}

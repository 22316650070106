export const renderStartLogoTemplate = () => {
    return `
        <label class="file__upload">
        <div class="file__upload-inner">
            <img src="${window.location.origin}/images/svg/Photo.svg" alt="photo" title="photo" />
            <input type="file" hidden tabindex="-1"/>
            <span>Загрузить лого</span>
        </div>
        </label>
        <input
            type="text"
            name="image"
            class="img-validate input-hidden"
            data-logo-input
            required
            tabindex="-1"
        >
    `;
};

export const renderDefaultLogoTemplate = () => {
    return `
            <div class="file__img d-flex flex-c">
                <img
                    class="maxh-80" src="${window.location.origin}/images/svg/no_photo.svg"
                    alt="no photo"
                    tittle="no photo"
                />
            </div>
        `;
};

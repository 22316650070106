import { Modal } from '@components/UI/modal/Modal';
import { checkClientRect, delay, warn, ajax } from '@auxiliary/customMethods';
import { FEATURE_URL, TIP_URL } from './tip.variables';

export class Tip extends Modal {
    constructor($wrapModal, $wrapBtns, options) {
        super($wrapModal, $wrapBtns, options);
        this.$title = $wrapModal.find('[data-modal-info-title]');
        this.$content = $wrapModal.find('[data-modal-info-description]');
        this.$head = this.$title.parent();
        this.$body = this.$content.parent();
        this.target = options.target;
    }

    init() {
        this.open();
        this._setContent();
        this.emitter.on('page:resized', () => {
            const isActiveBtn = this.$wrapBtns.find(`.active${this.target}`).length;
            if (isActiveBtn && checkClientRect(this.$wrapModal[0]).top <= 0) {
                this.$wrapModal.addClass('h-100per');
            } else if (isActiveBtn && checkClientRect(this.$wrapModal[0]).top > 0) {
                this.$wrapModal.removeClass('h-100per');
            }
        });
    }

    _setContent() {
        this.emitter.on('modalInfo:opening', ([_, type, name, id]) => {
            switch (type) {
                case 'feature':
                    return this._getTipDescription(this.$title, this.$content, FEATURE_URL, name, id);
                case 'tip':
                    return this._getTipDescription(this.$title, this.$content, TIP_URL, name, id);
                default:
                    return null;
            }
        });
        this.emitter.on('modalInfo:shown', () => {
            this.close();
        });
        this.emitter.on('modalInfo:hidden', $btn => {
            if (!$btn) return;
            $btn.trigger('click');
        });
    }

    async _getTipDescription($title, $content, url, name, id) {
        const formData = new FormData();

        try {
            formData.set('id', id);
            const response = await ajax('POST', url, formData);

            if (response.status === 'ok') {
                $title.text(name ? name : response.data.name);
                $content.html(response.data.description);
                this._initShowModal($content.find('img'));
            }
        } catch (error) {
            alert(`Something wrong!\n${error.message}`);
            warn(error.message);
            $('body').removeClass('not-allowed');
            $(`${this.target}.active.not-allowed`).removeClass('active not-allowed');
        }
    }

    _initShowModal($images) {
        const fireModal = () => {
            this.$wrapModal.addClass('op-0 d-block-important');
            if (checkClientRect(this.$wrapModal[0]).top <= 0) {
                this.$wrapModal.addClass('h-100per');
            } else {
                this.$wrapModal.removeClass('h-100per');
            }
            delay(200).then(() => {
                this.emitter.emit('modalInfo:fetch');
            });
        };
        let countImages = $images.length;
        if (countImages) {
            $images.on('load error', () => {
                countImages--;
                if (!countImages) {
                    fireModal();
                }
            });
        } else {
            fireModal();
        }
    }
}

import { PNG, JPEG, JPG, PDF, DOC, DOCX, XLS, XLSX, XLSM, XLTX, XLTM } from '@auxiliary/constants';
import axios from 'axios';

// eslint-disable-next-line no-console
export const warn = console.warn;

export const asyncEach = (array, cb, interval = 10) => {
    let time = Date.now();
    let i = 0;
    const last = array.length - 1;
    const next = () => {
        while (i <= last) {
            const now = Date.now();
            const diff = now - time;
            if (diff > interval) {
                time = now;
                setTimeout(next, 0);
                break;
            }
            cb(array[i], i++, array);
        }
    };
    next();
};

export const debounce = (cb, interval = 400) => {
    let debounceTimeoutId;
    return function (...args) {
        clearTimeout(debounceTimeoutId);
        debounceTimeoutId = null;
        debounceTimeoutId = setTimeout(() => cb.apply(this, args), interval);
    };
};

export const throttle = (cb, delay) => {
    let lastCall = 0;

    return (...args) => {
        const now = new Date().getTime();

        if (now - lastCall < delay) return;

        lastCall = now;
        return cb(...args);
    };
};

export const rAF = cb => {
    let globalID;
    let ticking = false;

    return function (...args) {
        if (!ticking) {
            cancelAnimationFrame(globalID);
            globalID = null;
            globalID = requestAnimationFrame(() => {
                ticking = false;
                return cb(...args);
            });
            ticking = true;
        }
    };
};

export const isHoverableDevice = window.matchMedia('(hover: hover) and (pointer: fine)');

export const useIntersectionObserver = (childNode, cb, options = {}) => {
    const observer = new IntersectionObserver(([target], observerOptions) => {
        cb(target, observerOptions);
    }, options);

    observer.observe(childNode);

    return () => {
        observer.unobserve(childNode);
    };
};

export const hiddenScroll = (className = 'no-scroll') => {
    if (document.body.scrollHeight > document.body.clientHeight) {
        const scrollTop = document.documentElement.scrollTop
            ? document.documentElement.scrollTop
            : document.body.scrollTop;
        document.documentElement.classList.add(className);
        document.documentElement.style.top = `${-scrollTop}px`;
    }
};

export const visibleScroll = (className = 'no-scroll') => {
    const scrollTop = parseInt(document.documentElement.style.top);
    document.documentElement.classList.remove(className);
    document.documentElement.removeAttribute('style');
    document.documentElement.scrollTop = -scrollTop;
    document.body.scrollTop = -scrollTop;
};

export const hasScrollbar = $el => {
    return {
        hasScrollY: $el ? $el.scrollHeight > $el.clientHeight : false,
        hasScrollX: $el ? $el.scrollWidth > $el.clientWidth : false,
    };
};

export const isElementInViewport = el => {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

export const scrollTo = (target, direction = 'top', startTarget = $('html, body')) =>
    startTarget.scrollTop(target.offset()[direction]);

export const checkValue = input => input.value !== '';

export const clearStorageAndReloadPage = () => {
    window.addEventListener(
        'storage',
        function (e) {
            if (e.storageArea === sessionStorage) {
                sessionStorage.clear();
                document.location.reload();
            }
        },
        false
    );
};

export const clearStorageAndWriteNewValue = (key, value) => {
    sessionStorage.clear();
    sessionStorage.setItem(key, value);
};

export const clearSessionStorage = (selector, key) => {
    if (!selector.length && sessionStorage.getItem(key)) {
        sessionStorage.clear();
    }
};

export const flat = (arr, deep = 1) =>
    deep > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flat(val, deep - 1) : val), []) : arr.slice();

export const getDefaultNamesAndValues = $list => {
    const obj = {};
    $list.each((_, field) => {
        obj[field.name] = field.value;
        field.removeAttribute('name');
    });
    return obj;
};

export const deleteFormDataKeys = (formData, elList) => {
    elList.each((_, item) => {
        if (formData.has(item.name)) formData.delete(item.name);
    });
};

export const addFormDataKeys = (formData, elList, value, method = null) => {
    elList.each((_, item) => {
        if (!formData.has(item.name)) formData.set(item.name, method ? item[method] : value);
    });
};

export const addFormDataKeysFromObj = (formData, obj) => {
    Object.keys(obj).forEach(key => {
        formData.set(key, obj[key]);
    });
};

export const replaceFormDataKeys = (formData, elList, attr = null) => {
    elList.each((_, item) => {
        if (formData.has(item.name)) {
            formData.set(item.name, attr && item.getAttribute(attr) ? item.getAttribute(attr) : '');
        }
    });
};

export const setBlobToFormData = (formData, obj) => {
    Object.keys(obj).forEach(key => {
        formData.set(key, obj[key][0], obj[key][1]);
    });
};

export const expirationDateInHours = hours => new Date(new Date().getTime() + hours * 60 * 60 * 1000);

export const replaceString = (oldStr, newStr, str) => str.replace(oldStr, newStr);

export const hasInString = (str, searchable) => str.includes(searchable);

export const loadScript = (ulr, cb, to = document.body, append = 'appendChild') => {
    const script = document.createElement('script');
    script.src = ulr;
    script.onload = cb;
    to[append](script);
};

export const sort = (array, key = null) => {
    return array.sort((a, b) => {
        switch (true) {
            case (a[key] || a) > (b[key] || b):
                return 1;
            case (a[key] || a) < (b[key] || b):
                return -1;
            default:
                return 0;
        }
    });
};

export const stopVideo = element => {
    const iframe = element.querySelector('iframe');
    const video = element.querySelector('video');
    if (iframe) {
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc;
    }
    if (video) {
        video.pause();
    }
};

export const checkClientRect = $el => $el.getBoundingClientRect();

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const fireSome = (array, dep) => array.some(item => item === dep);

export const isLastIteration = (index, array) => array.length - 1 === index;

export const replaceFileName = str => str.replace(/\.(png|jpe?g|pdf|doc|docx|xls|xlsx|xlsm|xltx|xltm)$/i, '');

export const removeExtraSpaces = str => str.replace(/\s+/g, ' ');

export const isImage = type => type === PNG || type === JPEG || type === JPG;

export const isDocument = type =>
    type === PDF ||
    type === DOC ||
    type === DOCX ||
    type === XLS ||
    type === XLSM ||
    type === XLSX ||
    type === XLTX ||
    type === XLTM;

export const getCurrentSubmitBtn = () => {
    $('body').on('click.getCurrentSubmitBtn', 'form button[type="submit"], form input[type="submit"]', function () {
        const $this = $(this);
        $this
            .closest('form')
            .find('button[type="submit"][data-current-submit-btn]')
            .removeAttr('data-current-submit-btn');
        $this.attr('data-current-submit-btn', '');
    });
};

export const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export const setLoader = (tagName, classes = '') =>
    `<${tagName} class="lds-dual-ring ${classes}" data-loader><div class="lds-dual-ring__inner"></div></${tagName}>`;

export const removeURL = str => str.replace(/^(https?):\/\//, '').replace(/\/$/, '');

export const ajax = (type, url, data, options = {}) => {
    return $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Language': window.locale,
        },
        url,
        type,
        contentType: false,
        processData: false,
        data,
        ...options,
    });
};

export const ajax2 = async (type, url, data, options = {}) => {
    let result = {};
    await axios({
        method: type,
        url: url,
        data,
        options: {
            ...options,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                Language: window.locale,
            },
        },
    }).then(dataa => (result = { ...dataa }));
    // 'http://127.0.0.1:8000' +
    // console.log(result, 'asdas');
    // const result = await responce.json();
    // console.log(result, 'asdas');
    // const responce = $.ajax({
    //     headers: {
    //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    //         Language: window.locale,
    //     },
    //     url,
    //     type,
    //     contentType: false,
    //     processData: false,
    //     data,
    //     ...options,
    // }).then(response => response);
    // console.log(await responce);
    // console.log(JSON.stringify(await result));
    // console.log(await result.data);
    // localStorage.data = await JSON.stringify(result.data);
    return await { status: 200, data: result };
};

export const updateURL = (url, title, data = null) => window.history.pushState(data, title, url);

export const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

export const decrementCount = count => {
    return () => count--;
};

export const sliceArrayOnTwoParts = (arr, count) => {
    const firstPart = arr.slice(0, count);
    const secondPart = arr.slice(count, arr.length);

    return [firstPart, secondPart];
};

export const moveArrayItemToNewIndex = (arr, startItem, endItem) => {
    const startIndex = arr.findIndex(item => item === startItem);
    const endIndex = arr.findIndex(item => item === endItem);
    const correctStartIndex = Number(startIndex) >= 0;
    const correctEndIndex = Number(endIndex) >= 0;

    if (!correctStartIndex || !correctEndIndex) {
        return arr;
    }

    const item = arr[startIndex];

    arr.splice(startIndex, 1);
    arr.splice(endIndex, 0, item);

    return arr;
};

export const popupHandlerShow = (popup, method = false) => {
    if (method === 'show') {
        popup.removeAttr('style').addClass('js-popup-show');
        hiddenScroll();
    } else {
        popup.removeClass('js-popup-show');
        visibleScroll();
    }
};

export const autosize = text => {
    const resize = $text => {
        $text.css('height', 'auto');
        $text.css('height', $text[0].scrollHeight + 'px');
    };

    text.each(function () {
        $(this).attr('rows', 1);
        resize($(this));
    });

    text.on('input change', e => {
        resize($(e.target));
    });
};

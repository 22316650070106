import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/additional-methods';
import 'jquery-validation/dist/localization/messages_ru';
import {
    URL_MESSAGE,
    ADDRESS_ERROR_MESSAGE,
    COMPANY_FORMAT,
    CATEGORY_REQUIRED,
    NOT_CORRECT_FIELD,
    REQUIRED_FIELD,
    NOT_CORRECT_DATE,
    NOT_CORRECT_PRICE,
    FIELD_WITH_VALUE_ALREADY_EXIST,
    REQUIRED_PHOTO,
    PHOTO_SIZE,
    URL_MESSAGE_EN,
    ADDRESS_ERROR_MESSAGE_EN,
    COMPANY_FORMAT_EN,
    CATEGORY_REQUIRED_EN,
    NOT_CORRECT_FIELD_EN,
    REQUIRED_FIELD_EN,
    NOT_CORRECT_DATE_EN,
    NOT_CORRECT_PRICE_EN,
    FIELD_WITH_VALUE_ALREADY_EXIST_EN,
    REQUIRED_PHOTO_EN,
    PHOTO_SIZE_EN,
} from './validation.variables';

const mainValidation = () => ({
    required: true,
    normalizer(value) {
        return $.trim(value);
    },
});

const isRU = window.locale == 'ru';

const mainMessage = (key, message = (isRU ? NOT_CORRECT_FIELD : NOT_CORRECT_FIELD_EN)) => ({
    [key]: message,
});

const addCheckboxProdCategoryGroup = () => {
    if (!$('.js-choicer-inputs').length) return '';

    const checkboxes = $('.js-choicer-inputs').find('[data-required-visibility][type="checkbox"]');
    let names = '';

    checkboxes.each((_, checkbox) => {
        names += `${$(checkbox).attr('name')} `;
    });

    return names.trim();
};

export const oneOfFn = (name, $inputs, message = (isRU ? REQUIRED_FIELD : REQUIRED_FIELD_EN)) => {
    return $.validator.addMethod(
        name,
        function () {
            if ($inputs.filter(':checked').length) {
                return true;
            }

            return false;
        },
        message
    );
};

export default class Validation {
    static init() {
        $('.form-inputs').each(function () {
            const $this = $(this);

            $this.validate({
                lang: 'ru',
                ignore: '[contenteditable="true"]:not([name])',
                errorClass: 'error',
                validClass: 'success',
                groups: {
                    // googleAutocomplete: 'legal_address country district city street house lng ltd',
                    yandexAutocomplete: 'legal_address country district city street house lng ltd',
                    checkboxProdCategory: addCheckboxProdCategoryGroup(),
                    workdays: 'workdays_from workdays_to',
                    monday: 'monday_from monday_to',
                    tuesday: 'tuesday_from tuesday_to',
                    wednesday: 'wednesday_from wednesday_to',
                    thursday: 'thursday_from thursday_to',
                    friday: 'friday_from friday_to',
                    saturday: 'saturday_from saturday_to',
                    sunday: 'sunday_from sunday_to',
                    productionFormat: `category_sale${
                        $(':checkbox[name="category_prod"][data-id="category_id"]').length ? ' category_prod' : ''
                    }`,
                },
                errorPlacement(error, element) {
                    const $element = $(element);
                    const inputParent = $element.closest('.form-group__inner');

                    if (error.siblings('.form-group__tip-error').length) {
                        $element.removeClass('is-invalid');
                        error.siblings('.form-group__tip-error').empty();
                    }

                    if ($element.hasClass('image-upload')) {
                        error.appendTo($element.closest('.photo__wrap'));
                        error.siblings('.photo__error').remove();
                        return;
                    }

                    if (inputParent.length && !$element.hasClass('next-error') && !$element.hasClass('closest-error')) {
                        error.insertAfter(inputParent);
                    } else if ($element.hasClass('closest-error')) {
                        error.insertAfter($element.closest('.next-be-error'));
                    } else {
                        error.insertAfter($element);
                    }

                    if (error.siblings('.photo__error').length) {
                        $element.removeClass('is-invalid');
                        error.siblings('.photo__error').remove();
                    }
                },
                highlight(element, errorClass, validClass) {
                    $(element).addClass(errorClass).removeClass(validClass);
                    $(element).closest('.form-group').addClass('form-group--error');
                },
                unhighlight(element, errorClass, validClass) {
                    $(element).removeClass(errorClass).addClass(validClass);
                    $(element).closest('.form-group').removeClass('form-group--error');
                },
                rules: {
                    name: {
                        required: true,
                        letters: true,
                    },
                    surname: {
                        letters: true,
                    },
                    second_name: {
                        letters: true,
                    },
                    // TODO: I will remove it in future
                    // phone: {
                    //     ...mainValidation(),
                    // },
                    email: {
                        required: true,
                        email: true,
                    },
                    password: {
                        required: true,
                        minlength: 8,
                    },
                    text: {
                        required: true,
                        maxlength: 3000,
                    },
                    password_confirmation: {
                        required: true,
                        minlength: 8,
                        equalTo: '#password',
                    },
                    legal_name: {
                        ...mainValidation(),
                    },
                    inn: {
                        ...mainValidation(),
                    },
                    legal_address: {
                        normalizer(value) {
                            return $.trim(value);
                        },
                        address: true,
                    },
                    description: {
                        ...mainValidation(),
                    },
                    site: {
                        url2: true,
                    },
                    video_link: {
                        url: true,
                    },
                    product_link: {
                        url2: true,
                    },
                    seller_type: {
                        required: true,
                        minlength: 1,
                    },
                    // checkbox_payment_type: {
                    //     minlength: 1,
                    // },
                    delivery: {
                        required: true,
                        minlength: 1,
                    },
                    installation: {
                        required: true,
                        minlength: 1,
                    },
                    production: {
                        required: true,
                        minlength: 1,
                    },
                    vk: {
                        required: '#checkbox-vk:checked',
                        url: '#checkbox-vk:checked',
                    },
                    // fb: {
                    //     required: '#checkbox-fb:checked',
                    //     url: '#checkbox-fb:checked',
                    // },
                    // inst: {
                    //     required: '#checkbox-inst:checked',
                    //     url: '#checkbox-inst:checked',
                    // },
                    odn: {
                        required: '#checkbox-odn:checked',
                        url: '#checkbox-odn:checked',
                    },
                    youtube: {
                        required: '#checkbox-youtube:checked',
                        url: '#checkbox-youtube:checked',
                    },
                    workdays_from: {
                        required: '#checkbox-workdays:checked',
                        minlength: 5,
                    },
                    workdays_to: {
                        required: '#checkbox-workdays:checked',
                        minlength: 5,
                    },
                    monday_from: {
                        required: '#checkbox-monday:checked',
                        minlength: 5,
                    },
                    monday_to: {
                        required: '#checkbox-monday:checked',
                        minlength: 5,
                    },
                    tuesday_from: {
                        required: '#checkbox-tuesday:checked',
                        minlength: 5,
                    },
                    tuesday_to: {
                        required: '#checkbox-tuesday:checked',
                        minlength: 5,
                    },
                    wednesday_from: {
                        required: '#checkbox-wednesday:checked',
                        minlength: 5,
                    },
                    wednesday_to: {
                        required: '#checkbox-wednesday:checked',
                        minlength: 5,
                    },
                    thursday_from: {
                        required: '#checkbox-thursday:checked',
                        minlength: 5,
                    },
                    thursday_to: {
                        required: '#checkbox-thursday:checked',
                        minlength: 5,
                    },
                    friday_from: {
                        required: '#checkbox-friday:checked',
                        minlength: 5,
                    },
                    friday_to: {
                        required: '#checkbox-friday:checked',
                        minlength: 5,
                    },
                    saturday_from: {
                        required: '#checkbox-saturday:checked',
                        minlength: 5,
                    },
                    saturday_to: {
                        required: '#checkbox-saturday:checked',
                        minlength: 5,
                    },
                    sunday_from: {
                        required: '#checkbox-sunday:checked',
                        minlength: 5,
                    },
                    sunday_to: {
                        required: '#checkbox-sunday:checked',
                        minlength: 5,
                    },
                    category_prod: {
                        productionFormat: true,
                    },
                    category_sale: {
                        productionFormat: true,
                    },
                    prod_category_parent: {
                        parentProductionCategories: true,
                        minlength: 1,
                    },
                },
                messages: {
                    name: mainMessage('letters'),
                    surname: mainMessage('letters'),
                    second_name: mainMessage('letters'),
                    password: mainMessage('password'),
                    password_confirmation: mainMessage('password'),
                    email: mainMessage('email'),
                    legal_address: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    country: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    district: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    city: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    street: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    house: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    ltd: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    lng: mainMessage('required', (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)),
                    personal_agreement: {
                        required: '',
                    },
                    site: (isRU ? URL_MESSAGE : URL_MESSAGE_EN),
                    product_link: (isRU ? URL_MESSAGE : URL_MESSAGE_EN),
                },
            });
        });

        $.validator.addMethod('letters', function (value, element) {
            return this.optional(element) || /^([a-zа-яё]+)$/i.test(value);
        });

        $.validator.addMethod('filesize', function (value, element, param) {
            return this.optional(element) || element.files[0].size <= param;
        });

        /*
            TODO: Method for auth with strong password.
            Comment it because it need a customer.
        */
        // $.validator.addMethod('password', function (value, element) {
        //     return this.optional(element) || /^(?!.*(\s))(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/.test(value);
        // });

        $.validator.addMethod(
            'futureDate',
            function (value, element) {
                const curDate = new Date();
                const valueArr = value.split('/');

                return this.optional(element) || new Date(valueArr[2], valueArr[1] - 1, valueArr[0]) > curDate;
            },
            (isRU ? NOT_CORRECT_DATE : NOT_CORRECT_DATE_EN),
        );

        $.validator.addMethod(
            'currency',
            function (value) {
                const valueArr = value.split('');
                const dot = '.';
                const dotIndex = valueArr.indexOf(dot);
                const hasDot = dotIndex === -1;
                const digitsAfterDot = hasDot ? [] : valueArr.slice(dotIndex + 1);

                if ((valueArr[0] === '0' && hasDot) || valueArr[0] === dot || digitsAfterDot.length === 1) {
                    return false;
                }

                return true;
            },
            (isRU ? NOT_CORRECT_PRICE : NOT_CORRECT_PRICE_EN),
        );

        $.validator.addMethod(
            'unique',
            function (value, element) {
                const elements = $(element)
                    .closest('form')
                    .find(`[data-unique="${$(element).attr('data-unique')}"]`);
                let matchesFound = 0;
                elements.each((_, item) => {
                    if (item.value === value) {
                        matchesFound++;
                    }
                });
                if (this.optional(element) || matchesFound <= 1) {
                    return true;
                }
                return false;
            },
            (isRU ? FIELD_WITH_VALUE_ALREADY_EXIST : FIELD_WITH_VALUE_ALREADY_EXIST_EN),
        );

        const $productionCategoryOld = $('.checkbox-production-category, #production_2');
        if ($productionCategoryOld.length) {
            oneOfFn('checkProductionCategory', $productionCategoryOld);
        }

        const $productionFormatCheckboxes = $(
            `:checkbox[name="category_prod"][data-id="category_id"],
            :checkbox[name="category_sale"][data-id="category_id"]`
        );
        if ($productionFormatCheckboxes.length) {
            oneOfFn('productionFormat', $productionFormatCheckboxes,
                (isRU ? COMPANY_FORMAT : COMPANY_FORMAT_EN)
            );
        }

        const $parentProductionCategories = $(':radio[name="prod_category_parent"]');
        if ($parentProductionCategories.length) {
            oneOfFn('parentProductionCategories', $parentProductionCategories,
                (isRU ? CATEGORY_REQUIRED : CATEGORY_REQUIRED_EN)
            );
        }

        //NOTE: google map temporarily disabled
        // if ($('#google-autocomplete').length) {
        //     $.validator.addMethod(
        //         'address',
        //         function () {
        //             const $inputAutocomplete = $('#google-autocomplete');
        //             const siblingsFields = $inputAutocomplete.siblings('input[required]');
        //             const hasValue = [...siblingsFields, ...$inputAutocomplete].every(input => input.value);
        //             const hasRequired = [...siblingsFields, ...$inputAutocomplete].every(input =>
        //                 input.hasAttribute('required')
        //             );

        //             if (hasValue || !hasRequired) {
        //                 return true;
        //             }
        //             return false;
        //         },
        //         ADDRESS_ERROR_MESSAGE
        //     );
        // }

        if ($('#yandex-autocomplete').length) {
            $.validator.addMethod(
                'address',
                function () {
                    const $inputAutocomplete = $('#yandex-autocomplete');
                    const siblingsFields = $inputAutocomplete.siblings('input[required]');
                    const hasValue = [...siblingsFields, ...$inputAutocomplete].every(input => input.value);
                    const hasRequired = [...siblingsFields, ...$inputAutocomplete].every(input =>
                        input.hasAttribute('required')
                    );

                    if (hasValue || !hasRequired) {
                        return true;
                    }
                    return false;
                },
                (isRU ? ADDRESS_ERROR_MESSAGE : ADDRESS_ERROR_MESSAGE_EN)
            );
        }

        const defaultValidate = $('.default-validate');
        const imgValidate = $('.img-validate');
        // const googleAutocompleteValidate = $('#google-autocomplete');
        const choicerInputsValidate = $('.js-choicer-inputs');
        const uniqueEmails = $('[data-unique="email"]');
        const uniquePhones = $('[data-unique="phone"]');
        const futureDateValidate = $('.future-date');
        const currencyValidate = $('.currency');

        if (defaultValidate.length) {
            Validation._addDefaultValidation(defaultValidate);
        }

        if (imgValidate.length) {
            Validation._addImgValidation(imgValidate);
        }

        // TODO: We are going to make a new logic for google maps/autocomplete
        // if (googleAutocompleteValidate.length) {
        //     Validation._addGoogleAutocompleteValidation(googleAutocompleteValidate);
        // }

        if (choicerInputsValidate.length) {
            const checkboxes = choicerInputsValidate.find('[data-required-visibility][type="checkbox"]');

            Validation._addChoicerValidation(checkboxes);
        }

        if (uniquePhones.length) {
            Validation.addUniqueValidation(uniquePhones);
        }

        if (uniqueEmails.length) {
            Validation.addUniqueValidation(uniqueEmails);
        }

        if (futureDateValidate.length) {
            Validation._addFutureDateValidation(futureDateValidate);
        }

        if (currencyValidate.length) {
            Validation._addCurrencyValidation(currencyValidate);
        }
    }

    static _addDefaultValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('remove');
            $(input).rules('add', {
                ...mainValidation(),
            });
        });
    }

    static _addImgValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('add', {
                ...mainValidation(),
                messages: {
                    required: (isRU ? REQUIRED_PHOTO : REQUIRED_PHOTO_EN),
                },
            });
        });
    }

    static _addGoogleAutocompleteValidation(inputs) {
        const siblingsInputs = inputs.siblings('input[required]');

        siblingsInputs.each((_, input) => {
            $(input).rules('add', {
                ...mainValidation(),
                messages: mainMessage('required'),
            });
        });
    }

    static _addChoicerValidation(checkboxes) {
        checkboxes.each((_, checkbox) => {
            $(checkbox).rules('add', {
                checkProductionCategory: true,
            });
        });
    }

    static addUniqueValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('add', {
                required: true,
                unique: true,
            });
        });
    }

    static removeValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('remove');
        });
    }

    static _addFutureDateValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('add', {
                futureDate: true,
            });
        });
    }

    static _addCurrencyValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('add', {
                currency: true,
            });
        });
    }

    static _addImagesValidation(inputs) {
        inputs.each((_, input) => {
            $(input).rules('add', {
                extension: 'png|jpeg|jpg',
                filesize: 5048576,
                messages: {
                    required: (isRU ? REQUIRED_PHOTO : REQUIRED_PHOTO_EN),
                    filesize: (isRU ? PHOTO_SIZE : PHOTO_SIZE_EN),
                },
            });
        });
    }
}

export class Loader {
    static init($loader, $overlay) {
        const isActiveLoader = window._activeLoader_;

        if (!isActiveLoader) {
            Loader._checkLoading($loader, Date.now(), window._startLoading_);
        }
        document.addEventListener('DOMContentLoaded', () => {
            if (!isActiveLoader) {
                Loader._checkLoading($loader, Date.now(), window._startLoading_);
            }
        });
        document.addEventListener('readystatechange', () => {
            if (!isActiveLoader) {
                Loader._checkLoading($loader, Date.now(), window._startLoading_);
            }
        });
        window.addEventListener('load', () => {
            Loader._offLoader($loader, $overlay);
        });
    }

    static _checkLoading($loader, now, start) {
        const diff = now - start;

        if (diff >= window._defaultTime_) {
            $loader.classList.add('active');
            window._activeLoader_ = true;
        }
    }

    static _offLoader($loader, $overlay) {
        const script = $loader.nextElementSibling;

        if ($loader.classList.contains('active')) {
            $loader.classList.remove('active');
        }
        $overlay.classList.add('hidden');
        script.parentNode.removeChild(script);
        delete window._startLoading_;
        delete window._defaultTime_;
        delete window._activeLoader_;
    }

    static showLoader() {
        $('#loader').addClass('active');
        $('#overlay').removeClass('hidden');
    }
}

export default class GetCode {
    static init(wrap) {
        const elGetCode = wrap.find('.code-get');

        GetCode._showTip(elGetCode, 30000);
        GetCode._getNewCode(wrap);
    }

    static _getNewCode(wrap) {
        const elHelpCode = wrap.find('.code-help');

        wrap.on('click', 'a[data-url]', function (e) {
            e.preventDefault();
            const $this = $(this);

            $this.addClass('not-allowed');
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
                url: `${$this.data('url')}`,
                type: 'POST',
                contentType: false,
                processData: false,
                success() {
                    $this.closest('.code-get').fadeOut(200, function () {
                        $(this).remove();
                    });
                    GetCode._showTip(elHelpCode, 30000);
                },
            });
        });
    }

    static _showTip(el, delay) {
        setTimeout(() => {
            el.fadeIn(200);
        }, delay);
    }
}

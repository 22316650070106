export class ProductionDelete {
    static init($btn) {
        $btn.off('click.findPopUpProdDel').on('click.findPopUpProdDel', function (e) {
            const $subtitle = $(`.${$(this).data('popup')}`).find('[data-remove-subtitle]'),
                $names = $(`.${$(this).data('popup')}`).find('[data-remove-names]');
            $subtitle.text('');
            $names.text('');

            const $categoryChildren = $(this).is('[data-production-has-children]')
                ? $(this)
                      .closest('[data-production-parent]')
                      .find('[data-production-children]:first')
                      .find('[data-production-name-category]')
                : [];

            if ($categoryChildren.length) {
                $subtitle.text('Удалив её, так же удалятся связанные с ней подкатегории:');
                $names.text(`${[...$categoryChildren].map(el => $(el).text()).join(', ')}`);
            }
        });
    }
}

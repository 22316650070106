import { Uploader } from '../uploader/Uploader';
import { createHTML } from './cropPhoto.template';

export default class CropPhoto extends Uploader {
    static async init(wrap, cropWrap, options) {
        try {
            const croppedPhoto = await super.init(cropWrap, options);

            this.uploadFile(
                wrap,
                croppedPhoto,
                (_wrap, data, nameOfImage) => {
                    if (data && typeof data === 'object' && data.__proto__ === Blob.prototype) {
                        _wrap.html('').html(createHTML(_wrap, URL.createObjectURL(data), nameOfImage));
                        options.emitter.emit('image:uploaded', [data, $('input[name="image"]')]);
                        wrap.find('.file__img img').on('load', function () {
                            URL.revokeObjectURL(this.src);
                        });
                    } else {
                        _wrap.html('').html(createHTML(_wrap, data));
                    }
                },
                { circle: options.uploader.type, type: options.uploader.dataType }
            );

            wrap.on('change', 'input[type="file"]', e => {
                super.readFile(e, wrap, croppedPhoto, options);
            });
        } catch (error) {
            alert(error.message);
        }
    }

    static rotatePhoto(croppedPhoto) {
        super.rotatePhoto(croppedPhoto);
    }

    static createError($wrap) {
        return super.createError($wrap);
    }
}

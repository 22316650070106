export const hideModal = ($modal, $btn, emitter, transition = 200) => {
    return new Promise(resolve => {
        $modal.stop().slideUp(transition, function () {
            $modal.removeClass('h-100per');
            $modal.find('[data-modal-info-title]').empty();
            $modal.find('[data-modal-info-description]').empty();
            emitter && emitter.emit('modalInfo:hidden', $btn);
            resolve($(this));
        });
    });
};

export const showModal = ($modal, emitter, transition = 200) => {
    return new Promise(resolve => {
        $modal.stop().slideDown(transition, function () {
            emitter && emitter.emit('modalInfo:shown');
            resolve($(this));
        });
    });
};

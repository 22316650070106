export const generateUrlChangeStatus = (url, id, status) => {
    return `${url}?id=${id}&status=${status}`;
};

export const generateUrlGetWarehouses = (url, id) => {
    return `${url}?id=${id}`;
};

export const generateUrlChangeWarehouse = (url, id, changeId) => {
    return `${url}?id=${id}&changeId=${changeId}`;
};

export const generateUrlDelete = (url, id) => {
    return `${url}?id=${id}`;
};

export const generateOptionsHtml = (defaultText, options) => {
    const defaultOption = `<li class="custom-select__option">
                <a href="#" class="selected" data-default-option>${defaultText}</a>
            </li>`;

    return options.reduce(function (acc, item) {
        const { name, id } = item;
        acc += `<li class="custom-select__option">
                        <a href="#" class="text--overflow" data-select-id="${id}">${name}</a>
                    </li>`;
        return acc;
    }, defaultOption);
};

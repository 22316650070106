import { debounce, ajax } from '@auxiliary/customMethods';
import { notFound, btnCreate } from '@components/UI/customSelect/search/searchSelect.template';
import CustomSelect from '../CustomSelect';

let dataList = null;
export class SearchSelect extends CustomSelect {
    static init(wraps) {
        SearchSelect.toggleSearchSelect('searchSelectTagToggle', 'searchSelectTagClose');

        [...wraps].forEach(wrap => {
            const $wrap = $(wrap);

            SearchSelect.setValue($wrap);
            SearchSelect.searchValue($wrap);
        });
    }

    static toggleSearchSelect(
        toggleNameSpace,
        closeNameSpace,
        delegation = '.js-search-select .search-select__input-inner input',
        search = true
    ) {
        $('body')
            .off(`click.${toggleNameSpace}`)
            .on(`click.${toggleNameSpace}`, `${delegation}`, function () {
                const $this = $(this);
                const wrap = $this.closest('.custom-select');

                if (wrap.hasClass('active')) {
                    SearchSelect.clearSearch(wrap);
                }

                wrap.toggleClass('active');
                SearchSelect.focusInput(wrap);

                SearchSelect.closeSelect(closeNameSpace, wrap, search);
            });
    }

    static closeSelect(closeNameSpace, wrap, search) {
        super.closeSelect(closeNameSpace, wrap, search);
    }

    static clearSearch(wrap) {
        super._clearSearch(wrap);
    }

    static setValue(wrap) {
        const input = wrap.find('.search-select__input-inner input');
        const optionList = wrap.find('.search-select__options');

        optionList.on('click', 'a', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.hasClass('selected')) return;

            optionList.find('a.selected').removeClass('selected');
            $this.addClass('selected');
            input.val($this.text());
            wrap.removeClass('active');
            SearchSelect.clearSearch(wrap);
        });
    }

    static focusInput(wrap) {
        wrap.hasClass('active') && wrap.find('.search-select__search input').focus();
    }

    static searchValue(wrap) {
        const mainInput = wrap.find('.search-select__input-inner input');
        const searchInput = wrap.find('.search-select__search input');
        const optionList = wrap.find('.search-select__options');
        const data = [];

        optionList.find('a').each((_, option) => {
            data.push($(option).text());
        });

        const searchFn = e => {
            const value = e.target.value.trim();

            if (value) {
                const searchedValues = data.filter(item => item.toLowerCase().includes(value.toLowerCase()));

                searchedValues.length
                    ? SearchSelect.renderOptions({ data: searchedValues, mainInput, optionList })
                    : SearchSelect.renderInfo(optionList, notFound);
            } else {
                SearchSelect.renderOptions({ data, mainInput, optionList });
            }
        };

        searchInput.on('input', debounce(searchFn, 500));
    }

    static renderOptions({ data, mainInput, optionList }) {
        optionList.empty();
        data.forEach(item => {
            optionList.append(SearchSelect._renderOption(item));
        });

        SearchSelect.findSelectedOption(mainInput, optionList);
    }

    static findSelectedOption(mainInput, optionList) {
        const options = optionList.find('a');
        const currVal = mainInput.val();

        if (options.length) {
            options.each((_, option) => {
                const $option = $(option);

                if (currVal && $option.text() === currVal) {
                    $option.addClass('selected');
                }
            });
        }
    }

    static _renderOption(option) {
        const dataId = option.id ? `data-id="${option.id}"` : '';

        return `
            <li class="custom-select__option">
                <a
                    href="#"
                    ${dataId}
                >${option.name ? option.name : option}</a>
            </li>
        `;
    }

    static renderInfo(wrap, HTML) {
        wrap.empty().append(HTML);
    }

    static createOption({ searchWrap, searchInput, optionList }) {
        searchWrap.append(btnCreate);
        searchWrap.off('click').on('click', '.search-select__create', function (e) {
            e.preventDefault();
            const $this = $(this);

            optionList.prepend(SearchSelect._renderOption(searchInput.val()));
            $this.remove();
        });
    }

    static xhr({ url, data }) {
        const formData = new FormData();
        const values = Object.values(data);

        Object.keys(data).forEach((key, i) => {
            formData.append(key, values[i]);
        });

        return ajax('POST', url, formData);
    }

    static get isDisabled() {
        return Boolean($('.search-select.active').find('.search-select__search.disabled').length);
    }

    static set setDataList(data) {
        dataList = data;
    }

    static get getDataList() {
        return dataList;
    }

    static enableSearch($wrap) {
        $wrap.removeClass('disabled');
    }

    static disableSearch($wrap) {
        $wrap.addClass('disabled');
    }

    static destroySelect(wrap) {
        wrap.find('.search-select__options').off('click');
        wrap.find('.search-select__search input').off('input');
        wrap.find('.search-select__create').off('click');
    }
}

import CheckboxData from '../UI/CheckboxData';
import FormSteps from './FormSteps';
import { hasOwnProperty } from '@auxiliary/customMethods';
import { ios } from 'is_js';

const eventName = ios() ? 'pagehide' : 'beforeunload';

export default class Create {
    static sendData(options) {
        const { form, step, url, instanceIntlPhone = null } = options;

        form.off('submit').on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);
            const btnSubmit = $this.find('button[type="submit"][data-current-submit-btn]');

            if ($this.valid()) {
                $this.addClass('not-allowed');
                const formData = new FormData($this[0]);

                if (instanceIntlPhone) {
                    const { name, number } = instanceIntlPhone;
                    number && formData.set(name, number);
                }

                Create._showLoader(form);
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        Create.correctFormData(formData);
                        Create.removeErrors(form);
                    },
                    success() {
                        if (form.is('[data-form-last]')) {
                            Create.checkLastStep($this);
                            return;
                        }

                        Create._setSession($this);
                        FormSteps.nextStep($this, step, btnSubmit, Create.sendData);
                    },
                    error(data) {
                        Create.createError({ data, form });
                    },
                });
            }
        });
    }

    static correctFormData(formData) {
        if (formData.has('legal_address')) {
            formData.delete('legal_address');
        }

        if (formData.has('checkbox_warehouse_type')) {
            formData.delete('checkbox_warehouse_type');
        }
    }

    static changeFileSubscriber(emitter, filesData) {
        if (emitter) {
            emitter.on('file:uploaded', ([blob, $input]) => {
                filesData[$input[0].name] = [blob, $input.val()];
            });
            emitter.on('file:delete', $input => {
                const key = $input[0].name;
                if (hasOwnProperty(filesData, key)) {
                    delete filesData[key];
                }
            });
        }
    }

    static showAlertBeforeLeavePage() {
        $(window)
            .on(eventName, () => {
                window.event.cancelBubble = true;
                return true;
            })
            .on('unload', () => {
                sessionStorage.clear();
            });
    }

    static removeAlertBeforeLeavePage() {
        $(window).off(eventName).off('unload');
    }

    static checkLastStep(form, href = 'successful-create') {
        const $forms = form.closest('[data-create]').find('[data-form-step]');

        if ($forms.length) {
            $forms.each((_, formEl) => {
                formEl.reset();
            });
        } else {
            form[0].reset();
        }

        sessionStorage.clear();
        window.location.href = href;
        return;
    }

    static _showLoader(form) {
        if (form.is('[data-form-last]')) {
            $('#loader').addClass('active');
            $('#overlay').removeClass('hidden');
        }
    }

    static _hideLoader(form) {
        if (form.is('[data-form-last]')) {
            $('#loader').removeClass('active');
            $('#overlay').addClass('hidden');
        }
    }

    static checkSessionMainKey(value) {
        const sessionMainKey = sessionStorage.getItem('creating');

        if (!sessionMainKey) {
            sessionStorage.setItem('creating', value);
        } else if (sessionMainKey && sessionMainKey !== value) {
            sessionStorage.clear();
            sessionStorage.setItem('creating', value);
        }
    }

    static checkSession(form, step, cb) {
        const wrap = $('[data-create]');
        const stepsWrap = $('#form-steps');
        const stepAttr = step.data('step');
        const isSession = Boolean(sessionStorage.getItem(stepAttr));

        if (sessionStorage.getItem('form-step') && Number(stepAttr) === Number(sessionStorage.getItem('form-step'))) {
            stepsWrap.find('.active[data-step-text]').removeClass('active');
            step.closest('.form-steps__item')
                .prevAll()
                .find('[data-step]')
                .removeClass('form-steps__step--active')
                .addClass('form-steps__step--done');
            wrap.find('.active[data-form-step]').removeClass('active').css('display', 'none');
            stepsWrap.find(`[data-step-text="${stepAttr}"]`).addClass('active');
            step.addClass('form-steps__step--active');
            form.addClass('active').css('display', 'block');

            cb({
                form,
                url: form.data('form-url'),
                step,
            });
        }

        if (isSession) {
            stepsWrap.find(`[data-step="${stepAttr}"]`).addClass('form-steps__step--done');
        }
    }

    // NOTE: Old method does not use
    static getSession(form) {
        const step = `${form.data('form-step')}`;
        const curSession = sessionStorage.getItem(step);

        if (curSession) {
            const session = JSON.parse(curSession);
            const keys = Object.keys(session);
            const values = Object.values(session);

            keys.forEach((name, i) => {
                const $inputs = form.find(`[name="${name}"]`);

                $inputs.each((_, input) => {
                    const $input = $(input);

                    if ($input.is('[type="checkbox"]') && !$input.is('[data-required-visibility]')) {
                        $input.prop('checked', values[i]);
                        return;
                    }

                    $input.val(values[i]);
                });
            });

            if (form.find('[data-checkbox-wrap]').length) {
                CheckboxData.init(form.find('[data-checkbox-wrap]'));
            }
        }
    }

    // NOTE: Old method does not use
    static _setSession(form) {
        const session = {};
        const formStep = form.data('form-step');

        form.find('[data-input]').each((_, input) => {
            const $input = $(input);

            if ($input.is('[type="checkbox"]') && !$input.is('[data-required-visibility]')) {
                session[$input.attr('name')] = $input.prop('checked');
                return;
            } else if ($input.is('[type="radio"]')) {
                if ($input.is('[type="radio"]:checked')) {
                    session[$input.attr('name')] = $input.val();
                }
                return;
            }

            if (!$input.val()) {
                return;
            }

            session[$input.attr('name')] = $input.val();
        });

        sessionStorage.setItem(formStep.toString(), JSON.stringify(session));
    }

    static _setStepToSession(form) {
        sessionStorage.setItem(form.data('form-step'), true);
    }

    static createError({ data, form }) {
        const { errors } = data.responseJSON;

        Create._hideLoader(form);
        form.removeClass('not-allowed');

        if (!errors) {
            alert('Something Wrong!');
            return;
        }

        const keys = Object.keys(errors);
        const values = Object.values(errors);

        Create._addErrors(form, keys, values);
    }

    static _addErrors(form, names, values) {
        names.forEach((name, i) => {
            let $input = form.find(`[name="${name}"]`);

            if (!$input.length) {
                $input = form.find(`[id="${name}"]`);
            }

            $input.addClass('is-invalid');
            $input.closest('.form-group').find('.form-group__tip-error').text(values[i].join(''));

            const $errors = $('.form-group__tip-error:not(:empty)');

            $('html, body').scrollTop($($errors[0]).closest('.form-group').offset().top);
        });
    }

    static removeErrors(form) {
        const $inputs = form.find('.is-invalid, input.error, textarea.error');

        $inputs.each((_, input) => {
            const $input = $(input);

            $input.removeClass('is-invalid error');
            $input.closest('.form-group').find('.form-group__tip-error, label.error').text('');
        });
    }
}

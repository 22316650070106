export const COUNTRY = 'country';
export const ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1';
export const ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2';
export const ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3';
export const SUBLOCALITY_LEVEL_1 = 'sublocality_level_1';
export const SUBLOCALITY_LEVEL_2 = 'sublocality_level_2';
export const SUBLOCALITY_LEVEL_3 = 'sublocality_level_3';
export const LOCALITY = 'locality';
export const ROUTE = 'route';
export const STREET_NUMBER = 'street_number';
export const PREMISE = 'premise';
export const ESTABLISHMENT = 'establishment';
export const LNG = 'lng';
export const LTD = 'ltd';
export const LONG_NAME = 'long_name';
export const UNNAMED_ROAD = 'Unnamed Road';
export const defaultGeolocation = [55.76535091, 37.64607099];
// export const defaultGeolocation = {
//     lat: 55.76535091,
//     lng: 37.64607099,
// };

export const defaultGeolocationClusters = [63.62674464, 77.25585938];

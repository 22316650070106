import {
    ADMINISTRATIVE_AREA_LEVEL_2,
    ADMINISTRATIVE_AREA_LEVEL_3,
    SUBLOCALITY_LEVEL_1,
    SUBLOCALITY_LEVEL_2,
    SUBLOCALITY_LEVEL_3,
    PREMISE,
    ESTABLISHMENT,
} from './map.variables';

export const checkNewMapFields = addressType =>
    addressType === ADMINISTRATIVE_AREA_LEVEL_2 ||
    addressType === ADMINISTRATIVE_AREA_LEVEL_3 ||
    addressType === SUBLOCALITY_LEVEL_1 ||
    addressType === SUBLOCALITY_LEVEL_2 ||
    addressType === SUBLOCALITY_LEVEL_3 ||
    addressType === PREMISE ||
    addressType === ESTABLISHMENT;

export const concatAddress = addressList =>
    addressList
        .map(address => (address ? `${address}, ` : ''))
        .join('')
        .replace(/(,\s)$/, '');

import { defaultGeolocationClusters } from './map.variables';
import { YANDEX_MAP_URL } from '@auxiliary/constants';
import { warn } from '@auxiliary/customMethods';
import ymaps from 'ymaps';

export class YandexMap {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.emitter = options.emitter;
        this.locations = options.locations;
        this.flag = false;
        this.maps = null;
        this.myMap = null;
    }

    async render() {
        try {
            let myPlacemark = null;
            const latlng = this.locations
                ? [this.locations[0].latlng.lat, this.locations[0].latlng.lng]
                : defaultGeolocationClusters;
            const locations = this.locations;
            this.maps = await ymaps.load(YANDEX_MAP_URL);
            const maps = this.maps;

            this.myMap = new maps.Map(this.$wrap, {
                center: latlng,
                zoom: 4,
            });

            this.myMap.controls.remove('searchControl');
            this.myMap.controls.remove('zoomControl');
            this.myMap.controls.remove('rulerControl');

            if (!locations) {
                return;
            }

            locations.map((location, i) => {
                const { lat, lng } = location.latlng;
                this.myMap.geoObjects.add(
                    new maps.Placemark(
                        [lat, lng],
                        {
                            hintContent: location.name,
                            balloonContent: location.name,
                            // iconContent: '12',
                        },
                        {
                            preset: 'islands#icon',
                            iconColor: '#161616',
                        }
                    )
                );
            });

            const createPlacemark = coords => {
                return new maps.Placemark(
                    coords,
                    {
                        iconCaption: 'поиск...',
                    },
                    {
                        preset: 'islands#icon',
                        iconColor: '#161616',
                        draggable: true,
                        type: 'Point',
                    }
                );
            };

            const getAddress = async coords => {
                myPlacemark.properties.set('iconCaption', 'поиск...');
                const res = await maps.geocode(coords);
                const firstGeoObject = res.geoObjects.get(0);

                myPlacemark.properties.set({
                    iconCaption: [
                        firstGeoObject.getLocalities().length
                            ? firstGeoObject.getLocalities()
                            : firstGeoObject.getAdministrativeAreas(),
                        firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                    ]
                        .filter(Boolean)
                        .join(', '),
                    balloonContent: firstGeoObject.getAddressLine(),
                });
            };

            this.myMap.events.add('click', e => {
                const coords = e.get('coords');

                if (myPlacemark) {
                    myPlacemark.geometry.setCoordinates(coords);
                } else {
                    myPlacemark = createPlacemark(coords);
                    this.myMap.geoObjects.add(myPlacemark);
                    myPlacemark.events.add('dragend', () => {
                        getAddress(myPlacemark.geometry.getCoordinates());
                    });
                }
                getAddress(coords);
            });
        } catch (error) {
            warn(error);
        }
    }
}

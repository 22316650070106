export default class FormSteps {
    static init(wrap, isCompany) {
        const items = wrap.find('.form-steps__item');

        if (items.length > 3 && !isCompany) {
            FormSteps._hideLines(wrap);
        } else if (items.length > 3 && isCompany) {
            FormSteps._hideLines(wrap, window.matchMedia('(min-width: 768px) and (max-width: 1365px)'));
        }
    }

    static _hideLines(wrap, mq = window.matchMedia('(min-width: 768px) and (max-width: 991px)')) {
        const checkMq = () => {
            if (mq.matches) {
                wrap.addClass('form-steps--nolines');
            } else {
                wrap.removeClass('form-steps--nolines');
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }

    // NOTE: You should init this method immediatly in your CreateFunction
    static prevStep(form, step, cb) {
        const wrapSteps = step.closest('#form-steps');
        const mainWrap = form.closest('[data-create]');
        let activeStep, activeForm, toForm, toStep, toAdditionalText;
        mainWrap.on('click.prevstep', '[data-prev-step]', function (e) {
            e.preventDefault();

            const $this = $(this);

            activeStep = wrapSteps.find('.form-steps__step--active');
            activeForm = mainWrap.find('.active[data-form-step]');
            toAdditionalText = wrapSteps.find(`[data-step-text="${Number(activeStep.data('step')) - 1}"]`);
            toStep = wrapSteps.find(`[data-step="${Number(activeStep.data('step')) - 1}"]`);
            toForm = mainWrap.find(`[data-form-step="${Number(activeForm.data('form-step')) - 1}"]`);
            activeForm.addClass('not-allowed');

            FormSteps._utilFn({
                form: toForm,
                prevForm: activeForm,
                url: $this.data('step-url'),
                step: toStep,
                additionalText: toAdditionalText,
                cb,
            });
        });
    }

    // NOTE: This method is called in Create (create/Create.js)
    static nextStep(form, step, btn, cb) {
        const activeStep = step.data('step');
        const activeForm = form.data('form-step');
        const wrapSteps = step.closest('#form-steps');
        const mainWrap = form.closest('[data-create]');
        let toForm, toStep, toAdditionalText;

        btn.on('click.nextstep', function (e) {
            e.preventDefault();

            const $this = $(this);

            toAdditionalText = wrapSteps.find(`[data-step-text="${Number(activeStep) + 1}"]`);
            toStep = wrapSteps.find(`[data-step="${Number(activeStep) + 1}"]`);
            toForm = mainWrap.find(`[data-form-step="${Number(activeForm) + 1}"]`);

            FormSteps._utilFn({
                form: toForm,
                prevForm: form,
                url: $this.data('step-url'),
                step: toStep,
                additionalText: toAdditionalText,
                cb,
            });
            $this.off('click.nextstep');
        }).trigger('click.nextstep');
    }

    static _toggleSendData(dataObj) {
        const { form, prevForm, url, step, cb } = dataObj;

        prevForm.off('submit');
        cb({ form, url, step });
    }

    static _changeStepsStyle(step, additionalText) {
        const prevStep = step
            .closest('#form-steps')
            .find('.form-steps__step--active')
            .removeClass('form-steps__step--active');

        if (!prevStep.length) return;

        for (let i = 0; i < sessionStorage.length; i++) {
            if (Number(prevStep.data('step')) === Number(sessionStorage.key(i))) {
                prevStep.addClass('form-steps__step--done');
            }
        }

        step.removeClass('form-steps__step--done').addClass('form-steps__step--active');
        additionalText.siblings('.active').removeClass('active');
        additionalText.addClass('active');
    }

    static _utilFn(argObj) {
        const { form, prevForm, url, step, additionalText, cb } = argObj;

        FormSteps._changeStepsStyle(step, additionalText);
        FormSteps._toggleSendData({
            form,
            prevForm,
            url,
            step,
            cb,
        });

        prevForm.removeClass('active').fadeOut(300, function () {
            form.addClass('active').fadeIn(300, function () {
                prevForm.removeClass('not-allowed');
                $('html, body').animate(
                    {
                        scrollTop: $('[data-create]').offset().top,
                    },
                    300
                );
            });
        });

        sessionStorage.setItem('form-step', form.data('form-step'));
    }
}

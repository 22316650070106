import { GEvent } from '@components/metrics/gEvent/GEvent';
import { YMEvent } from '@components/metrics/YMEvent/YMEvent';
import { YandexMap } from '@components/yandexMap/YandexMap';
import { warn, useIntersectionObserver } from '@auxiliary/customMethods';
// import { CLUSTER_URL, MAP_URL } from '@auxiliary/constants';
import { buttonTemplate, itemTemplate } from './productOffers.template';

import { Sort } from '@components/sort/Sort';
import {
    fadeOut,
    fadeIn,
    appendActiveItem,
    initAuxiliaryFn,
    toggleClassesInWrap,
    resetMap,
    scrollToListTop,
    generateFormData,
    isHiddenPagination,
} from './productOffers.functions';

let loaded = false;

export class ProductOffers {
    constructor($wrap, $btn, options) {
        this.$wrap = $wrap;
        this.$btn = $btn;
        this.emitter = options.emitter;
    }

    init() {
        this.$wrap.find('[data-product-list]').on('click', '[data-g-event="from_product_offer_to_shop"]', () => {
            GEvent.fromProductOfferToShop();
            YMEvent.fromProductOfferToShop();
        });

        const self = this;
        const $map = this.$wrap.find('[data-map]');
        const $parentMap = $map.parent();
        const $productListBox = this.$wrap.find('[data-product-list-box]');
        this._loadItem(this.$wrap, $productListBox);
        this.$btn.on('click', e => {
            e.preventDefault();
            const isActiveMap = $parentMap.hasClass('active');
            this.$btn.addClass('not-allowed');
            this.$wrap.addClass('not-allowed op-0 o-hidden');
            $productListBox.addClass('op-0');
            if (!loaded) {
                const loadMap = (data = null) => {
                    const initMap = () => {
                        const map = new YandexMap($map[0], {
                            emitter: this.emitter,
                            locations: data ? data : null,
                        });
                        map.render();
                    };

                    this._changeViewItems(
                        {
                            $wrap: this.$wrap,
                            $productListBox,
                            $map: $parentMap,
                            $btn: this.$btn,
                            isActiveMap,
                        },
                        initMap
                    );

                    loaded = true;
                };
                self._getItems('/get-offers-coordinates', generateFormData(self.$wrap.attr('data-product-id')))
                    .then(response => {
                        if (response.status !== 'ok') {
                            // NOTE: Will add redirect to 404
                            alert('Something wrong!!!');
                            return;
                        }
                        GEvent.productOffersMapView();
                        YMEvent.productOffersMapView();
                        loadMap(response.coordinates);
                    })
                    .catch(error => warn(error)); // NOTE: Will add redirect to 404
                return;
            }

            this._changeViewItems({
                $wrap: this.$wrap,
                $productListBox,
                $map: $parentMap,
                $btn: this.$btn,
                isActiveMap,
            });
        });
    }

    _loadItem($wrap, $productListBox) {
        this._getItems('/get-offers', generateFormData($wrap.attr('data-product-id')))
            .then(response => {
                if (response.status !== 'ok') {
                    // NOTE: Will add redirect to 404
                    alert('Something wrong!!!');
                    return;
                }

                if (window.dataLayer || window.ym) {
                    const unobserve = useIntersectionObserver(this.$wrap[0], target => {
                        if (target.isIntersecting) {
                            GEvent.productOffersView();
                            YMEvent.productOffersView();
                            unobserve();
                        }
                    });
                }

                const data = response.offers;
                Sort.sortProductOffers(data, { emitter: this.emitter, template: itemTemplate, list: $productListBox });

                this._selectProductItem($wrap, $productListBox, data);
            })
            .catch(error => warn(error)); // NOTE: Will add redirect to 404
    }

    _changeViewItems({ $wrap, $productListBox, $map, $btn, isActiveMap }, initMap = null) {
        const self = this;
        const $toAllOffers = $('#all-offers');
        if (isActiveMap) {
            $map.removeClass('active');
            if ($toAllOffers.length) {
                $toAllOffers.trigger('click');
            }
        } else {
            $map.addClass('active');
        }
        $map[0].addEventListener('transitionend', function cb(e) {
            if (e.propertyName === 'opacity') {
                if (isActiveMap) {
                    $productListBox.removeClass('with-map').removeClass('op-0');
                    $btn.html('').append(buttonTemplate('На карте', 'search-map'));
                } else {
                    $productListBox.addClass('with-map');
                    $btn.html('').append(buttonTemplate('Список', 'list'));
                }
                typeof initMap === 'function' && initMap();
                $wrap.removeClass('op-0');
                self._clearState($productListBox, $wrap, $btn);
                this.removeEventListener('transitionend', cb);
            }
        });
    }

    _selectProductItem($wrap, $list, data) {
        const self = this;
        const $activeProductList = $list.find('[data-product-list-active]');
        const $siblingsEl = $activeProductList.siblings();
        const $siblingList = $($siblingsEl[0]);
        const $siblingPagination = $($siblingsEl[1]);
        this.emitter.on('select:marker', ([id, name]) => {
            $wrap.addClass('not-allowed');
            const foundItem = data.find(item => item.id === id);
            if (!$activeProductList.hasClass('active')) {
                appendActiveItem($activeProductList, foundItem);
                if (!isHiddenPagination($siblingPagination)) {
                    fadeOut($siblingPagination);
                }
                fadeOut($siblingList, function () {
                    fadeIn($activeProductList, function () {
                        toggleClassesInWrap($wrap, $(this));
                        resetMap({
                            $el: $('#all-offers'),
                            self,
                            $wrap: $activeProductList,
                            $list,
                            cb: self._closeActiveProductItem,
                        });
                        scrollToListTop($list);
                    });
                });
                initAuxiliaryFn($activeProductList);
                return;
            }
            $activeProductList.removeClass('active');
            fadeOut($activeProductList, function () {
                appendActiveItem($(this), foundItem);
                initAuxiliaryFn($(this));
                fadeIn($(this), function () {
                    toggleClassesInWrap($wrap, $(this));
                    resetMap({
                        $el: $('#all-offers'),
                        self,
                        $wrap: $activeProductList,
                        $list,
                        cb: self._closeActiveProductItem,
                    });
                    scrollToListTop($list);
                });
            });
        });
    }

    _closeActiveProductItem($wrap, $list) {
        event.preventDefault();
        $wrap.addClass('not-allowed');
        const $activeProductList = $list.find('[data-product-list-active]');
        const $siblingsEl = $activeProductList.siblings();
        const $siblingList = $($siblingsEl[0]);
        const $siblingPagination = $($siblingsEl[1]);
        this.emitter.emit('close:activeItem|marker', $activeProductList.find('[data-item-id]').data('item-id'));
        $activeProductList.removeClass('active');
        fadeOut($activeProductList, function () {
            $activeProductList.html('');
            fadeIn($siblingList, function () {
                $wrap.removeClass('not-allowed');
            });
            if (!isHiddenPagination($siblingPagination)) {
                fadeIn($siblingPagination);
            }
        });
    }

    async _clearState($productListBox, $wrap, $btn) {
        await new Promise(resolve => {
            setTimeout(() => {
                $productListBox.removeClass('op-0');
                $wrap.removeClass('o-hidden');
                resolve();
            }, 200);
        });
        setTimeout(() => {
            $btn.removeClass('not-allowed');
            $wrap.removeClass('not-allowed o-hidden');
        }, 400);
    }

    _getItems(url, data) {
        return $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            contentType: false,
            processData: false,
            data,
        });
    }

    _initGEvent() {}
}

import { MAIN_STARS } from '@auxiliary/constants';

export class Rating {
    constructor($wrap) {
        this.$wrap = $wrap;
    }

    init() {
        const $wrap = $(this.$wrap);
        const rating = $wrap.data('rating');

        if (rating <= 0) {
            return;
        }

        const ratingHalf = $wrap.data('rating') / 2;
        const fullStars = Math.floor(ratingHalf);
        const halfStarPercent = (ratingHalf - fullStars) * 100;

        if (ratingHalf > 0) {
            $wrap.children().each((i, item) => {
                $(item).append(this._createStart());
                return i + 1 < fullStars;
            });
        }

        if (halfStarPercent !== 0 && fullStars < MAIN_STARS) {
            const $halfStar = $wrap.children(`:nth-child(${parseInt(fullStars + 1)})`);

            $halfStar
                .find('[data-fill-star]')
                .remove()
                .end()
                .append(this._createStart(halfStarPercent.toFixed(1)));
        }
    }

    _createStart(_width = null) {
        const width = _width ? `style="width:${_width}%"` : '';

        return `
            <span class="full-rating-view__fill-stars" data-fill-star ${width}>
                <svg>
                    <use xlink:href="#icon-star"></use>
                </svg>
            </span>
        `;
    }
}

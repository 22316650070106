import { NO_PHOTO, COMPANY_DIR } from '@auxiliary/constants';
import { removeURL } from '@auxiliary/customMethods';

export const startSearch = '<li class="custom-select__option">Начните поиск</li>';
export const notFound = '<li class="custom-select__option">Ничего не найдено</li>';
export const errorText = '<li class="custom-select__option">Произошла ошибка. Попробуйте сделать это позже</li>';
export const btnCreate = `
    <a href="#" class="search-select__create d-flex flex-c link">
        <span class="text text--upper text--fz-10 text--ff-normsmid text--spc-1_5">Добавить</span>
    </a>`;
export const optionTemplate = (data, needImage = true) => {
    const originURL = window.location.origin;
    const image = data.image
        ? `<img src="${originURL}${COMPANY_DIR}${data.image}" alt="logo" title="logo"/>`
        : `<img src="${originURL}${NO_PHOTO}" alt="logo" title="logo" class="w-50 h-42" />`;
    const country = data.country
        ? `<div class="text text--fz-14 text--color-secondary2 mr-10 mt-3">${data.country}</div>`
        : '';
    const linkToSite = data.site
        ? `<a
                href="${data.site}"
                target="_blank"
                rel="noopener noreferrer nofollow noindex"
                class="text text--fz-14 text--color-info search-select__option-link mt-3"
                data-search-select="link"
            >${removeURL(data.site)}</a>`
        : '';
    const imageWrap = needImage
        ? `<div class="search-select__option-img">
                ${image}
            </div>`
        : '';

    return `
        <li class="search-select__option" data-search-select="option" data-option-id="${data.id}">
            ${imageWrap}
            <div class="search-select__option-content">
                <div class="text text--fz-16 text--color-secondary3">${data.name}</div>
                <div class="search-select__option-inner-content">
                    ${country}
                    ${linkToSite}
                </div>
            </div>
        </li>
    `;
};

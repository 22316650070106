export default class UserMenu {
    static init(wrap) {
        const headInfo = wrap.find('.header__user-head-info');
        const userMenu = wrap.find('.header__user-menu');
        const overlay = wrap.find('.header__user-info-overlay');

        $('body')
            .off('click.userMenu')
            .on('click.userMenu', function (e) {
                const target = e.target;

                if (!$(target).is(overlay) && $(target).closest(overlay).length && wrap.hasClass('active')) {
                    userMenu.slideUp(200);
                    wrap.removeClass('active');
                    return;
                }

                if ($(target).closest(headInfo).is(headInfo)) {
                    userMenu.stop().slideToggle(200, function () {
                        if (wrap.hasClass('active')) {
                            userMenu.removeAttr('style').css('display', 'block');
                        } else {
                            userMenu.removeAttr('style').css('display', 'none');
                        }
                    });
                    wrap.toggleClass('active');
                    return;
                }

                if (!$.contains(wrap[0], target) && wrap.hasClass('active')) {
                    userMenu.slideUp(200);
                    wrap.removeClass('active');
                }
            });
    }

    static shiftItems(wrap, mq) {
        const commonWrap = wrap.parent();
        const iconsWrap = commonWrap.find('.header__icons');
        const userMenu = wrap.find('.header__user-menu');

        const moveItems = obj => {
            const { from, to, putMethod, removeClass, addClass } = obj;

            const items = from.find('.to-user-menu');

            if (items.length) {
                items.removeClass(removeClass).addClass(addClass);
                to[putMethod](items);
            }
        };

        const checkMq = () => {
            if (mq.matches) {
                moveItems({
                    from: iconsWrap,
                    to: userMenu,
                    putMethod: 'prepend',
                    removeClass: 'header__icon-item',
                    addClass: 'header__user-menu-item',
                });
            } else {
                moveItems({
                    from: userMenu,
                    to: iconsWrap,
                    putMethod: 'append',
                    removeClass: 'header__user-menu-item',
                    addClass: 'header__icon-item',
                });
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

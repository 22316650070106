import { toggleText } from '@components/UI/accordion/accordion.function';

export class Accordion {
    constructor($wrap, options) {
        this.$wrap = $wrap;
        this.options = options;
    }

    init() {
        const self = this;

        $('body')
            .off('click.accordion')
            .on('click.accordion', '[data-accordion-target]', function (e) {
                e.preventDefault();
                const $this = $(this);
                const $list = $this.closest('[data-accordion-wrap]').find('[data-accordion-list]:first');

                $this.addClass('not-allowed');

                if ($list.is(':visible')) {
                    $this.removeClass('active');
                    $list.slideUp(self.options.duration, () => $this.removeClass('not-allowed'));
                    Accordion._toggleActiveClass($this);
                } else {
                    $this.addClass('active');
                    $list.slideDown(self.options.duration, () => $this.removeClass('not-allowed'));
                    Accordion._toggleActiveClass($this);
                }
            });
    }

    static _toggleActiveClass($this) {
        switch ($this.data('accordion-target')) {
            case 'plus-open':
                $this.find('.icon-plus-open').toggleClass('open');
                break;
            case 'comment':
                toggleText($this);
                break;

            default:
                break;
        }
    }
}

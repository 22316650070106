import { warn, asyncEach, setLoader, ajax, isLastIteration } from '@auxiliary/customMethods';
import {
    prodFeaturesTemplate,
    renderParentWrapHTML,
    renderChildrenHTML,
    renderGroup,
} from './productionFeatures.template';
import { replaceAnimated } from './production.function';
import { initSticky } from '@components/UI/stickyBlock/stickyBlock';
import stickyBlockWith from '@components/UI/stickyBlock/stickyBlockWith';

export class ProductionFeaturesAjax {
    static init($wrap, dataId, type, hasProduction, $formatManufacture, $categoryBlock, emitter) {
        //NOTE: categories
        if (type === 'radio') {
            ProductionFeaturesAjax._renderGroupsFeatures(
                $wrap,
                dataId,
                hasProduction,
                $formatManufacture,
                $categoryBlock,
                emitter
            );
        }
        //NOTE: features
        if (type === 'checkbox') {
            ProductionFeaturesAjax.renderChildrenFeatures($wrap, dataId);
        }
    }

    // NOTE: Render groups
    static async _renderGroupsFeatures($wrap, dataId, hasProduction, $formatManufacture, $categoryBlock, emitter) {
        try {
            const $preloader = $('<div/>', {
                id: 'features-main',
                class: 'production__characteristics-empty',
                html: setLoader('div'),
            })
                .css('display', 'none')
                .attr('data-sticky-wrap', '');

            $formatManufacture.is(':checked')
                ? $wrap.attr('data-features-hidden', '')
                : $wrap.attr('data-features-hidden', 'prod');

            await replaceAnimated($('#features-main'), $preloader);
            const response = await ProductionFeaturesAjax.getParentsData(dataId);
            const groupsLastIndex = response.length - 1;
            const initParentFeatures = ProductionFeaturesAjax._renderParentsFeatures(
                groupsLastIndex,
                hasProduction,
                $formatManufacture,
                $categoryBlock,
                emitter
            );

            response.forEach((item, i) => {
                initParentFeatures({
                    groupIndex: i,
                    features: item.features,
                    name: item.name,
                    icon: item.icon,
                });
            });
        } catch (error) {
            warn(error);
        }
    }

    // TODO: add available features
    static _renderParentsFeatures(groupsLastIndex, hasProduction, $formatManufacture, $categoryBlock, emitter) {
        const groups = [];
        const manufactureHide = $formatManufacture.is(':checked') ? '' : 'style="display: none;"';

        return ({ groupIndex, name, icon, features }) => {
            const featuresHTML = [];

            asyncEach(features, async (item, i, list) => {
                featuresHTML.push(renderParentWrapHTML(item, hasProduction, manufactureHide));

                if (isLastIteration(i, list)) {
                    groups.push(renderGroup({ name, icon }, featuresHTML.join('')));

                    if (groupsLastIndex === groupIndex) {
                        const $content = $('<div/>', {
                            id: 'features-main',
                            class: 'production__characteristics-tree',
                            html: prodFeaturesTemplate('content', groups.join(''), hasProduction, manufactureHide),
                        })
                            .attr('data-production-features', '')
                            .attr('data-sticky-wrap', '')
                            .css('display', 'none');
                        $formatManufacture.closest('label').removeClass('not-allowed');
                        $categoryBlock.removeClass('cursor-not-allowed');

                        await replaceAnimated($('#features-main'), $content);
                        const wrap = $content;
                        const stickyEl = wrap.find('[data-sticky-element]');

                        if (wrap.length && stickyEl.length) {
                            initSticky({ wrap, stickyEl, mq: false });
                            stickyBlockWith.init({
                                wrap,
                                stickyEl,
                                emitter,
                                mq: window.matchMedia('(max-width: 1199px)'),
                            });
                        }
                    }
                }
            });
        };
    }

    // TODO: add available sub features
    static async renderChildrenFeatures($childrenWrap, dataId) {
        try {
            let hiddenChild = '';

            if (
                $childrenWrap.data('children-hidden') === 'prod' ||
                !$('[data-type="category_manufacture"]').is(':checked')
            ) {
                hiddenChild = 'style="display: none;"';
            }

            const hasProduction = Boolean($('#production').data('has-production'));
            $childrenWrap.append(
                `<div class="production-tree__children-wrap production-tree__children-wrap--min-h"
                    data-production-children-wrap>
                    ${setLoader('div')}</div>`
            );
            const response = await ProductionFeaturesAjax.getChildrenData(dataId);

            if (!response.length) return;

            const $newChildrenWrap = $childrenWrap.find('[data-production-children-wrap]').css('display', 'none');
            const items = [];

            asyncEach(response, (item, i, list) => {
                items.push(renderChildrenHTML(item, hasProduction, hiddenChild));

                if (isLastIteration(i, list)) {
                    $newChildrenWrap.removeClass('production-tree__children-wrap--min-h')[0].innerHTML = items.join('');
                    $newChildrenWrap.stop().slideDown(200);
                }
            });
        } catch (error) {
            warn(error);
        }
    }

    // TODO: ajax available features
    static getParentsData(dataId) {
        return (async () => {
            try {
                const formData = new FormData();
                formData.append('id', dataId);
                const response = await ajax(
                    'POST',
                    '/cabinet/user/company/production/production-features-by-cat-id',
                    formData
                );

                if (response.status === 'ok') {
                    const {
                        data: { groups },
                    } = response;

                    if (!groups.length) {
                        return [];
                    }
                    return groups;
                }
            } catch (error) {
                warn(error);
            }
        })();
    }

    // TODO: ajax available sub features
    static getChildrenData(dataId) {
        return (async () => {
            try {
                const formData = new FormData();
                formData.append('id', dataId.id);
                formData.append('category_id', dataId.category_id);
                const response = await ajax(
                    'POST',
                    '/cabinet/user/company/production/production-child-features',
                    formData
                );
                if (response.status === 'ok') {
                    const {
                        data: { features },
                    } = response;

                    if (!features.length) {
                        return [];
                    }

                    return features;
                }
            } catch (error) {
                warn(error);
            }
        })();
    }
}

let handleSetwidth = null;

export default class stickyBlockWith {
    static init({ wrap, stickyEl, emitter, mq, mq2 }) {
        if (handleSetwidth) {
            emitter.off('page:resized', handleSetwidth);
        }

        handleSetwidth = () => {
            stickyEl.css('width', `${wrap.width()}`);
        };
        handleSetwidth();

        emitter.on('page:resized', handleSetwidth);

        const checkMq = () => {
            if (mq.matches) {
                stickyEl.removeClass('pos-abs pos-fix');
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

const handleScroll = $el => {
    $('html, body').animate(
        {
            scrollTop: $el.offset().top,
        },
        300
    );
};

const initHref = $el => {
    handleScroll($(`${$($el).data('href')}`));
    window.history.pushState(null, document.title, `${window.location.origin}${window.location.pathname}`);
};

export class ScrollTo {
    constructor(hrefs, pathName, options) {
        this.$link = hrefs;
        this.pathName = pathName || '';
        this.emitter = options.emitter || null;
    }

    init() {
        if (!this.$link.length) {
            return;
        }

        $(this.$link).each((_, el) => {
            if (this.emitter && window.location.hash === `${$(el).data('href')}`) {
                if ($(el).data('href') === '#offers') {
                    this.emitter.once('pagination:didMounted', () => {
                        initHref(el);
                        return;
                    });
                } else {
                    initHref(el);
                }
            }

            $(el).on('click', e => {
                e.preventDefault();
                if ($(`${$(el).data('href')}`).length) {
                    handleScroll($(`${$(el).data('href')}`));
                    return;
                }
                window.location.href = `${window.location.origin}${this.pathName}${$(el).attr('href')}`;
            });
        });
    }
}

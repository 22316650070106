import Masks from '@auxiliary/Masks';

export class Fields {
    static init($wrap, emitter = null) {
        const $createdFields = $wrap.find('[data-created-fields]');
        const HTML = Fields.createHTML($createdFields.length ? Fields.findMaxNumber($createdFields) : 0);

        $wrap.on('click.add', '[data-add-fields]', function (e) {
            Fields.addField.call(this, e, HTML, 'input.is-mask[name*="wholesales"]');
        });
        $wrap.on('click.remove', '[data-remove-fields]', function (e) {
            Fields.removeField.call(this, e, 'input.is-mask[name*="wholesales"]', emitter);
        });
    }

    static addField(e, HTML, selector) {
        e.preventDefault();

        const $this = $(this);

        $this.before(HTML());

        const createdInputs = $this.prev('[data-created-fields]').find(selector);

        createdInputs.each((_, input) => {
            const $input = $(input);

            if ($input.hasClass('natural-num')) {
                Masks.initNaturalNum($input);
            } else if ($input.hasClass('currency')) {
                Masks.initCurrency($input);
            }
        });
    }

    static removeField(e, selector, emitter) {
        e.preventDefault();

        const $this = $(this);
        const parent = $this.closest('[data-created-fields]');
        const inputs = parent.find(selector);

        if (emitter && typeof emitter === 'object') {
            emitter.emit('remove:field', [$this, parent, inputs]);
        }

        Masks.removeMask(inputs);
        parent.remove();
    }

    static findMaxNumber($fields) {
        const numbers = [];

        $fields.each((_, field) => {
            numbers.push(Number(field.dataset.count));
        });

        return Math.max(...[...new Set([...numbers])]);
    }

    static deleteDataFromDataBase(dataObj) {
        const { url, originData } = dataObj;

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            url,
            type: 'POST',
            data: originData,
            error() {
                alert('Something Wrong! (Data deletes)');
            },
        });
    }

    static createHTML(number) {
        let count = number;

        return () => {
            count++;

            return `
                <div class="wholesale-fields__created-fields" data-created-fields data-count="${count}">
                    <div class="wholesale-fields__inner fields-data__fields-inner">
                        <div class="sm:pr-2per form-group form-group--input flex-auto">
                            <div class="watch-input">
                                <span class="form-group__tip form-group__tip--required">При заказе от</span>
                                <div class="form-group__inner flex-aic">
                                    <input
                                        type="text"
                                        class="is-mask natural-num db-transparent"
                                        name="wholesales[${count}][count]"
                                        required
                                    >
                                </div>
                                <span class="form-group__tip-error"></span>
                            </div>
                        </div>
                        <div class="sm:pl-2per form-group form-group--input flex-auto">
                            <div class="watch-input">
                                <span class="form-group__tip form-group__tip--required">Цена</span>
                                <div class="form-group__inner flex-aic">
                                    <input
                                        type="text"
                                        class="is-mask currency db-transparent"
                                        name="wholesales[${count}][price]"
                                        required
                                    >
                                    <span class="form-group__entities flex-s0">руб</span>
                                </div>
                                <span class="form-group__tip-error"></span>
                            </div>
                        </div>
                    </div>
                    <a href="#" class="wholesale-fields__remove btn-remove-fields" data-remove-fields>
                        <svg>
                            <use xlink:href="#icon-close"></use>
                        </svg>
                        <span>Удалить</span>
                    </a>
                </div>
            `;
        };
    }
}

import { Cookie } from '@components/cookie/Cookie';
import { expirationDateInHours } from '@auxiliary/customMethods';
import { YM_CODE } from '@auxiliary/constants';

export class Metrics {
    constructor() {
        this.namesEvents = [];
    }

    sendEvent(name, options = {}) {
        if (Cookie.getCookie(name)) {
            this.namesEvents.push(name);
            return;
        }

        if (window.dataLayer) {
            gtag('event', name, options);
        }

        if (window.ym) {
            ym(YM_CODE, 'reachGoal', name);
        }

        Cookie.setCookie(name, true, {
            expires: expirationDateInHours(8760),
            samesite: 'strict',
        });
        this.namesEvents.push(name);
    }

    removeNamesEvents() {
        if (this.namesEvents.length === 0) {
            return;
        }

        this.namesEvents.forEach(name => {
            Cookie.deleteCookie(name);
        });

        this.namesEvents = [];
    }
}

let inputMask = null;
export default class Masks {
    static async _loadLib() {
        try {
            const inputmask = await import(
                /* webpackChunkName: "inputmask" */
                'inputmask/dist/jquery.inputmask.js'
            );
            inputMask = inputmask.default;
        } catch (error) {
            alert(error.message);
        }
    }

    static init() {
        const date = $('.input-date');
        const time = $('.input-time');
        const network = $('.input-network');
        const number = $('.num');
        const naturalNum = $('.natural-num');
        const year = $('.input-year');
        const decimal = $('.input-decimal');
        const currency = $('.currency');

        if (date.length) {
            Masks.initMaskDate(date);
        }

        if (time.length) {
            Masks.initMaskTime(time);
        }

        if (year.length) {
            Masks.initMaskYear(year);
        }

        if (network.length) {
            Masks.initMaskOptional(network);
        }

        if (number.length) {
            Masks.initMaskNumbers(number);
        }

        if (naturalNum.length) {
            Masks.initNaturalNum(naturalNum);
        }

        if (decimal.length) {
            Masks.decimalNum(decimal);
        }

        if (currency.length) {
            Masks.initCurrency(currency);
        }
    }

    // Remove mask
    static removeMask($input) {
        if (inputMask) {
            $input.inputmask('remove');
        }
    }

    // Mask for phone
    static async initMaskPhone($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                mask: '+99999999999[9][9][9][9]',
                showMaskOnHover: false,
                placeholder: '',
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for date
    static async initMaskDate($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                alias: 'datetime',
                inputFormat: 'dd/mm/yyyy',
                placeholder: '__/__/____',
                showMaskOnHover: false,
                clearIncomplete: true,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for time
    static async initMaskTime($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                alias: 'datetime',
                inputFormat: 'HH:MM',
                placeholder: '__:__',
                showMaskOnHover: false,
                clearIncomplete: true,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for year 1900 - current year
    static async initMaskYear($input) {
        try {
            await Masks._loadLib();

            const currentYear = new Date().getFullYear().toString().split('');

            $input.inputmask({
                regex: `^([1][9][0-9]\\d|[2][0][0-${currentYear[2] - 1}]\\d|[2][0]${currentYear[2]}[0-${
                    currentYear[3]
                }])$`,
                showMaskOnHover: false,
                placeholder: '',
                clearIncomplete: true,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for networks
    static async initMaskOptional($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                mask: '*{0,}',
                showMaskOnHover: false,
                placeholder: '',
                definitions: {
                    '*': {
                        validator: '',
                    },
                },
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for number
    static async initMaskNumbers($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                regex: '[0-9]*',
                placeholder: '',
                showMaskOnHover: false,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for natural numbers which start without zero
    static async initNaturalNum($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                regex: '[1-9]{1,1}[0-9]*',
                placeholder: '',
                showMaskOnHover: false,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for decimal numbers
    static async decimalNum($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                alias: 'decimal',
                rightAlign: false,
                allowMinus: false,
                placeholder: '',
                showMaskOnHover: false,
                clearIncomplete: true,
            });
        } catch (error) {
            alert(error.message);
        }
    }

    // Mask for currency
    static async initCurrency($input) {
        try {
            await Masks._loadLib();
            $input.inputmask({
                digits: 2,
                alias: 'decimal',
                // groupSeparator: ' ',
                rightAlign: false,
                allowMinus: false,
                radixPoint: '.',
                placeholder: '',
                showMaskOnHover: false,
                clearIncomplete: true,
            });
        } catch (error) {
            alert(error.message);
        }
    }
}

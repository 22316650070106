import { asyncEach } from '@auxiliary/customMethods';
import {
    ITEM_FEATURE_SELECTOR,
    ITEM_PARENT_FEATURE_SELECTOR,
    REQUIRED_TYPE_ALWAYS_SELECTOR,
    REQUIRED_TYPE_TO_SELECT_SELECTOR,
} from './featuresTree.variables';

export const fireResolve = (isLastWrap, cb, arrayOfCollection) => {
    if (isLastWrap) {
        cb(arrayOfCollection);
    }
};

export const removeErrorForParentFeature = ($parentFeatures, siblingSelector = null) => {
    asyncEach([...$parentFeatures].reverse(), parentFeature => {
        const $parentFeature = $(parentFeature);
        const $siblingWrap = siblingSelector ? $parentFeature.siblings(siblingSelector) : $parentFeature;

        if ($siblingWrap.find('.tree__wrap-error').length) {
            return;
        }

        $parentFeature.removeClass('tree__wrap-error');
    });
};

export const toggleClass = ($parent, method) => $parent.find('> label')[method]('not-allowed');

export const removeLoader = $parent =>
    $parent.find('[data-loader]').slideUp(200, function () {
        $(this).remove();
    });

export const findNotCheckedAllRequiredTypes = $input => {
    return $input.closest(ITEM_PARENT_FEATURE_SELECTOR).siblings(ITEM_PARENT_FEATURE_SELECTOR).find(
        `
            > ${ITEM_FEATURE_SELECTOR} ${REQUIRED_TYPE_ALWAYS_SELECTOR},
            > ${ITEM_FEATURE_SELECTOR} ${REQUIRED_TYPE_TO_SELECT_SELECTOR},
            > ${REQUIRED_TYPE_ALWAYS_SELECTOR},
            > ${REQUIRED_TYPE_TO_SELECT_SELECTOR}
        `
    );
};

export const findInputsWrapRequiredTypes = $inputs =>
    $inputs.closest(ITEM_PARENT_FEATURE_SELECTOR).find(`> ${ITEM_FEATURE_SELECTOR}`);

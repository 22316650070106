import { rAF } from '@auxiliary/customMethods';

export const scrollToTop = (
    $el,
    options = {
        $scrollableNode: $(window),
        $scrollableTopEl: $('html, body'),
        $dependedBtn: null,
        dependedBtnClass: '',
    }
) => {
    let prevScrollTop = 0;
    const { $scrollableNode, $scrollableTopEl, $dependedBtn, dependedBtnClass } = options;
    const initClick = () => $el.off('click.to-top').on('click.to-top', handleClick);
    const initScroll = () => $scrollableNode.off('scroll.to-top').on('scroll.to-top', rAF(handleScroll));
    const destroyClick = () => $el.off('click.to-top');
    const destroyScroll = () => $scrollableNode.off('scroll.to-top');
    const handleClick = () => {
        $el.removeClass('active');
        $dependedBtn && $dependedBtn.removeClass(dependedBtnClass);
        destroyClick();
        destroyScroll();
        prevScrollTop = 0;

        $scrollableTopEl.animate(
            {
                scrollTop: $scrollableTopEl.offset().top,
            },
            300,
            () => {
                initClick();
                initScroll();
            }
        );
    };
    const handleScroll = e => {
        const $target = $(e.target);
        const currentScrollTop = $target.scrollTop();

        if (currentScrollTop === 0) {
            prevScrollTop = currentScrollTop;
        }

        if (currentScrollTop < prevScrollTop && !$el.hasClass('active')) {
            $el.addClass('active');
            $dependedBtn && $dependedBtn.addClass(dependedBtnClass);
        } else if (currentScrollTop >= prevScrollTop && $el.hasClass('active')) {
            $el.removeClass('active');
            $dependedBtn && $dependedBtn.removeClass(dependedBtnClass);
        }

        prevScrollTop = currentScrollTop;
    };

    initClick();
    initScroll();

    return () => {
        destroyClick();
        destroyScroll();
    };
};

import Create from '../../create/Create';
import Brand from '../Brand';
import FromSteps from '../../create/FormSteps';
import { deleteFormDataKeys, getCurrentSubmitBtn } from '@auxiliary/customMethods';
import { PhonesCreate } from '../PhonesCreate';
import { EmailsCreate } from '../EmailsCreate';
import OwnProdVisibility from '../OwnProdVisibility';
import { toggleLogo } from './../company.functions';
import { InputSearch } from '@components/UI/inputSearch/InputSearch';
import { Metrics } from '@components/metrics';

const metrics = new Metrics();
const namesEvents = [
    'create_company_step_1',
    'create_company_step_2',
    'create_company_step_3',
    'create_company_success',
];
let phones = [];
export default class CreateCompany extends Create {
    static init(wrap, forms, steps, options) {
        metrics.sendEvent(namesEvents[0]);
        getCurrentSubmitBtn();
        new InputSearch($('[data-input-search="wrap"]'), {
            search: {
                type: 'POST',
                url: '/cabinet/user/search-by-first-symbols',
                dataKeys: ['name', 'type'],
                responseKey: 'companies',
            },
            popups: {
                bindCompany: $('[data-bind-company]'),
                complainCompany: $('[data-complain-company]'),
            },
            emitter: options.emitter,
        });

        options.emitter.on('updated:phones', intlPhones => {
            phones = intlPhones;
        });

        forms.each((i, form) => {
            const formAttr = $(form).data('form-step');
            const $form = $(form);
            const $step = $(steps[i]);

            if (formAttr === 1) {
                CreateCompany.sendData({
                    form: $form,
                    step: $step,
                    url: 'create-step-1',
                });

                toggleLogo($form.find(':checkbox[name="has-not-logo"]'), $('#photo'));
                FromSteps.prevStep($form, $step, CreateCompany.sendData);
                Brand.checkBrand($form, $step);
            }
        });

        CreateCompany.showAlertBeforeLeavePage();
        OwnProdVisibility.init(wrap.find('[data-toggle-visibility]'));
        PhonesCreate.init(wrap.find('[data-contacts="phones"]'), { emitter: options.emitter });
        EmailsCreate.init(wrap.find('[data-contacts="emails"]'));
    }

    static sendData(options) {
        const { form, step, url } = options;

        form.off('submit').on('submit', function (e) {
            e.preventDefault();
            const $this = $(this);
            const btnSubmit = $this.find('button[type="submit"][data-current-submit-btn]');

            if ($this.valid()) {
                $this.addClass('not-allowed');
                const formData = new FormData($this[0]);

                CreateCompany._showLoader(form);
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    url,
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData,
                    beforeSend() {
                        CreateCompany.correctFormData($this, formData);
                        CreateCompany.removeErrors(form);
                    },
                    success() {
                        const stepNumber = step.data('step');

                        if (form.is('[data-form-last]')) {
                            if (!window.dataLayer) {
                                CreateCompany.removeAlertBeforeLeavePage();
                                CreateCompany.checkLastStep($this);
                                return;
                            }

                            metrics.sendEvent(namesEvents[namesEvents.length - 1], {
                                event_callback: () => {
                                    metrics.removeNamesEvents();
                                    CreateCompany.removeAlertBeforeLeavePage();
                                    CreateCompany.checkLastStep($this);
                                },
                            });

                            return;
                        }

                        metrics.sendEvent(namesEvents[stepNumber]);
                        CreateCompany._setStepToSession($this);
                        Brand.checkBrand($this, step, true);
                        FromSteps.nextStep($this, step, btnSubmit, CreateCompany.sendData);
                    },
                    error(data) {
                        CreateCompany.createError({ data, form: $this });
                    },
                });
            }
        });
    }

    static correctFormData(form, formData) {
        const $notLogo = form.find(':checkbox[name="has-not-logo"]');
        const checkboxProdCategories = form.find(
            'input[type="checkbox"][name^="checkbox_production_category_"]:checked'
        );

        super.correctFormData(formData);

        deleteFormDataKeys(formData, $notLogo);

        if (formData.has('checkbox_payment_type')) {
            formData.delete('checkbox_payment_type');

            if (!formData.has('is_brand')) formData.set('is_brand', 0);
        }

        if (checkboxProdCategories.length) {
            deleteFormDataKeys(formData, checkboxProdCategories);
        }

        if (form.data('form-step') === 3) {
            phones.forEach(phone => {
                formData.set(phone.name, phone.number);
            });
        }
    }

    static _createPhoto(data) {
        return `
                <div class="file__img">
                    <img src="${data}" alt="photo" title="photo"/>
                </div>
                <label class="file__edit edit">
                    <input type="file" hidden/>
                    <svg class="edit__icon">
                        <use xlink:href="#icon-edit"></use>
                    </svg>
                    <span class="edit__text">
                        Изменить лого
                    </span>
                </label>
                <input type="text" name="image" class="img-validate input-hidden" value="${data}">
            `;
    }
}
